import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import TableAdminLeaderBoard from "../../components/Tables/TableAdminLeaderBoard";
import InputSearch from "../../components/inputs/InputSearch";
import Pagination from "../../components/Tables/Pagination";
import {
  useInviteUserMutation,
  useInviteByCSVMutation,
} from "../../services/organization/adminUsers";
import { useGetAdminLeaderboardQuery } from "../../services/organization/leaderboard";
import InviteEmployeeModal from "../../components/Modals/InviteEmployeeModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";

const AdminLeaderBoard = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [usersFile, setUsersFile] = useState([]);

  const { t } = useTranslation(["common", "leaderboard", "employees"]);

  const validationSchema = yup.object({
    email: yup.string().email(t("validations.emailWrong")),
    type: yup.string().required(t("validations.emailRequired")),
  });

  let { data: usersList, isLoading: isGetLeaderboardLoading } =
    useGetAdminLeaderboardQuery({
      keyword: searchKeyword,
      page,
    });
  const [inviteUser] = useInviteUserMutation();
  const [inviteByCSV] = useInviteByCSVMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
      type: "email",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      let result;
      if (values?.type == "email") result = await inviteUser(values?.email);
      else if (values?.type == "csv") {
        if (usersFile.length > 0) {
          let dataForm = new FormData();
          dataForm.append("users", usersFile[0]);
          result = await inviteByCSV(dataForm);
        }
      }

      if (result?.error) {
        toast.error(result?.error?.data?.message);
      } else {
        toast.success(t("invitationSuccess"));
      }
    },
  });

  if (isGetLeaderboardLoading) return <LoadingIndicator />;

  return (
    <div className="employee ranks">
      <InviteEmployeeModal />

      <div className={`sticky-header header-table ${usersList.data.length === 0 ? "" : "top-sticky-header"}`} >
        <div className="d-lg-flex flex-wrap">
          <div className="left-side">
            <h1 className="title-page ">{t("leaderboard:leaderboard")}</h1>
            <ul className="d-flex">
              <li className="me-2">
                <span className="label label-black">
                  {" "}
                  <span className="me-2">{usersList?.pagination?.count}</span>
                  {t("employee")}
                </span>
              </li>
            </ul>
          </div>

          <div className="ms-auto right-side">
            <ul className="list-filter">
              <li className="me-2">
                <InputSearch
                  placeHolder={t("employees:searchForEmployee")}
                  value={searchKeyword}
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                    setPage(1);
                  }}
                />
              </li>
              <li>
                <button
                  type="button"
                  className="btn btn-main-color font-15"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  <span className="fas fa-user-plus fa-fw me-2"></span>
                  {t("inviteNewEmployee")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="content-page__wrapper">
        <TableAdminLeaderBoard usersList={usersList} />
        {usersList?.data?.length !== 0 ? (
          <Pagination
            current={usersList?.pagination?.current}
            pages={usersList?.pagination?.pages}
            handlePagination={(page) => setPage(page)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AdminLeaderBoard;
