import PropTypes from "prop-types";
import DoughnutChart from "../DoughnutChart";
import Icon from "../../icons/Icon";
import { CheckCircleIcon } from "../../icons/Icons";
import { getGrade } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

const ExamProgressCard = ({ exam, className, levelText }) => {
  const { t } = useTranslation(["common", "communityFeed", "learningPaths"]);

  let questionsCount = exam?.lesson_log?.answers?.length;
  let correctAnswersCount = exam?.lesson_log?.correct_answers_count;
  let wrongAnswersCount = questionsCount - correctAnswersCount;
  // let quizPercentage = exam?.lesson_log?.quiz_percentage;
  let quizPercentage = (correctAnswersCount / questionsCount) * 100;
  let isSuccess = quizPercentage >= 50;
  let grade = getGrade(quizPercentage, t);
  let timeConsumed;
  return (
    <div className={`card-style ${className} exam-progress`}>
      <p className="font_14 font-color-grey mb-0">
        {t("learningPaths:courseExam")}
      </p>
      <h4 className="font_20 font-extrabold">
        <span className="me-2">{exam?.course?.title}</span>
        <span
          className={`${
            isSuccess
              ? "badge badge__success--light"
              : "badge badge__danger--light"
          }`}
        >
          {isSuccess ? t("successful") : t("fail")}
        </span>
      </h4>

      <div className="d-md-flex mt-4">
        <div className="me-4">
          <DoughnutChart
            percentage={parseInt(quizPercentage)}
            color={!isSuccess && "#AF151F"}
            levelText={grade}
          />
        </div>
        <ul className="d-flex flex-wrap flex-md-nowrap mt-3 mt-md-0  flex-grow-1">
          <li className="flex-fill w-50 w-25 d-flex align-items-center">
            <i className="font_20 font-color-grey-light me-2 far fa-question-circle"></i>
            <div>
              <small className="font_12 font-color-grey">
                {t("learningPaths:questionsNumber")}
              </small>
              <h4 className="font_20 font-extrabold">{questionsCount}</h4>
            </div>
          </li>

          <li className="flex-fill w-50 w-25 d-flex align-items-center">
            <Icon
              icon={<CheckCircleIcon />}
              hasStroke
              iconSize={20}
              iconColor="#bbbabf"
              viewBox="0 0 20 23"
              className="me-2"
              iconName="check-circle"
            />
            <div>
              <small className="font_12 font-color-grey">
                {t("learningPaths:rightAnswers")}
              </small>
              <h4 className="font_20 font-extrabold">{correctAnswersCount}</h4>
            </div>
          </li>

          <li className="flex-fill w-50 w-25 d-flex align-items-center">
            <i className="font_20 font-color-grey-light me-2 far fa-times-circle"></i>
            <div>
              <small className="font_12 font-color-grey">
                {t("learningPaths:wrongAnswers")}
              </small>
              <h4 className="font_20 font-extrabold">{wrongAnswersCount}</h4>
            </div>
          </li>

          <li className="flex-fill w-50 w-25 d-flex align-items-center">
            <i className="font_20 font-color-grey-light me-2 far fa-clock"></i>
            <div>
              <small className="font_12 font-color-grey">
                {t("learningPaths:timeTaken")}
              </small>
              <h4 className="font_20 font-extrabold">
                {exam?.lesson_log?.quiz_time
                  ? exam?.lesson_log?.quiz_time
                  : t("notSpecified")}
              </h4>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ExamProgressCard;

ExamProgressCard.propTypes = {
  exam: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    numberOfQuestion: PropTypes.number,
    correctAnswers: PropTypes.number,
    wrongAnswers: PropTypes.number,
    duration: PropTypes.number,
    degree: PropTypes.string,
  }),
};
