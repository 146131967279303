import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePickerInput({
  name,
  label,
  disabled,
  marginBottom,
  error,
  touched,
  minDate,
  setFieldValue,
  onBlur,
}) {
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState("");

  function updateSelectedDate(val) {
    const date = val?.toLocaleDateString("en-GB");
    setFieldValue(name, date);
    setValue(val);
  }
  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur(e) {
    setIsFocused(false);
    onBlur(e);
  }

  return (
    <div className="input-text">
      <div
        className="did-floating-label-content"
        style={{ marginBottom: marginBottom ? marginBottom + "px" : "27px" }}
      >
        <DatePicker
          className="did-floating-input"
          dateFormat="dd/MM/yy"
          name={name}
          selected={value}
          minDate={minDate}
          onBlur={handleBlur}
          onChange={updateSelectedDate}
          disabled={disabled}
          onFocus={handleFocus}
        />
        <input
          name={name}
          style={{ display: "none" }}
          placeholder={isFocused ? "" : " "}
          className="did-floating-input"
          value={value ? value : ""}
          onChange={() => {}}  //just to remove the console error 
          disabled={disabled}
        />
        <label className="did-floating-label">{label}</label>
        {error && touched ? <div className="input-error">{error}</div> : null}
      </div>
    </div>
  );
}
