import React from "react";
import { useNavigate } from "react-router-dom";
import HomeAdminsCard from "../../components/cards/HomeAdminsCard";
import HomeStatsCard from "../../components/cards/HomeStatsCard";
import TotalCoursesCard from "../../components/cards/TotalCoursesCard";
import { useGetAdminDashboardStatsQuery } from "../../services/organization/adminStatistics";
import InviteEmployeeModal from "../../components/Modals/InviteEmployeeModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import EmptyState from "../../components/EmptyState";
import Icon from "../../components/icons/Icon";
import { ReportsStrokeIcon } from "../../components/icons/Icons";
import { useTranslation } from "react-i18next";

const Reports = () => {
  const { data: dashboardStats, isLoading: isGetDashboardStatsLoading } =
    useGetAdminDashboardStatsQuery();

  const { t } = useTranslation(["common", "leaderboard", "employees"]);

  if (isGetDashboardStatsLoading) return <LoadingIndicator />;

  console.log(dashboardStats?.data);

  return (
    <div className="employee p-4">
      <InviteEmployeeModal />
      {dashboardStats?.data?.total_number_of_users ? (
        <>
          <div className="d-flex flex-wrap">
            <div className="left-side">
              <h1 className="title-page font_24">{t("reports")}</h1>
              <p className="mb-0 font_16 font-color-grey ">
                {t("reportsSummary")}
              </p>
            </div>

            <div className="ms-auto right-side">
              <ul className="list-filter d-flex flex-wrap">
                <li className="me-2"></li>
                <li></li>
              </ul>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mt-5">
              <div className="col-lg-6">
                <ul className="row">
                  <li className="col-sm-6">
                    <HomeStatsCard
                      bgColor="rgba(157, 78, 221, 0.06)"
                      img="graduation-cap-solid.svg"
                      title={t("learningTracksCount")}
                      num={dashboardStats?.data?.learning_tracks_count}
                      singleNumber
                    />
                  </li>
                  <li className="col-sm-6">
                    <HomeStatsCard
                      bgColor="rgba(241, 115, 0, 0.06)"
                      img="courses.svg"
                      title={t("coursesCount")}
                      num={dashboardStats?.data?.total_courses}
                      singleNumber
                    />
                  </li>
                  <li className="col-sm-12">
                    <TotalCoursesCard
                      completedCourses={
                        dashboardStats?.data?.completed_course_enrollments
                      }
                      totalCourses={
                        dashboardStats?.data?.total_course_enrollments
                      }
                    />
                  </li>
                  <li className="col-sm-6">
                    <HomeStatsCard
                      bgColor="rgba(58, 134, 255, 0.06)"
                      img="departments.svg"
                      title={t("departmentsCount")}
                      num={dashboardStats?.data?.total_number_of_departments}
                      singleNumber
                    />
                  </li>
                  <li className="col-sm-6">
                    <HomeStatsCard
                      bgColor="rgba(91, 192, 235, 0.06)"
                      img="groups.svg"
                      title={t("groupsCount")}
                      num={0}
                      singleNumber
                    />
                  </li>
                </ul>
              </div>

              <div className="col-lg-6">
                <ul className="row mb-4">
                  <li className="col-sm-6">
                    <HomeStatsCard
                      bgColor="rgba(241, 174, 78, 0.06)"
                      img="lessons.svg"
                      title={t("lessonsCount")}
                      num={dashboardStats?.data?.total_number_of_lessons}
                      singleNumber
                    />
                  </li>
                  <li className="col-sm-6">
                    <HomeStatsCard
                      bgColor="rgba(179, 106, 94, 0.06)"
                      img="time.svg"
                      title={t("watchingHours")}
                      num={dashboardStats?.data?.total_watching_hours}
                      singleNumber
                      text={t("hoursRoughly")}
                    />
                  </li>
                  <li className="col-sm-6">
                    <HomeStatsCard
                      bgColor="rgba(1, 194, 153, 0.06)"
                      img="pass.svg"
                      title={t("succeedInExams")}
                      num={dashboardStats?.data?.passed_quizzes_count}
                      totalNum={dashboardStats?.data?.total_number_of_quizzes}
                    />
                  </li>
                  <li className="col-sm-6">
                    <HomeStatsCard
                      bgColor="rgba(239, 35, 60, 0.06)"
                      img="fail.svg"
                      title={t("failedInExams")}
                      num={
                        dashboardStats?.data?.total_number_of_quizzes -
                        dashboardStats?.data?.passed_quizzes_count
                      }
                      totalNum={dashboardStats?.data?.total_number_of_quizzes}
                    />
                  </li>
                  <li className="col-sm-12">
                    <HomeAdminsCard
                      totalUsersNumber={
                        dashboardStats?.data?.total_number_of_users
                      }
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ marginTop: "200px" }}>
          <EmptyState
            iconComponent={
              <Icon
                icon={<ReportsStrokeIcon />}
                hasStroke
                iconColor="#bbbabf"
                iconSize={38}
                iconName="reports-stroke"
                viewBox="0 0 18 19"
              />
            }
            title={t("emptyStates.reports")}
            emptyStateText={t("reportsWillAppearHere")}
          />
        </div>
      )}
    </div>
  );
};

export default Reports;
