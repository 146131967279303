
import React from 'react'

export default function reports() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19">
    <g id="reports" transform="translate(-3.5 -2.5)">
      <line id="Line_906" data-name="Line 906" y1="10" transform="translate(19 10)" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
      <line id="Line_907" data-name="Line 907" y1="16" transform="translate(12 4)" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
      <line id="Line_908" data-name="Line 908" y1="6" transform="translate(5 14)" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
    </g>
  </svg>
  )
}
