import React from "react";
import { useTranslation } from "react-i18next";

export default function CertificateDesign() {
  const { t } = useTranslation("settings");
  return (
    <div className="py-4">
      <p className="font_14 font-color-grey pb-3">
        {" "}
        {t("settings:certifcateDesignHint")}
      </p>
      <img src="/images/Cretifacte.png" className="w-100" alt={`certificate`} />
    </div>
  );
}
