import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../components/inputs/RadioGroup";
import Modal from "../../components/Modals/Modal";
import InputFieldFloat from "../../components/inputs/InputFieldFloat";
import FileInput from "../../components/inputs/FileInput";
import {
  useInviteByCSVMutation,
  useInviteUserMutation,
} from "../../services/organization/adminUsers";

const InviteEmployeeModal = ({ onInvitationSuccess }) => {
  const [usersFile, setUsersFile] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const [csvCount, setCsvCount] = useState(0);
  const [inviteUser] = useInviteUserMutation();
  const [inviteByCSV] = useInviteByCSVMutation();

  const { t } = useTranslation(["common"]);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("validations.emailWrong"))
      .when("type", {
        is: (type) => type === "email",
        then: yup.string().required(t("validations.emailWrong")),
      }),
    type: yup.string().required(t("validations.emailWrong")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      type: "email",
    },
    validationSchema: validationSchema,

    validate: (values) => {
      validateEmail(values.email);
    },
    onSubmit: async (values) => {
      let result;
      if (values?.type == "email" && validateEmail(values.email)) {
        result = await inviteUser(values?.email);
      } else if (values?.type == "csv" && csvCount != 0) {
        if (usersFile.length > 0) {
          let dataForm = new FormData();
          dataForm.append("users", usersFile[0]);
          result = await inviteByCSV(dataForm);
          document.getElementById("emails_csv").value = "";
        }
      }

      if (result?.error || result == undefined) {
        toast.error(result?.error?.data?.message);
        // toast.error("Invalid File Extension");
      } else {
        if (onInvitationSuccess) onInvitationSuccess();
        setCloseModal(true);
        toast.success(t("invitationSuccess"));
      }
    },
  });

  const validateEmail = (input) => {
    var validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validEmailRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const getCsvCount = (file) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const emailsList = event.target.result
        .split(/\r\n|\r|\n/)
        .filter((line) => String(line).trim());
      let fileError = false;
      for (var i = 1; i < emailsList.length; i++) {
        if (!validateEmail(emailsList[i])) {
          fileError = true;
          break;
        }
      }
      if (!fileError) {
        setCsvCount(emailsList.length - 1);
      } else {
        setCsvCount(0);
      }
    };
    reader.readAsText(file);
  };

  return (
    <Modal
      id="staticBackdrop"
      title={t("addNewAccount")}
      mainBtn={t("sendInvitation")}
      mainBtnOnclick={formik.handleSubmit}
      className="invite-employee-modal"
      maxWidth="500"
      closeModal={closeModal}
      onClose={() => {
        setCloseModal(false);
        formik.resetForm();
        setUsersFile([]);
        setCsvCount(0);
      }}
    >
      <RadioGroup
        values={[
          {
            id: 1,
            text: t("oneEmployee"),
            subtText: t("inputs.email"),
            groupName: "type",
            value: "email",
          },
          {
            id: 2,
            text: t("moreThanOneEmployee"),
            subtText: t("CSVfile"),
            groupName: "type",
            value: "csv",
          },
        ]}
        formik={formik}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        {formik?.values?.type == "email" ? (
          <>
            <p className="font-16 font-color-grey">{t("enterEmailAddress")}</p>
            <InputFieldFloat
              marginBottom="0"
              label={t("inputs.email")}
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
          </>
        ) : (
          <>
            <p className="font-16 font-color-grey">
              {t("uploadNewEmail")} <br />
              {t("uploadHint")}
            </p>
            <FileInput
              id="emails_csv"
              name="emails_csv"
              accept=".csv"
              onChange={(e) => {
                getCsvCount(e.target.files[0]);
                setUsersFile(e.target.files);
              }}
              files={usersFile}
              lineCount={csvCount}
            />
          </>
        )}
      </form>
    </Modal>
  );
};

export default InviteEmployeeModal;
