import React from "react";
import { useTranslation } from "react-i18next";
import Avatar from "../inputs/Avatar";
import DoughnutChart from "./DoughnutChart";

const TotalCoursesCard = ({ completedCourses, totalCourses }) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="card-style total-courses-card">
      <DoughnutChart
        percentage={parseInt((completedCourses / totalCourses || 0) * 100)}
      />
      <div className="">
        <h1 className="font_18 font-extrabold mb-0">
          <span className="font_31">{completedCourses}</span>{" "}
          {t("completedCourse")}
        </h1>
        <p className="font_14 font-color-grey mb-0">
          {t("fromTotal")} <span className="font-bold">{totalCourses}</span>{" "}
          {t("subscribedCourses")}
        </p>
      </div>
    </div>
  );
};

export default TotalCoursesCard;
