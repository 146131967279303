import React, { useState, useEffect } from "react";
import { numbers } from "../utils/enums";

const SideBarCourseContent = ({
  lessonLogs,
  sections,
  currentLesson,
  setCurrentLesson,
  createLessonLog,
}) => {
  const renderVideoLesson = (lesson) => {
    let minutes = Math.floor(lesson?.video_duration / 60);
    let seconds = lesson?.video_duration - minutes * 60;
    if (seconds < 10) seconds = `0${seconds}`;

    const lessonLog = lessonLogs?.find(
      (lesson_log) => lesson_log?.lesson_id == lesson?.id
    );

    let lessonClasses = "";
    if (lessonLog?.progress == 100)
      lessonClasses = lessonClasses.concat(" done");
    if (currentLesson?.id == lesson?.id)
      lessonClasses = lessonClasses.concat(" active");

    return (
      <li className={lessonClasses}>
        <a
          onClick={() => {
            setCurrentLesson(lesson);
            // createLessonLog(lesson?.id);
          }}
          style={{ cursor: "pointer" }}
        >
          <span className="fas fa-check-circle fa-fw me-2 icon" />
          {lesson?.name}{" "}
          <span className="ms-auto">
            {minutes}:{seconds}
          </span>
        </a>
      </li>
    );
  };

  const renderFileLesson = (lesson) => {
    const lessonLog = lessonLogs?.find(
      (lesson_log) => lesson_log?.lesson_id == lesson?.id
    );

    let lessonClasses = "";
    if (lessonLog?.progress == 100)
      lessonClasses = lessonClasses.concat(" done");
    if (currentLesson?.id == lesson?.id)
      lessonClasses = lessonClasses.concat(" active");

    return (
      <li className={lessonClasses}>
        <a
          onClick={() => {
            setCurrentLesson(lesson);
            createLessonLog({ lesson_id: lesson?.id });
          }}
          style={{ cursor: "pointer" }}
        >
          <span className="fas fa-check-circle fa-fw me-2 icon" />
          {lesson?.name} <span className="ms-auto"></span>
        </a>
      </li>
    );
  };

  const renderExamSection = (lesson) => {
    const lessonLog = lessonLogs?.find(
      (lesson_log) => lesson_log?.lesson_id == lesson?.id
    );

    let lessonClasses = "";
    if (lessonLog?.progress == 100)
      lessonClasses = lessonClasses.concat(" done");
    if (currentLesson?.id == lesson?.id)
      lessonClasses = lessonClasses.concat(" active");

    return (
      <li className={lessonClasses}>
        <a
          onClick={() => {
            setCurrentLesson(lesson);
          }}
          style={{ cursor: "pointer" }}
        >
          <span className="fas fa-check-circle fa-fw me-2 icon" />
          {lesson?.name} <span className="ms-auto"></span>
        </a>
      </li>
    );
  };

  return (
    <div className="course-side col-lg-3 col-md-12">
      <div className="course-side__content">
        <div className="accordion" id="accordionExample">
          {sections?.map((section, i) => {
            let modalTarget = `#collapse${i}`;
            let modalId = `collapse${i}`;
            let headingId = `heading${i}`;

            let totalSectionWatchedLessonsNum = 0;
            section?.lessons?.map((lesson) => {
              const isLessonWatched = lessonLogs?.find(
                (lesson_log) => lesson_log?.lesson_id == lesson?.id
              );
              if (isLessonWatched) ++totalSectionWatchedLessonsNum;
            });

            return (
              <div className="accordion-item">
                <h2 className="accordion-header" id={headingId}>
                  <button
                    className="font-16 accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={modalTarget}
                    aria-expanded="true"
                    aria-controls={modalId}
                  >
                    {/* <span className="font-color-grey font-bold">
                      {" "}
                      المجموعة {numbers[i]}{" "}
                    </span> */}
                    <span className="font-color-grey font-bold">
                      {section?.name}
                    </span>
                    <span className="font-color-grey ms-2">
                      ({totalSectionWatchedLessonsNum}/{section?.lessons_count})
                    </span>
                  </button>
                </h2>
                <div
                  id={modalId}
                  className="accordion-collapse collapse show"
                  aria-labelledby={headingId}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul>
                      {section?.lessons?.map((lesson) => {
                        switch (lesson.type) {
                          case "Video":
                            return renderVideoLesson(lesson);

                          case "ExplanationFile":
                            return renderFileLesson(lesson);

                          case "Quiz":
                            return renderExamSection(lesson);
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SideBarCourseContent;
