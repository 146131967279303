import PasswordInputFloat from "../../components/inputs/PasswordInputFloat";
import { Formik } from "formik";
import { useChangePasswordMutation } from "../../services/users/auth";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const { t } = useTranslation(["common", "auth"]);
  const [ChangePassword] = useChangePasswordMutation();

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmedPassword: "",
  };

  const validationSchema = yup.object({
    currentPassword: yup
      .string()
      .min(3, t("validations.passwordMin3"))
      .required(t("validations.passwordRequired")),
    newPassword: yup
      .string()
      .min(3, t("validations.passwordMin3"))
      .required(t("validations.newPasswordRequired")),
    confirmedPassword: yup
      .string()
      .oneOf(
        [yup.ref("newPassword"), null],
        t("validations.passwordConfirmation")
      )
      .required(t("validations.passwordConfirmationRequired")),
  });

  const onSubmit = async (values) => {
    console.log(values);
    const response = await ChangePassword({
      old_password: values.currentPassword,
      new_password: values.confirmedPassword,
    });
    if (response?.error) {
      toast.error(response?.error?.data?.message);
    } else {
      toast.success(t("updatedSuccessfully"));
    }
  };

  return (
    <div className="container-fluid change-password-page">
      <div className="row justify-content-center p-4">
        <div className="col-lg-9 col-md-12">
          <div className="row align-items-center">
            <div className="col-lg-6 offset-lg-2 col-md-12 order-2 order-lg-1">
              <div className="change-password-page__form flex-grow-1">
                <h3 className="font_24 font-extrabold">
                  {t("auth:changePassword")}
                </h3>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="change-password-page__current-password">
                        <p className="font_16 font-color-grey">
                          {t("auth:changePasswordHint")}
                        </p>
                        <PasswordInputFloat
                          label={t("auth:currentPassword")}
                          type="password"
                          name="currentPassword"
                          value={values.currentPassword}
                          onChange={handleChange}
                          error={errors.currentPassword}
                          touched={touched.currentPassword}
                        />
                      </div>

                      <div className="change-password-page__new-password">
                        <p className="font_16 font-color-grey">
                          {t("auth:createNewPassword")}
                        </p>
                        <PasswordInputFloat
                          label={t("auth:theNewPassword")}
                          type="password"
                          name="newPassword"
                          value={values.newPassword}
                          onChange={handleChange}
                          error={errors.newPassword}
                          touched={touched.newPassword}
                        />

                        <PasswordInputFloat
                          label={t("auth:confirmTheNewPassword")}
                          type="password"
                          name="confirmedPassword"
                          value={values.confirmedPassword}
                          onChange={handleChange}
                          error={errors.confirmedPassword}
                          touched={touched.confirmedPassword}
                        />
                      </div>

                      <button
                        className="btn btn-main-color d-block w-100"
                        type="submit"
                      >
                        {t("auth:changePassword")}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>

            <div className="col-lg-3 col-md-12 order-1 order-lg-2 mb-3 mb-lg-0">
              <div className="change-password-page__icon text-center text-lg-start">
                <img
                  src="/images/reset-password.png"
                  alt="reset-password"
                  width={382}
                  height={287}
                  className="object-fit-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
