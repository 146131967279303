import { CircularProgress } from "react-loading-indicators";

const LoadingIndicator = () => {
  return (
    <div className="loading-indicator">
      <CircularProgress size="medium" text="" textColor="" />
    </div>
  );
};

export default LoadingIndicator;
