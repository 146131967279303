import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const AdminQuizCard = ({ quiz }) => {
  const { t } = useTranslation("learningPaths");
  return (
    <div className="admin-card-quiz">
      <div className="d-flex gap-2 mb-3 flex-column flex-sm-row">
        <span className="label label-black">{quiz.course}</span>
        <span className="label label-black">{quiz.lesson}</span>
        <span className="label label-black">{quiz.time}</span>
      </div>
      <h3 className="font_20 font-extrabold"> {quiz.title} </h3>
      <ul className="admin-card-quiz__answers">
        {quiz.answers.map((answer, i) => (
          <li
            key={answer.id}
            className="d-flex align-items-sm-center flex-column flex-sm-row"
          >
            <div className="d-flex">
              <span className="font-color-grey-light font_16 font-extrabold">
                {i + 1}-{" "}
              </span>
              <p className="font_16 font-bold px-3 mb-0">{answer.title}</p>
            </div>
            {answer.isCorrect && (
              <span className="label label-green admin-card-quiz__answers__correct font-bold mt-2 mt-sm-0 text-center text-sm-start">
                <i className="fas fa-check-circle me-2" />
                {t("learningPaths:rightAnswer")}
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminQuizCard;

AdminQuizCard.propTypes = {
  quiz: PropTypes.shape({
    title: PropTypes.string,
    course: PropTypes.string,
    lesson: PropTypes.string,
    time: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        isCorrect: PropTypes.bool,
      })
    ),
  }),
};
