import React from "react";
import hexToRgba from "hex-to-rgba";
import PropTypes from "prop-types";

const StatsCard = ({
  title,
  num,
  state,
  titleFontColor
}) => {

  const STATECOLOR = {
    "default": "#000",
    "success": "#01C299",
    "fail": "#AF151F"
  }

  const stateCardStyle = {
    backgroundColor: hexToRgba(STATECOLOR[state], 0.04)
  }


  return (
    <div
      className="card-style stats-card"
      style={stateCardStyle}
    >
      <p className={`font_12 ${titleFontColor} mb-0`}>{title}</p>
      <p className={`font_18 font-extrabold mb-0`} style={{ color: STATECOLOR[state] }}> {num} </p>
    </div>
  );
};

export default StatsCard;

StatsCard.propTypes = {
  color: PropTypes.string,
  titleFontColor: PropTypes.string,
  num: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

StatsCard.defaultProps = {
  color: "#000",
  titleFontColor: ""
}
