import React from "react";

export default function TabsNav({ tabsConfig }) {
  return (
    <div className="nav nav-tabs" id="nav-tab" role="tablist">
      {tabsConfig.map((tab, idx) => (
        <button
          className={`nav-link ${idx === 0 && "active"}`}
          id={`nav-${tab.name}-tab`}
          data-bs-toggle="tab"
          data-bs-target={`#nav-${tab.name}`}
          type="button"
          role="tab"
          aria-controls={`nav-${tab.name}`}
          aria-selected="true"
          onClick={tab.clickHandler}
          key={tab.name}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
}
