import React from "react";

export default function InputSearch({ value, onChange, placeHolder }) {
  return (
    <div className="input-search ">
      <span className="input-icon">
        <span className="fas fa-search fa-fw"></span>
      </span>
      <input
        type="text"
        className="form-control"
        placeholder={placeHolder}
        aria-label="Username"
        aria-describedby="addon-wrapping"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
