import React from "react";

const ImageInput = ({ id, name, accept, onChange, imageUrl, imageAlt = "img", className, onDelete, error, touched, onBlur }) => {
  function openFileSelector(e) {
    e.preventDefault();
    const fileInput = document.getElementById(id);
    fileInput.click();
  }

  if (imageUrl) {
    return (
      <div className="position-relative">
        <div className="position-absolute image-input_image-options ">
          <i className="fas fa-edit fa-lg image-input_image-options_icon" onClick={openFileSelector}></i>
          <i className="far fa-trash-alt fa-lg image-input_image-options_icon" onClick={onDelete}></i>
        </div>
        <img className={className} src={imageUrl} alt={imageAlt} />
        <input type="file" id={id} name={name} accept={accept} onChange={onChange} style={{ display: "none" }} />
      </div>
    );
  }
  return (
    <div className="input-text">
      <label htmlFor={id} className={`image-input ${className} `}>
        <img className="icon me-2" alt="" src="/images/icons/upload.svg" />

        <div>
          <p>ارفع صورة الغلاف للمسار التعليمي</p>
        </div>

        <input type="file" id={id} name={name} accept={accept} onChange={onChange} onBlur={onBlur} />
      </label>
      {error && touched ? <div className="input-error">{error}</div> : null}
    </div>
  );
};

export default ImageInput;
