import React, { useEffect, useState } from "react";
import DepartmentCRUDform from "./DepartmentCRUDform";
import {
  useAdminGetDepartmentsQuery,
  useAdminDeleteDepartmentMutation,
  useAdminUpdateDepartmentMutation,
  useAdminCreateDepartmentMutation,
} from "../../../services/organization/settings";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Departments({ submittingController }) {
  const [initialDepData, setInitialDepData] = useState(null);

  const { data: departmentsList, isLoading } = useAdminGetDepartmentsQuery();
  const [createDepartmentMutation] = useAdminCreateDepartmentMutation();
  const [updateDepartmentMutation] = useAdminUpdateDepartmentMutation();
  const [deleteDepartmentMutation] = useAdminDeleteDepartmentMutation();

  const createDepartment = (department) => createDepartmentMutation(department);
  const updateDepartment = (department) => updateDepartmentMutation(department);
  const deleteDepartment = (departmentID) =>
    deleteDepartmentMutation(departmentID);
  const { t } = useTranslation(["common", "settings"]);

  const handleSubmittingDepartmentData = async (departmentsUpdatedList) => {
    try {
      await departmentsUpdatedList.forEach((department) => {
        if (department.id) {
          //find original department and check it's not changed
          const originalDepartmentData = departmentsList.data.find(
            (dep) => dep.id === department.id
          );
          const sameDepartmentData =
            originalDepartmentData?.name_ar === department.departmentArName &&
            originalDepartmentData?.name_en === department.departmentEnName;
          if (!sameDepartmentData) {
            updateDepartment({
              name_ar: department.departmentArName,
              id: department.id,
              name_en: department.departmentEnName,
            });
          }
        } else {
          createDepartment({
            name_ar: department.departmentArName,
            name_en: department.departmentEnName,
          });
        }
      });
      toast.success(t("successfulSave"));
    } catch (e) {
      console.error("something went wrong", e);
      toast.error(t("failedSaving"));
    }
  };

  useEffect(() => {
    if (departmentsList) {
      setInitialDepData(departmentsList.data);
    }
  }, [departmentsList]);

  if (isLoading || !initialDepData) {
    return null;
  }

  return (
    <div>
      <header className="mt-4 mb-4">
        <h3 className="font-extrabold font_20 ">
          {t("departments")} ({departmentsList.data.length})
        </h3>
        <p className="font-color-grey mb-4">{t("settings:departmentsHint")}</p>
      </header>
      <DepartmentCRUDform
        departmentsList={initialDepData}
        submittingController={submittingController}
        handleSubmittingDepartmentData={handleSubmittingDepartmentData}
        deleteDepartment={deleteDepartment}
      />
    </div>
  );
}
