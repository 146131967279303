import hexToRgba from "hex-to-rgba";

export function setDocumentLang(lang) {
  document.querySelector("html").setAttribute("lang", lang);
}

export function getDocumentLang() {
  return document.querySelector("html").getAttribute("lang");
}

export const getAccountStatus = (isRegistered, isActive, t) => {
  let accountStatus;
  let accountStatuses = [
    {
      type: "notResigtered",
      name: t("accountStatuses.pending"),
      color: "#F1AE4E",
    },
    {
      type: "notActive",
      name: t("accountStatuses.disabled"),
      color: "#AF151F",
    },
    {
      type: "active",
      name: t("accountStatuses.active"),
      color: "#01C299",
    },
  ];

  if (!isRegistered)
    accountStatus = accountStatuses?.find(
      (stats) => stats.type == "notResigtered"
    );
  else if (!isActive)
    accountStatus = accountStatuses?.find((stats) => stats.type == "notActive");
  else accountStatus = accountStatuses?.find((stats) => stats.type == "active");
  return accountStatus;
};

export const getGrade = (ExamPrecentage, t) => {
  if (ExamPrecentage < 50) return t("grades.failed");
  else if (ExamPrecentage < 65) return t("grades.pass");
  else if (ExamPrecentage < 75) return t("grades.good");
  else if (ExamPrecentage < 85) return t("grades.veryGood");
  else return t("grades.excellent");
};

export const downloadImage = (url) => {
  fetch(url, {
    mode: "no-cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.download = url.replace(/^.*[\\\/]/, "");
      a.href = blobUrl;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
};

export const getRenderColor = (color) => {
  let mainColor = color || "#AF151F";
  let colorRgb = hexToRgba(mainColor, 1)
    .replace(/^rgba?\(|\s+|\)$/g, "")
    .slice(0, -2);

  let renderColorRgb = document.documentElement.style.setProperty(
    "--color-primary-rgb",
    colorRgb
  );
  const RenderColor =
    document.documentElement.style.setProperty("--color-primary", mainColor) &&
    renderColorRgb;

  return RenderColor;
};

export const isLocalhost = (url) => {
  return url.includes("localhost") || url.includes("127.0.0.1");
};
