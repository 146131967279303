import { api } from "../index";

export const learningTrack = api.injectEndpoints({
  endpoints: (builder) => ({
    listTracks: builder.query({
      query: ({ page = 1, perPage = 10 }) =>
        `/api/v1/user/learning_tracks?page=${page}&per_page=${perPage}`,
    }),

    getTrack: builder.query({
      query: (trackId) => `/api/v1/user/learning_tracks/${trackId}`,
    }),
  }),
});

export const { useListTracksQuery, useGetTrackQuery } = learningTrack;
