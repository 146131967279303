import EmployeeLayout from "../layout/EmployeeLayout";

const NotFoundPage = () => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ marginTop: "130px" }}
    >
      <img
        className="main-logo"
        alt=""
        src="/images/Error404.svg"
        style={{ maxWidth: "332px" }}
      />

      <div className="mb-4 d-flex flex-column align-items-center justify-content-center">
        <h1 className="mb-0 mt-1 font_24 font-extrabold ">الصفحة غير موجودة</h1>
        <p className="mb-0 mt-2 font_16 font-color-grey ">
          عفواً يبدو انه تعذر الوصول إلى هذه الصفحة وأنها غير موجودة الآن
        </p>
      </div>

      <a href="/" className="btn btn-main-color font-15">
        العودة للصفحة الرئيسية
        {/* <span className="fas fa-plus fa-fw ms-2"></span> */}
      </a>
    </div>
  );
};

export default NotFoundPage;
