import React from "react";
import Modal from "./Modal";
import SearchAutoComplete from "../../components/inputs/SearchAutoComplete";
import AdminLearningPathdetailsCard from "../../components/cards/AdminLearningPathdetailsCard";
import { useLazyListCoursesQuery } from "../../services/organization/adminCourse";
import { useTranslation } from "react-i18next";

function CourseOptionComponent({ option }) {
  return (
    <div className="d-flex" style={{ padding: "5px", cursor: "pointer" }}>
      <img src={option.image_url} style={{ width: "30px", height: "30px" }} />
      <p style={{ marginRight: "10px", marginBottom: "0px" }}>
        {" "}
        {option.title}
      </p>
    </div>
  );
}

export default function EditLearningTrackCoursesModal({
  id,
  isOpened,
  onClose,
  handleSave,
  deletedCoursesIds,
  setDeltedCoursesIds,
  courses,
  setCourses,
}) {
  const coursesIds = courses.map((course) => course.id);
  const coursesIdsSet = new Set(coursesIds);
  const [listCourses, { data: coursesOptions }] = useLazyListCoursesQuery();
  const { t } = useTranslation(["common", "learningPaths"]);

  async function search(e) {
    if (e.target.value.trim().length > 1) {
      await listCourses({
        filters: `filters[keyword]=${e.target.value.trim()}`,
      });
    }
  }

  async function deleteCourse(courseId) {
    try {
      const tempCourses = courses.filter((course) => course.id !== courseId);
      setDeltedCoursesIds([...deletedCoursesIds, courseId]);
      setCourses(tempCourses);
    } catch (error) {}
  }

  async function addCourse(course) {
    try {
      if (!coursesIdsSet.has(course.id)) {
        setCourses([...courses, course]);
      }
    } catch (error) {}
  }

  return (
    <Modal
      id={id}
      title={t("learningPaths:addCourseToTrack")}
      mainBtn={t("save")}
      mainBtnOnclick={handleSave}
      className="add-course-learning-path-modal"
      maxWidth="670"
      onClose={onClose}
      isOpened={isOpened}
    >
      <SearchAutoComplete
        label={t("learningPaths:courseName")}
        type="text"
        name="name"
        onChange={search}
        onSelect={addCourse}
        options={coursesOptions?.data || []}
        optionComponent={CourseOptionComponent}
      />
      <ul className="add-course-learning-path-modal__result">
        {courses.map((course) => (
          <li
            key={`model-course-${course.id}`}
            className="d-flex align-items-start mb-2"
          >
            <AdminLearningPathdetailsCard course={course} smallCard />
            <button
              className="flex-0"
              onClick={() => {
                deleteCourse(course.id);
              }}
            >
              <span className="fas fa-times fa-fw font-color-grey"></span>
            </button>
          </li>
        ))}
      </ul>
    </Modal>
  );
}
