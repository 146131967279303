import React, { useEffect, useState } from "react";
import { useFormik, FormikProvider, FieldArray } from "formik";
import * as yup from "yup";
import InputFieldFloat from "../../inputs/InputFieldFloat";
import { toast } from "react-toastify";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import Icon from "../../icons/Icon";
import { TimesStrokeIcon } from "../../icons/Icons";
import { useTranslation } from "react-i18next";

const DepartmentCRUDform = ({
  departmentsList: departmentInitialList,
  submittingController,
  deleteDepartment,
  handleSubmittingDepartmentData,
}) => {
  const [deleteModalMetaData, setDeleteModalData] = useState(null);
  const [isModalOpen, setModelOpen] = useState(false);
  const { t } = useTranslation(["common", "settings"]);

  const validationSchema = yup.object({
    departments: yup.array().of(
      yup.object().shape({
        departmentArName: yup
          .string()
          .required(t("settings:departmentNameARRequired"))
          .test(
            "is-unique-arabic-name",
            t("settings:uniqueDepartmentNameAR"),
            function (value) {
              const parent = this.from[1].value;
              const otherDepartments = parent.departments || [];
              return !(
                otherDepartments.filter(
                  (department) => department.departmentArName === value
                ).length > 1
              );
            }
          ),
        departmentEnName: yup
          .string()
          .required(t("settings:departmentNameENRequired"))
          .test(
            "is-unique-english-name",
            t("settings:uniqueDepartmentNameEN"),
            function (value) {
              const parent = this.from[1].value;
              const otherDepartments = parent.departments || [];
              return !(
                otherDepartments.filter(
                  (department) => department.departmentEnName === value
                ).length > 1
              );
            }
          ),
      })
    ),
    id: yup.string().optional(),
    users_count: yup.number().optional(),
  });

  const formik = useFormik({
    initialValues: {
      departments: departmentInitialList.length
        ? departmentInitialList.map((dep) => ({
            departmentArName: dep.name_ar,
            departmentEnName: dep.name_en,
            id: dep.id || null,
            users_count: dep.users_count,
          }))
        : [
            {
              departmentArName: "",
              departmentEnName: "",
            },
          ],
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmittingDepartmentData(values.departments);
    },
  });

  useEffect(() => {
    if (submittingController.isSaving) {
      formik.handleSubmit();
      submittingController.setIsSaving(false);
    }
  }, [submittingController.isSaving]);

  const cancelDelete = () => toast.error(t("settings:deleteDepartmentError"));

  const handleDeleteDepartment = ({ index, arrayHelpers }) => {
    const targetedDepartment = formik.values?.departments?.[index];
    const departmentID = targetedDepartment?.id;
    const departmentHasUsers = targetedDepartment?.users_count;

    if (departmentHasUsers) {
      cancelDelete();
      setModelOpen(false);
    } else {
      try {
        if (departmentID) deleteDepartment(departmentID);
        arrayHelpers.remove(index);
        toast.success(t("common:successfullyDelete"));
        setModelOpen(false);
      } catch (e) {
        toast.error(t("common:failedDelete"));
        setModelOpen(false);
      }
    }
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className="employee-department">
        <ConfirmationModal
          confirmationMessage={t("settings:deleteDepartmentConfirmation")}
          handleConfirmation={() => handleDeleteDepartment(deleteModalMetaData)}
          modalId="confirm-delete-department-modal"
          isOpened={isModalOpen}
        />
        <FieldArray
          name="departments"
          render={(arrayHelpers) => (
            <>
              {formik?.values?.departments &&
                formik.values.departments.map((_, index) => {
                  const departmentHasNoMembers =
                    formik?.values?.departments?.[index]?.users_count === 0;
                  const isSubmitted = formik?.values?.departments?.[index]?.id;
                  const modalControllerProperties = departmentHasNoMembers
                    ? {
                        "data-bs-toggle": "modal",
                        "data-bs-target": "#confirm-delete-department-modal",
                      }
                    : {};
                  return (
                    <div
                      className="d-flex align-items-center align-items-md-start gap-2 mb-3 mb-md-4"
                      key={index}
                    >
                      {/** both these conventions do the same  */}
                      <div className="flex-grow-1 d-md-flex gap-3">
                        <div className="flex-fill mb-3 mb-md-0">
                          <InputFieldFloat
                            containerClassName="mb-0"
                            label={t("settings:departmentNameAr", {
                              index: index + 1,
                            })}
                            type="text"
                            name={`departments[${index}].departmentArName`}
                            value={
                              formik.values?.departments[index]
                                ?.departmentArName
                            }
                            onChange={formik.handleChange}
                            error={
                              formik.errors?.departments?.[index]
                                ?.departmentArName
                            }
                            touched={
                              formik.touched?.departments?.[index]
                                ?.departmentArName
                            }
                          />
                        </div>

                        <div className="flex-fill mb-3 mb-md-0">
                          <InputFieldFloat
                            containerClassName="mb-0"
                            label={t("settings:departmentNameEn", {
                              index: index + 1,
                            })}
                            type="text"
                            name={`departments[${index}].departmentEnName`}
                            value={
                              formik.values?.departments[index]
                                ?.departmentEnName
                            }
                            onChange={formik.handleChange}
                            error={
                              formik.errors?.departments?.[index]
                                ?.departmentEnName
                            }
                            touched={
                              formik.touched?.departments?.[index]
                                ?.departmentEnName
                            }
                          />
                        </div>
                      </div>

                      <button
                        {...modalControllerProperties}
                        className="border border-2 border-radius-12 border-color flex align-items-center justify-content-between remove-btn"
                        id="deleteDepartmentButton"
                        type="button"
                        onClick={() => {
                          if (!isSubmitted) {
                            arrayHelpers.remove(index);
                            toast.success(t("common:successfullyDelete"));
                          } else if (departmentHasNoMembers) {
                            //we can delete departments with no members
                            setDeleteModalData({ index, arrayHelpers });
                          } else {
                            // departments with members shouldn't be deletable
                            cancelDelete();
                          }
                        }}
                      >
                        <Icon
                          icon={<TimesStrokeIcon />}
                          iconName="times"
                          iconSize={20}
                          height={20}
                          viewBox="0 0 20 20"
                          iconColor="#bbbabf"
                          hasStroke
                        />
                      </button>
                    </div>
                  );
                })}
              <div className="row">
                <div className="col col-md-5">
                  <button
                    type="button"
                    className="btn btn-dark-color font-15"
                    onClick={() =>
                      arrayHelpers.push({
                        departmentArName: "",
                        departmentEnName: "",
                      })
                    }
                    style={{ display: "block", width: "100%" }}
                  >
                    <span className="fa fa-plus mx-2" />
                    <span>{t("settings:addnewDepartment")}</span>
                  </button>
                </div>
              </div>
            </>
          )}
        />
      </form>
    </FormikProvider>
  );
};

export default DepartmentCRUDform;
