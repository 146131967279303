import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import "moment/locale/ar";
import { LANGUAGE_COOKIE_NAME, FALLBACK_LANGUAGE } from "./constants";

// English translation files imports
import commonEn from "./locales/en/common.json";
import profileEn from "./locales/en/profile.json";
import learningPathsEn from "./locales/en/learningPaths.json";
import leaderboardEn from "./locales/en/leaderboard.json";
import communityFeedEn from "./locales/en/communityFeed.json";
import authEn from "./locales/en/auth.json";
import EmployeesEn from "./locales/en/employees.json";
import SettingsEn from "./locales/en/settings.json";

// Arabic translation files imports
import commonAr from "./locales/ar/common.json";
import profileAr from "./locales/ar/profile.json";
import learningPathsAr from "./locales/ar/learningPaths.json";
import leaderboardAr from "./locales/ar/leaderboard.json";
import communityFeedAr from "./locales/ar/communityFeed.json";
import authAr from "./locales/ar/auth.json";
import EmployeesAr from "./locales/ar/employees.json";
import SettingsAr from "./locales/ar/settings.json"

const resources = {
  en: {
    common: commonEn,
    profile: profileEn,
    learningPaths: learningPathsEn,
    leaderboard: leaderboardEn,
    communityFeed: communityFeedEn,
    auth: authEn,
    employees: EmployeesEn,
    settings: SettingsEn
  },
  ar: {
    common: commonAr,
    profile: profileAr,
    learningPaths: learningPathsAr,
    leaderboard: leaderboardAr,
    communityFeed: communityFeedAr,
    auth: authAr,
    employees: EmployeesAr,
    settings: SettingsAr
  },
};

const setLanguageCookie = (lng) => {
  document.cookie = `${LANGUAGE_COOKIE_NAME}=${lng}`;
};

const setDocumentLang = (lng) => {
  const direction = lng === "ar" ? "rtl" : "ltr";
  const htmlTag = document.querySelector("html");

  // assign multiple attributes at once to an <html> tag
  Object.assign(htmlTag, { lang: lng, id: lng, dir: direction });
};

const getLanguageCookie = () => {
  const match = document.cookie.match(
    new RegExp(`(^| )${LANGUAGE_COOKIE_NAME}=([^;]+)`)
  );

  // returns fallback language if language cookie not found
  return match ? match[2] : FALLBACK_LANGUAGE;
};

i18n.on("initialized", (options) => {
  // sets the language cookie value on initialization to be read later
  setLanguageCookie(options.lng);
  // sets initial document language, id and direction
  setDocumentLang(options.lng);
  moment.locale(options.lng);
});

i18n.on("languageChanged", (lng) => {
  // update language cookie on change
  setLanguageCookie(lng);
  // update document lang, direction and id attr for styling purpose
  setDocumentLang(lng);
  moment.locale(lng);
});

i18n.use(initReactI18next).init({
  resources,
  lng: getLanguageCookie(),
  fallbackLng: FALLBACK_LANGUAGE,
  supportedLngs: ["en", "ar"],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
