import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAdminGetUserQuizStatsQuery } from "../../../services/organization/adminUsers";
import ExamProgressCard from "../../cards/Exam/ExamProgressCard";
import LoadingIndicator from "../../LoadingIndicator";
import { useTranslation } from "react-i18next";

const Exams = ({ user }) => {
  const { data: quizStats, isLoading: isGetUserQuizStatsLoading } =
    useAdminGetUserQuizStatsQuery(user?.id);

  const { t } = useTranslation(["common"]);

  if (isGetUserQuizStatsLoading) return <LoadingIndicator />;

  return (
    <div className="profile-about">
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          {quizStats?.data?.length ? (
            quizStats?.data?.map((exam) =>
              exam?.lesson_log?.answers?.length ? (
                <Link to={`/admin/${user?.id}/exams/${exam?.lesson_log?.id}`}>
                  <ExamProgressCard exam={exam} className="mb-4" />
                </Link>
              ) : null
            )
          ) : (
            <p style={{ textAlign: "center" }}>
              <b>{t("noExamsYet")}</b>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Exams;
