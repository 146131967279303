import { api } from "../index";

export const adminDiscoverCourses = api.injectEndpoints({
  endpoints: (builder) => ({
    listCourses: builder.query({
      query: ({ page, filters }) => {
        return `/api/v1/organization/courses?page=${page || 1}${filters ? `&${filters}` : ""}`;
      },
      providesTags: ["discoverCourses"],
      transformResponse: (response) => {
        if (!response || !response.data || response.data.length === 0) {
          // Clear the cache if the response data is empty
          return { data: [], links: {}, meta: {} };
        }
        // Return the original response if it's not empty
        return response;
      },
    }),
    listTopics: builder.query({
      query: () => "/api/v1/organization/topics",
    }),
  }),
});

export const { useLazyListCoursesQuery, useListCoursesQuery, useLazyListTopicsQuery } = adminDiscoverCourses;
