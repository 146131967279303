import React, { useContext, useRef } from "react";

const WebSocketContext = React.createContext();

const WebSocketProvider = ({ children }) => {
  const webSocketRef = useRef(null);

  const connect = (url) => {
    return new Promise((resolve, reject) => {
      webSocketRef.current = new WebSocket(url);
      if (webSocketRef.current) {
        resolve(webSocketRef.current);
      } else {
        reject();
      }
    });
  };

  const disconnect = () => {
    if (webSocketRef.current) {
      webSocketRef.current.close();
      webSocketRef.current = null;
    }
  };

  const send = (message) => {
    if (webSocketRef.current) {
      webSocketRef.current.onopen = () =>
        webSocketRef.current.send(JSON.stringify(message));
    } else {
      console.warn("WebSocket connection not open.");
    }
  };

  const subscribe = (channel, callback) => {
    //still needs some updates 
    if (webSocketRef.current) {
            const message = {
                "command": "subscribe",
                "identifier": "{\"channel\": \"EmployeeChannel\"}"
              }
      const messageHandler = (event) => {
        const data = JSON.parse(event.data);
        if (data?.message?.payload) {
          callback(data.message);
        }
      };

      send(message);
      webSocketRef.current.addEventListener("message", messageHandler);
      // webSocketRef.current.onclose = webSocketRef.current.removeEventlistener(
      //   "message",
      //   messageHandler
      // );
      
    } else {
      console.warn("WebSocket connection not open.");
    }
  };

  const value = {
    connect,
    disconnect,
    send,
    subscribe,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

const useWebSocket = () => useContext(WebSocketContext);

export { WebSocketProvider, useWebSocket };
