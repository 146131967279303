import React from "react";
import Avatar from "../inputs/Avatar";
import hexToRgba from "hex-to-rgba";
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const DoughnutChart = ({
  percentage = 0,
  color,
  levelText,
  questionTotals,
}) => {
  const MainColor = "#36BA8E";
  const MainColorRgb = "54, 186, 142";

  return (
    <div className="doughnut-chart " style={{ width: 80, height: 80 }}>
      {/* ref https://www.npmjs.com/package/react-circular-progressbar */}
      <CircularProgressbarWithChildren
        value={percentage}
        // text={`${percentage}%`}
        strokeWidth="13"
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)

          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: "round",

          // Text size
          textSize: "20px",

          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 0.5,
          // Can specify path transition in more detail, or remove it entirely
          // pathTransition: 'none',

          // Colors
          pathColor: color ? color : MainColor,
          textColor: color ? color : MainColor,
          trailColor: color
            ? hexToRgba(color, 0.15)
            : `rgba(${MainColorRgb}, 0.15)`,
        })}
      >
        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
        <div className="CircularProgressbar-text text-center">
          {levelText && (
            <p
              className="font_12 mb-0"
              style={{ color: color ? color : MainColor }}
            >
              {levelText}
            </p>
          )}
          {!questionTotals && (
            <strong
              style={{ color: color ? color : MainColor }}
            >{`${percentage}%`}</strong>
          )}
          {questionTotals && (
            <>
              <p className="font_10 mb-0 font-color-grey">الوقت</p>
              <strong
                style={{ color: color ? color : MainColor }}
              >{`${percentage}/${questionTotals}`}</strong>
            </>
          )}
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default DoughnutChart;
