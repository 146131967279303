import { useTranslation } from "react-i18next";
import useViewPort from "../../app/hooks/useViewPort";
import { MEDIA_BREAKPOINT_LG } from "../../constants";
import i18n from "../../i18n";
import { setDocumentLang } from "../../utils/helpers";
import Icon from "../icons/Icon";
import { LanguageStrokeIcon } from "../icons/Icons";
import { useUpdateProfileMutation } from "../../services/users/auth";
import { useAdminUpdateOrganizationMutation } from "../../services/organization/settings";

const SwitchLang = () => {
  const { t } = useTranslation("common");
  const lang = i18n.language;
  const { width } = useViewPort();

  const isAdmin = localStorage.getItem("is_admin");

  const [updateProfileAdmin] = useAdminUpdateOrganizationMutation();
  const [updateProfileUser] = useUpdateProfileMutation();

  const onChangeLanguage = () => {
    i18n.changeLanguage(lang === "ar" ? "en" : "ar");
    setDocumentLang(lang === "ar" ? "en" : "ar");
    isAdmin === "true"
      ? updateProfileAdmin({ language: lang === "ar" ? "en" : "ar" })
      : updateProfileUser({ language: lang === "ar" ? "en" : "ar" });
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  };

  return (
    <button
      className="btn btn-grey-color font-14 rounded-pill lang"
      onClick={onChangeLanguage}
    >
      <Icon
        icon={<LanguageStrokeIcon />}
        iconColor="#bbbabf"
        hasStroke
        iconSize={width <= MEDIA_BREAKPOINT_LG ? 15 : 20}
        iconName="language"
        viewBox="0 0 22 22"
        className="me-2"
      />
      <span
        className={`${
          width <= MEDIA_BREAKPOINT_LG ? "font_12" : "font_14"
        }  font-extrabold`}
      >
        {width <= MEDIA_BREAKPOINT_LG
          ? t("navbar.shortLang")
          : t("navbar.lang")}
      </span>
    </button>
  );
};

export default SwitchLang;
