import React from "react";

export default function InputSelectFloat({
  name,
  label,
  type,
  disabled,
  marginBottom,
  value,
  onChange,
  error,
  touched,
  children,
}) {
  return (
    <div className="input-text">
      <div
        className="did-floating-label-content"
        style={{ marginBottom: marginBottom ? marginBottom + "px" : "27px" }}
      >
        <select
          name={name}
          className="did-floating-input form-select"
          type={type}
          placeholder=" "
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          {children}
        </select>
        <label className="did-floating-label">{label}</label>
        {error && touched ? <div className="input-error">{error}</div> : null}
      </div>
    </div>
  );
}
