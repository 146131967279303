import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Avatar from "../inputs/Avatar";
import { useSelector } from "react-redux";
import ProgressBar from "../../components/ProgressBar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const HonorBoardCard = ({ leaderboardUsers, isAdmin, currentUserOrder }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "leaderboard"]);

  return (
    <div className="card-style posts-leader-board-card">
      <div className="d-flex flex-wrap align-center posts-leader-board-card__header">
        <h1 className="font_20 font-extrabold mb-0">
          {t("leaderboard:leaderboard")}
        </h1>
        <Link
          to={isAdmin ? "/admin/leaderboard" : "/employee/leaderboard"}
          className="ms-auto font_14 font-extrabold font-color-grey"
        >
          {t("showAll")}
          <span className="fas fa-arrow-right fa-fw ms-2"></span>
        </Link>
      </div>

      <ul className="leader-board leader-board__list">
        {leaderboardUsers?.map((user, i) => {
          let isThisUserMyself = user?.id == currentUser?.id;
          return (
            <li key={user.name}>
              <div
                className={
                  isThisUserMyself
                    ? "leader-board-card bg-grey-radius myself-selected"
                    : `leader-board-card leader-${user?.rank}`
                }
              >
                <span className="num me-3 font_14 font-extrabold">{++i}</span>
                <Avatar width="40" height="40" avatarUrl={user?.avatar_url} />
                <div className="ms-2">
                  <h1
                    role="button"
                    onClick={() =>
                      navigate(
                        isAdmin
                          ? `/admin/employee/${user.id}`
                          : `/employee/profile/${user.id}`
                      )
                    }
                    className="mb-1 font_15 font-extrabold"
                  >
                    {user?.name}
                  </h1>
                  {isAdmin ? (
                    <p className="mb-0 font_14" style={{ color: "#888888" }}>
                      {user?.email}
                    </p>
                  ) : (
                    <p className="mb-0 font_14 font-color-grey">
                      <span className="font-extrabold">{user?.points}</span>{" "}
                      {t("points")}
                    </p>
                  )}
                </div>
                {isAdmin && (
                  <>
                    <span style={{ margin: "0 auto" }}></span>
                    <div className="label me-3 points">
                      <i className="fas fa-star font-color-gold font_16 me-2" />
                      <span className="font_16 font-extrabold">
                        {user?.points || 0}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </li>
          );
        })}
        {!isAdmin && currentUserOrder > 3 && (
          <li>
            <div className="leader-board-card bg-grey-radius myself-selected">
              <span className="num me-2 font_14 font-extrabold">
                {currentUserOrder}
              </span>
              <Avatar width="40" height="40" />
              <div className="ms-2">
                <h1 className="mb-2 font_15 font-extrabold">
                  {currentUser?.name}{" "}
                  <span className="font-color-grey-light font-regular">
                    ({t("you")})
                  </span>
                </h1>
                <p className="mb-0 font_16 font-color-grey">
                  {" "}
                  <span className="font-extrabold">
                    {currentUser?.points}
                  </span>{" "}
                  {t("points")}
                </p>
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default HonorBoardCard;
