import { useTranslation } from "react-i18next";
import EmployeeProfileCard from "../../cards/EmployeeProfileCard";
import HomeStatsCard from "../../cards/HomeStatsCard";
import SwiperSlider from "../../SwiperSlider";
import { SwiperSlide } from "swiper/react";
import { useState } from "react";
import Icon from "../../icons/Icon";
import { Chartline } from "../../icons/Icons";
import CommunityFeed from "../../../pages/employee/CommunityFeed";
import { useGetUserCompletedCoursesQuery as useGetUserCertificates } from "../../../services/users/userProfile";

const Profile = ({ currentUser, listPosts, posts }) => {
  const { t } = useTranslation("common");

  const { data: certificates, isLoading } = useGetUserCertificates(
    currentUser?.id
  );

  const [isshowImage, setisShowImage] = useState(false);
  const [currentImage, setCurrentImage] = useState("");


  const onShowImage = (imageIndex) => {
    setisShowImage(true);
    const slectedImage = certificates.data.find(
      (certificate) => certificate?.id === imageIndex
    );
    setCurrentImage(slectedImage.enrollment.certificate_url);
  };

  const onClose = () => {
    setisShowImage(false);
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="container-fluid">
      <div className="row justify-content-center p-4 employee-profile">
        <div className="col-xxl-3 col-xl-4 col-md-12 mb-4 mb-xl-0">
          <EmployeeProfileCard currentUser={currentUser} />
        </div>
        <div className="col-xxl-7 col-xl-7  col-md-12">
          <section className="card-style employee-profile__quick-reports">
            <h5 className="font_22 font-extrabold">{t("quickReports")}</h5>
            <ul className="row">
              <li className="col-xl-3 col-md-3 col-sm-6 ">
                <HomeStatsCard
                  bgColor="rgba(157, 78, 221, 0.06)"
                  img="graduation-cap-solid-opacity.svg"
                  title={t("learningTracksCount")}
                  num={currentUser?.learning_tracks_count}
                  singleNumber
                  titleFontSize={12}
                  numFontSize={30}
                />
              </li>
              <li className="col-xl-3 col-md-3 col-sm-6 ">
                <HomeStatsCard
                  bgColor="rgba(241, 115, 0, 0.06)"
                  img="courses-opacity.svg"
                  title={t("coursesCount")}
                  num={currentUser?.courses_count}
                  singleNumber
                  titleFontSize={12}
                  numFontSize={30}
                />
              </li>
              <li className="col-xl-3 col-md-3 col-sm-6 ">
                <HomeStatsCard
                  bgColor="rgba(136, 136, 136, 0.06)"
                  img="starSolid.svg"
                  title={t("pointsCollected")}
                  num={currentUser?.points}
                  singleNumber
                  titleFontSize={12}
                  numFontSize={30}
                />
              </li>
              <li className="col-xl-3 col-md-3 col-sm-6 ">
                <HomeStatsCard
                  bgColor="rgba(1, 194, 153, 0.06)"
                  img="badge.svg"
                  title={t("sort")}
                  num={currentUser?.rank || 0}
                  singleNumber
                  titleFontSize={12}
                  numFontSize={30}
                />
              </li>
            </ul>
          </section>

          <section className="card-style employee-profile__tracks slider-page">
            <SwiperSlider
              sliderName="certificates"
              title={t("certificates")}
              showAllNavigationUrl={"/employee/learning_paths"}
              desc={`${currentUser?.completed_learning_tracks_count} ${t(
                "completedLearningTracksCount"
              )} -
              ${currentUser?.completed_courses_count} ${t(
                "completedCoursesCount"
              )}`}
              slidesPerView={3}
              slidesPerViewXxl={3}
            >
              {certificates?.data &&
                certificates?.data.map((certificate) => (
                  <SwiperSlide key={certificate?.id}>
                    <button
                      className="show-image"
                      onClick={() => onShowImage(certificate.id)}
                    >
                      <div className="show-image__text">
                        <i className="far fa-eye font_22"></i>
                        <p className="font_12 font-extrabold">
                          {" "}
                          {t("showCertificate")}{" "}
                        </p>
                      </div>
                      <img
                        src={certificate.enrollment.certificate_url}
                        className="w-100"
                        alt={`${certificate.enrollment.certificate_url}`}
                      />
                    </button>
                  </SwiperSlide>
                ))}
            </SwiperSlider>
            <div
              className={`preview-image-container ${
                isshowImage ? "preview-image-container--show" : ""
              }`}
            >
              <div className="preview-image-container__content">
                <div className="d-flex justify-content-between mb-3 align-items-center">
                  <h4 className="font_18 font-bold"> {t("certificate")} </h4>
                  <button className="btn btn-main-color" onClick={onClose}>
                    <i className="fas fa-times" />
                  </button>
                </div>
                <img src={currentImage} className="w-100" alt="" />
              </div>
            </div>
          </section>

          <section className="employee-profile__all-posts">
            <div className="d-flex align-items-center justify-content-between employee-profile__all-posts__title">
              <h5 className="font_22 font-extrabold">{t("allPosts")}</h5>
              <span>
                <Icon
                  icon={<Chartline />}
                  iconName="chart-line"
                  iconSize={21}
                  height={16}
                  iconColor="#bbbabf"
                  className="me-2"
                />
                <span className="font_14 font-color-grey">
                  <strong className="font-extrabold">
                    {posts?.pagination?.count || 0}
                  </strong>{" "}
                  {t("shareOnTheForum")}
                </span>
              </span>
            </div>
            <CommunityFeed listPosts={listPosts} posts={posts} />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Profile;
