import React, { useState, useRef, useEffect } from "react";
import { FieldArray, Formik } from "formik";
import * as yup from "yup";
import InputFieldFloat from "../../../../../components/inputs/InputFieldFloat";
import InputRichTextAreaFloat from "../../../../../components/inputs/InputRichTextAreaFloat";
import ImageInput from "../../../../../components/inputs/ImageInput";
import Icon from "../../../../icons/Icon";
import { TimesStrokeIcon } from "../../../../icons/Icons";
import { useTranslation } from "react-i18next";

export default function Details({
  submit,
  setSubmit,
  setIsValidated,
  inputInititalValues,
  formData,
  setFormData,
}) {
  const [file, setFile] = useState(formData?.image);
  const [imageUrl, setimageUrl] = useState();
  const [isFileUploaded, setIsFileUploaded] = useState(true);
  const lessonsGeneratedID = useRef(0);
  const skillsGeneratedID = useRef(0);
  const formRef = useRef();

  const { t } = useTranslation("learningPaths");

  const validationSchema = yup.object({
    trackNameAr: yup.string().required(t("learningPaths:pathARNameRequired")),
    trackNameEn: yup.string().required(t("learningPaths:pathENNameRequired")),
    trackDetailsAr: yup.string().required(t("learningPaths:pathARDetailsRequired")),
    trackDetailsEn: yup.string().required(t("learningPaths:pathENDetailsRequired")),
    lessons: yup.array().of(
      yup.object().shape({
        nameAr: yup.string().required(t("learningPaths:pathARPointRequired")),
        nameEn: yup.string().required(t("learningPaths:pathENPointRequired")),
      })
    ),
    skills: yup.array().of(
      yup.object().shape({
        nameAr: yup.string().required(t("learningPaths:pathARSkillRequired")),
        nameEn: yup.string().required(t("learningPaths:pathENSkillRequired")),
      })
    ),
  });

  const validateArDescription = (arDescription) => {
    if (arDescription === " " || arDescription === undefined || arDescription === "<p><br></p>") {
      return t("learningPaths:pathARDetailsRequired");
    }
  };
  const validateEnDescription = (enDescription) => {
    if (enDescription === " " || enDescription === undefined || enDescription === "<p><br></p>") {
      return t("learningPaths:pathENDetailsRequired");
    }
  };

  let initialValues = {
    trackNameAr: "",
    trackNameEn: "",
    trackDetailsAr: "",
    trackDetailsEn: "",
    lessons: [{ id: 0, nameAr: "", nameEn: "" }],
    skills: [{ id: 0, nameAr: "", nameEn: "" }],
  };

  useEffect(() => {
    if (!inputInititalValues) {
      return;
    }
    const lessons = inputInititalValues.key_points_ar?.map((keyPoint, index) => {
      return {
        id: index,
        nameAr: keyPoint,
        nameEn: inputInititalValues.key_points_en[index],
      };
    }) || [{ id: 0, nameAr: "", nameEn: "" }];

    const skills = inputInititalValues.skills_ar?.map((skillAr, index) => {
      return {
        id: index,
        nameAr: skillAr,
        nameEn: inputInititalValues.skills_en[index],
      };
    }) || [{ id: 0, nameAr: "", nameEn: "" }];
    initialValues.trackNameAr = inputInititalValues.title_ar;
    initialValues.trackNameEn = inputInititalValues.title_en;
    initialValues.trackDetailsAr = inputInititalValues.description_ar;
    initialValues.trackDetailsEn = inputInititalValues.description_en;
    initialValues.lessons = lessons;
    initialValues.skills = skills;
    setimageUrl(inputInititalValues.image_url);
    lessonsGeneratedID.current = lessons.length;
    skillsGeneratedID.current = skills.length;
  }, [inputInititalValues]);

  useEffect(() => {
    if (!formData) {
      return;
    }
    const lessons = formData.key_points_ar?.map((keyPoint, index) => {
      return {
        id: index,
        nameAr: keyPoint,
        nameEn: formData.key_points_en[index],
      };
    }) || [{ id: 0, nameAr: "", nameEn: "" }];

    const skills = formData.skills_ar?.map((skillAr, index) => {
      return { id: index, nameAr: skillAr, nameEn: formData.skills_en[index] };
    }) || [{ id: 0, nameAr: "", nameEn: "" }];

    initialValues.trackNameAr = formData.title_ar;
    initialValues.trackNameEn = formData.title_en;
    initialValues.trackDetailsAr = formData.description_ar;
    initialValues.trackDetailsEn = formData.description_en;
    initialValues.lessons = lessons;
    initialValues.skills = skills;
    if (formData.image) {
      setimageUrl(URL.createObjectURL(formData.image));
    }
    lessonsGeneratedID.current = lessons.length;
    skillsGeneratedID.current = skills.length;
  }, [formData]);

  useEffect(() => {
    if (submit && formRef.current) {
      formRef.current.handleSubmit();
      setSubmit(null);
    }
  }, [submit, setSubmit]);

  useEffect(() => {
    if (!formData) {
      return;
    }
    if (!file) {
      setFormData({ ...formData, image: null });
      setimageUrl(null);
    }
    // eslint-disable-next-line
  }, [file, setFormData]);

  function populateFormData(values, newFile = null) {
    let dataForm = {};
    const keyPointsEn = values?.lessons?.map((lesson) => lesson.nameEn.trim());
    const keyPointsAr = values?.lessons?.map((lesson) => lesson.nameAr.trim());
    const skillsEn = values?.skills?.map((skill) => skill.nameEn.trim());
    const skillsAr = values?.skills?.map((skill) => skill.nameAr.trim());
    dataForm.title_en = values?.trackNameEn.trim();
    dataForm.title_ar = values?.trackNameAr.trim();
    dataForm.description_en = values?.trackDetailsEn?.trim();
    dataForm.description_ar = values?.trackDetailsAr?.trim();
    dataForm.key_points_en = keyPointsEn;
    dataForm.key_points_ar = keyPointsAr;
    dataForm.skills_en = skillsEn;
    dataForm.skills_ar = skillsAr;
    if (newFile) {
      dataForm.image = newFile;
      setimageUrl(URL.createObjectURL(newFile));
    } else if (file) {
      dataForm.image = file;
      setimageUrl(URL.createObjectURL(file));
    }
    setFormData(dataForm);
  }

  function addImage(e, values) {
    const imageFile = e?.target?.files[0];
    setFile(imageFile);
    setIsFileUploaded(true);
    populateFormData(values, imageFile);
    setimageUrl(URL.createObjectURL(imageFile));
  }
  function removeImage(values) {
    setFile(null);
    setIsFileUploaded(false);
    populateFormData(values);
    setimageUrl(null);
  }

  function isImagePresent(e) {
    if (!imageUrl) {
      setIsFileUploaded(false);
      return false;
    }
    return true;
  }

  function removedSpacesEvent(e) {
    e.target.value = e.target.value.replace(/\s+/g, " ");
    return e;
  }

  return (
    <div
      className="mt-2 container-fluid tab-pane fade show active edit-learning-path "
      id="nav-home"
      role="tabpanel"
      aria-labelledby="nav-home-tab"
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};
          setIsValidated(false);
          if (!isImagePresent()) {
            errors.file = "file is required";
          }
          const checkArDescription = validateArDescription(values.trackDetailsEn);
          if (checkArDescription) {
            errors.trackDetailsAr = checkArDescription;
          }
          const checkEnDescription = validateEnDescription(values.trackDetailsEn);
          if (checkEnDescription) {
            errors.trackDetailsEn = checkEnDescription;
          }
          populateFormData(values);
          return errors;
        }}
        onSubmit={() => {
          try {
            setIsValidated(true);
          } catch (e) {}
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
          <form className="p-4" onSubmit={handleSubmit}>
            <div>
              <h1 className="mb-4 font_20 font-extrabold"> {t("learningPaths:pathInfo")} </h1>
              <div className="row">
                <div className="col-12 col-md-6">
                  <InputFieldFloat
                    label={t("learningPaths:trackNameAr")}
                    type="text"
                    name="trackNameAr"
                    value={values.trackNameAr}
                    onChange={(e) => handleChange(removedSpacesEvent(e))}
                    error={errors.trackNameAr}
                    touched={touched.trackNameAr}
                  />

                  <InputRichTextAreaFloat
                    label={t("learningPaths:trackDetailsAr")}
                    type="text"
                    id="trackDetailsAr"
                    name="trackDetailsAr"
                    rows="10"
                    value={values.trackDetailsAr}
                    setFieldValue={setFieldValue}
                    error={errors.trackDetailsAr}
                    touched={touched.trackDetailsAr}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputFieldFloat
                    label={t("learningPaths:trackNameEn")}
                    type="text"
                    name="trackNameEn"
                    value={values.trackNameEn}
                    onChange={(e) => handleChange(removedSpacesEvent(e))}
                    error={errors.trackNameEn}
                    touched={touched.trackNameEn}
                  />

                  <InputRichTextAreaFloat
                    label={t("learningPaths:trackDetailsEn")}
                    type="text"
                    id="trackDetailsEn"
                    name="trackDetailsEn"
                    rows="10"
                    value={values.trackDetailsEn}
                    setFieldValue={setFieldValue}
                    error={errors.trackDetailsEn}
                    touched={touched.trackDetailsEn}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <h1 className="font_20 font-extrabold">{t("learningPaths:trackCoverPhoto")}</h1>
              <p className="font-16 font-color-grey"> {t("learningPaths:trackCoverPhotoHint")}</p>

              <div className="row">
                <div className="col">
                  <ImageInput
                    className="learning-path-details-card-image"
                    id="learning-track-avatar"
                    name="learningTrackAvatar"
                    accept=".png, .jpg, .jpeg"
                    imageUrl={imageUrl}
                    onChange={(e) => addImage(e, values)}
                    onDelete={() => removeImage(values)}
                    onBlur={isImagePresent}
                    error={t("learningPaths:trackCoverPhotoError")}
                    touched={!isFileUploaded}
                    file={file}
                  />
                </div>
                <div className="col"></div>
              </div>
            </div>

            <div className="mt-4">
              <h1 className="font_20 font-extrabold">
                {t("learningPaths:trackLearningObjectives")}
              </h1>
              <p className="font-16 font-color-grey">
                {" "}
                {t("learningPaths:trackLearningObjectivesHint")}{" "}
              </p>

              <FieldArray name="lessons">
                {({ push, remove }) => (
                  <div>
                    {values.lessons.map((lesson, index) => {
                      const lessonErrors = (errors.lessons?.length && errors.lessons[index]) || {};
                      const lessonTouched =
                        (touched.lessons?.length && touched.lessons[index]) || {};
                      return (
                        <div
                          className="d-flex align-items-center align-items-md-start gap-2 mb-3 mb-md-4"
                          key={`lesson-${lesson.id}`}
                        >
                          <div className="flex-grow-1 d-md-flex gap-3">
                            <div className="flex-fill mb-3 mb-md-0">
                              <InputFieldFloat
                                label={t("learningPaths:trackLessonsAr", {
                                  index: index + 1,
                                })}
                                type="text"
                                name={`lessons.${index}.nameAr`}
                                value={lesson.nameAr}
                                onChange={(e) => handleChange(removedSpacesEvent(e))}
                                error={lessonErrors.nameAr}
                                touched={lessonTouched.nameAr}
                              />
                            </div>
                            <div className="flex-fill mb-3 mb-md-0">
                              <InputFieldFloat
                                label={t("learningPaths:trackLessonsEn", {
                                  index: index + 1,
                                })}
                                type="text"
                                name={`lessons.${index}.nameEn`}
                                value={lesson.nameEn}
                                onChange={(e) => handleChange(removedSpacesEvent(e))}
                                error={lessonErrors.nameEn}
                                touched={lessonTouched.nameEn}
                              />
                            </div>
                          </div>

                          {values.lessons.length > 1 && (
                            <button
                              className="border border-2 border-radius-12 border-color flex align-items-center justify-content-between  remove-btn"
                              id="deleteDepartmentButton"
                              onClick={() => remove(index)}
                            >
                              <Icon
                                icon={<TimesStrokeIcon />}
                                iconName="times"
                                iconSize={20}
                                height={20}
                                viewBox="0 0 20 20"
                                iconColor="#bbbabf"
                                hasStroke
                              />
                            </button>
                          )}
                        </div>
                      );
                    })}
                    <div className="row">
                      <button
                        className="col gray-btn"
                        type="button"
                        onClick={() => {
                          push({ id: Math.random(), nameAr: "", nameEn: "" });
                        }}
                      >
                        <img className="ms-3" src="/images/icons/plusW.svg" alt="add point" />
                        {t("learningPaths:addTrackLesson")}
                      </button>
                      <div className="col" />
                    </div>
                  </div>
                )}
              </FieldArray>
            </div>

            <div className="mt-4">
              <h1 className="font_20 font-extrabold">{t("learningPaths:learnedSkills")} </h1>
              <p className="font-16 font-color-grey"> {t("learningPaths:learnedSkillHint")}</p>

              <FieldArray name="skills">
                {({ push, remove }) => (
                  <div>
                    {values.skills.map((skill, index) => {
                      const skillErrors = (errors.skills?.length && errors.skills[index]) || {};
                      const skillTouched = (touched.skills?.length && touched.skills[index]) || {};
                      return (
                        <div
                          key={`skill-${index}`}
                          className="d-flex align-items-center align-items-md-start gap-2 mb-3 mb-md-4"
                        >
                          <div className="flex-grow-1 d-md-flex gap-3">
                            <div className="flex-fill mb-3 mb-md-0">
                              <InputFieldFloat
                                label={t("learningPaths:trackSkillsAr", {
                                  index: index + 1,
                                })}
                                type="text"
                                name={`skills.${index}.nameAr`}
                                value={skill.nameAr}
                                onChange={(e) => handleChange(removedSpacesEvent(e))}
                                error={skillErrors.nameAr}
                                touched={skillTouched.nameAr}
                              />
                            </div>
                            <div className="flex-fill mb-3 mb-md-0">
                              <InputFieldFloat
                                label={t("learningPaths:trackSkillsEn", {
                                  index: index + 1,
                                })}
                                type="text"
                                name={`skills.${index}.nameEn`}
                                value={skill.nameEn}
                                onChange={(e) => handleChange(removedSpacesEvent(e))}
                                error={skillErrors.nameEn}
                                touched={skillTouched.nameEn}
                              />
                            </div>
                          </div>

                          {values.skills.length > 1 && (
                            <button
                              className="border border-2 border-radius-12 border-color flex align-items-center justify-content-between remove-btn"
                              id="deleteDepartmentButton"
                              onClick={() => remove(index)}
                            >
                              <Icon
                                icon={<TimesStrokeIcon />}
                                iconName="times"
                                iconSize={20}
                                height={20}
                                viewBox="0 0 20 20"
                                iconColor="#bbbabf"
                                hasStroke
                              />
                            </button>
                          )}
                        </div>
                      );
                    })}
                    <div className="row">
                      <button
                        className="col gray-btn"
                        type="button"
                        onClick={() => {
                          push({ id: Math.random(), nameAr: "", nameEn: "" });
                        }}
                      >
                        <img className="ms-3" src="/images/icons/plusW.svg" alt="add skill" />
                        {t("learningPaths:addTrackSkill")}
                      </button>
                      <div className="col" />
                    </div>
                  </div>
                )}
              </FieldArray>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
