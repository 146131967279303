import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomeAdminsCard from "../../components/cards/HomeAdminsCard";
import HomeStatsCard from "../../components/cards/HomeStatsCard";
import HonorBoardCard from "../../components/cards/HonorBoardCard";
import TotalCoursesCard from "../../components/cards/TotalCoursesCard";
import ViewsHoursCard from "../../components/cards/ViewsHoursCard";
import { useGetAdminDashboardStatsQuery } from "../../services/organization/adminStatistics";
import { useGetAdminLeaderboardQuery } from "../../services/organization/leaderboard";
import InviteEmployeeModal from "../../components/Modals/InviteEmployeeModal";
import LoadingIndicator from "../../components/LoadingIndicator";

const Home = () => {
  const { data: dashboardStats, isLoading: isDashboardStatsLoading } =
    useGetAdminDashboardStatsQuery();
  const { data: leaderboardUsers, isAdminLeaderboardLoading } =
    useGetAdminLeaderboardQuery({
      page: 1,
      perPage: 3,
    });

  let navigate = useNavigate();
  const { t } = useTranslation(["common", "learningPaths"]);

  if (isDashboardStatsLoading || isAdminLeaderboardLoading)
    return <LoadingIndicator />;

  return (
    <div className="employee home">
      <InviteEmployeeModal />
      <div></div>
      <div className="d-flex flex-wrap align-items-center">
        <div className="left-side">
          <h1 className="title-page font_24 mb-0">{t("homepage")}</h1>
        </div>

        <div className="ms-auto right-side">
          <ul className="list-filter d-flex flex-wrap">
            <li className="me-2">
              <button
                type="button"
                className="btn btn-main-color-border font-15"
                onClick={() => navigate("/admin/learning_paths/new")}
                // data-bs-toggle="modal"
                // data-bs-target="#staticBackdrop"
              >
                <span className="fas fa-plus fa-fw me-2"></span>
                {t("learningPaths:createNewLearningTrack")}
              </button>
            </li>
            <li>
              <button
                type="button"
                className="btn btn-main-color font-15"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                <span className="fas fa-user-plus fa-fw me-2"></span>
                {t("inviteNewEmployee")}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-5">
          <div className="col-lg-6">
            <ul className="row">
              <li className="col-sm-6">
                <HomeStatsCard
                  bgColor="rgba(157, 78, 221, 0.06)"
                  img="graduation-cap-solid.svg"
                  title={t("learningTracksCount")}
                  num={dashboardStats?.data?.learning_tracks_count}
                  singleNumber
                />
              </li>
              <li className="col-sm-6">
                <HomeStatsCard
                  bgColor="rgba(241, 115, 0, 0.06)"
                  img="courses.svg"
                  title={t("coursesCount")}
                  num={dashboardStats?.data?.total_courses}
                  singleNumber
                />
              </li>
              <li className="col-sm-6">
                <HomeStatsCard
                  bgColor="rgba(136, 136, 136, 0.06)"
                  img="invitationsNumber.svg"
                  title={t("sentInvitationsCount")}
                  num={dashboardStats?.data?.invites_count}
                  singleNumber
                />
              </li>
              <li className="col-sm-6">
                <HomeStatsCard
                  bgColor="rgba(1, 194, 153, 0.06)"
                  img="acceptedUsers.svg"
                  title={t("acceptedInvitationsCount")}
                  num={dashboardStats?.data?.registers_count}
                  singleNumber
                />
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <ul className="row mb-4">
              <li className="col-sm-12">
                <TotalCoursesCard
                  completedCourses={
                    dashboardStats?.data?.completed_course_enrollments
                  }
                  totalCourses={dashboardStats?.data?.total_course_enrollments}
                />
              </li>
              <li className="col-sm-12">
                <HomeAdminsCard
                  totalUsersNumber={dashboardStats?.data?.total_number_of_users}
                />
              </li>
            </ul>
          </div>
          <div className="col-lg-6 mb-4">
            <ViewsHoursCard />
          </div>
          <div className="col-lg-6 mb-4">
            <HonorBoardCard leaderboardUsers={leaderboardUsers?.data} isAdmin />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
