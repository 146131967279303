import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useAdminLogoutMutation } from "../services/organization/auth";
import { useLogoutMutation } from "../services/users/auth";
import { useListNotificationsQuery } from "../services/users/notifications";
import { setCurrentUser } from "../app/store/reducers/authSlice";
import { displayDate } from "../utils/arabicDatesUtilities";
// Components
import Dropdown from "./DropDown";
import Avatar from "./inputs/Avatar";
import Logout from "./icons/logout";
import SwitchLang from "./SwitchLang/SwitchLang";
import { LockIcon } from "./icons/Icons";
import Icon from "./icons/Icon";
import useMyPoints from "../app/hooks/useMyPoints";
import { api } from "../services";
import { MEDIA_BREAKPOINT_XXL } from "../constants";
import useViewPort from "../app/hooks/useViewPort";
import { useTranslation } from "react-i18next";
import { useAdminUpdateOrganizationMutation } from "../services/organization/settings";

const Navbar = ({ items, oraganizationLogo, isAdmin }) => {
  const { width } = useViewPort();
  const [isUnreadNotifications, setIsUnreadNotifications] = useState(false);
  const currentUser = useSelector((state) => state?.auth?.currentUser);
  const { t } = useTranslation("common");
  const [adminLogout] = useAdminLogoutMutation();
  const [logout] = useLogoutMutation();
  const { data: notifications } = useListNotificationsQuery({
    page: 1,
    perPage: 3,
  });

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateOrganization] = useAdminUpdateOrganizationMutation();

  useEffect(() => {
    if (notifications?.data?.notifications?.length)
      notifications?.data?.notifications?.map((notification) => {
        if (!notification?.read) setIsUnreadNotifications(true);
      });
  }, [notifications]);

  const handleLogout = async () => {
    const result = isAdmin ? await adminLogout() : await logout();
    if (!result.error || result?.error?.status == 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("is_admin");
      dispatch(setCurrentUser(null));
      isAdmin ? navigate("/login/admin") : navigate("/login");
      setTimeout(() => {
        dispatch(api.util.resetApiState());
      }, 0);
    } else {
      toast.error(result?.error?.data?.message);
    }
  };
  const userPoints = useMyPoints(0);

  const handleNotificationNavigation = (key, navigationableId) => {
    switch (key) {
      case "post_comment":
      case "post_like":
        navigate(`/employee/community/${navigationableId}`);
        return;

      case "assign_request":
        navigate(`/employee/learning_paths`);
    }
  };

  const renderPoints = () => userPoints || currentUser?.points;

  return (
    <nav className="navbar navbar-main navbar-expand-lg">
      <div className="container-fluid">
        {/* logos */}
        <ul
          className="logos"
          onClick={() => (isAdmin ? navigate(`/admin`) : navigate(`/employee`))}
        >
          {oraganizationLogo && (
            <li>
              <Link className="navbar-brand" to="#">
                <img className="main-logo " alt="" src={oraganizationLogo} />
              </Link>
            </li>
          )}

          <li>
            <Link className="navbar-brand" to="#">
              <img
                className="main-logo"
                alt=""
                src="/images/Tadarab-logo.png"
              />
            </Link>
          </li>
        </ul>
        {/* main-menu */}
        <div
          className="collapse navbar-collapse main-menu"
          id="navbarNavDropdown"
        >
          <ul className={isAdmin ? "navbar-nav mx-5" : "navbar-nav mx-auto"}>
            {items.map((item, key) => (
              <li key={key} className="nav-item">
                <Link
                  className={`nav-link ${item.active ? "active" : ""}`}
                  aria-current="page"
                  to={item.link}
                  style={isAdmin ? { color: "black" } : {}}
                >
                  {item.icon && <span className={`me-2`}>{item.icon}</span>}
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="d-flex align-items-center gap-3 justify-content-between">
          {!isAdmin && width >= MEDIA_BREAKPOINT_XXL && (
            <div className="label label-yellow rounded-pill points">
              <i className="fas fa-star font-color-gold font_20 me-2" />
              <span className="font_20 font-extrabold">{renderPoints()}</span>
            </div>
          )}
          <SwitchLang />
          {/* notification */}
          {!isAdmin && (
            <Dropdown
              dropdownClassName="notification"
              toggleBtnClassName="p-0"
              toggleElement={
                <>
                  {isUnreadNotifications && (
                    <span className="notification__not-read"></span>
                  )}
                  <i className="far fa-bell font_24"></i>
                </>
              }
            >
              <li className="dropdown-menu-title d-flex align-items-center justify-content-between">
                <h5 className="mb-0 font-extrabold font_20">
                  {" "}
                  {t("notfications")}{" "}
                </h5>
                <Link
                  to={
                    isAdmin ? "/admin/notification" : "/employee/notification"
                  }
                  className="font-color-red font_14 font-bold"
                >
                  {t("showAll")}
                  {/* see all */}
                  <i className="fas fa-arrow-right fa-fw ms-2 align-middle"></i>
                </Link>
              </li>
              {notifications?.data?.notifications?.map((notification) => (
                <li
                  key={notification.id}
                  className={`notification__item d-flex ${
                    !notification.read ? "notification__item--new" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleNotificationNavigation(
                      notification.key,
                      notification.navigationable_id
                    )
                  }
                >
                  <div className="notification__item__img">
                    {notification.avatar_url ? (
                      <img
                        src={notification.avatar_url}
                        className="img"
                        alt={`img`}
                        style={{ width: "40px", height: "40px" }}
                      ></img>
                    ) : (
                      <i className="far fa-bell notification__item__icon "></i>
                    )}
                  </div>
                  <div className="notification__item__info">
                    <p className="mb-0 font_14">{notification.title}</p>
                    <small className="font_14 font-color-grey">
                      {displayDate(notification.created_at)}
                    </small>
                  </div>
                </li>
              ))}
            </Dropdown>
          )}

          {/* setting */}
          <Dropdown
            dropdownClassName="setting-menu"
            toggleElement={
              <Avatar
                avatarUrl={currentUser?.avatar_url}
                width="40"
                height="40"
              />
            }
            hasToggleArrowDown
          >
            {!isAdmin ? (
              <li className="user-info setting-menu__item">
                <Link to="/employee/profile">
                  <>
                    <h5 className="mb-0 font-extrabold">{currentUser?.name}</h5>
                    <p className="mb-0 font-color-grey">{currentUser?.email}</p>
                    <p className="mb-0 font_14 font-color-grey">
                      <i className="fas fa-star font-color-gold font_20" />
                      <span className="font-extrabold font_20">
                        {renderPoints()}
                      </span>
                      {t("points")}
                    </p>
                  </>
                </Link>
              </li>
            ) : null}

            {!isAdmin ? (
              <>
                <Link
                  className="setting-menu__item__link"
                  to="/employee/edit-profile"
                >
                  <li className="setting-menu__item">
                    <i className="far fa-user"></i>
                    {t("navbar.editProfile")}
                  </li>
                </Link>
                <Link
                  className="setting-menu__item__link"
                  to="/employee/change-password"
                >
                  <li className="setting-menu__item">
                    <Icon
                      icon={<LockIcon />}
                      iconSize={18}
                      height={20}
                      iconName="lock"
                      iconColor="#bbbabf"
                      hasStroke
                      viewBox="0 0 20 23"
                    />
                    {t("navbar.changePassword")}
                  </li>
                </Link>
              </>
            ) : null}
            <button className="setting-menu__item__link" onClick={handleLogout}>
              <li className="setting-menu__item">
                <Logout />
                {t("navbar.Logout")}
              </li>
            </button>
          </Dropdown>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
