import React from "react";
import { useParams } from "react-router-dom";
import { useAdminGetUserQuizStatsQuery } from "../../services/organization/adminUsers";
import ExamProgressCard from "../../components/cards/Exam/ExamProgressCard";
import EmployeeQuizAnswerCard from "../../components/cards/EmployeeQuizAnswerCard";

export default function AdminQuestionsAnswers() {
  const { employeeId, lessonLogId } = useParams();

  const { data: quizStats } = useAdminGetUserQuizStatsQuery(employeeId);

  const questions = [
    {
      id: 1,
      answers: ["التسويق عن طريق البريد", "التسويق عن طريق الشركات التجارية", "التسويق عن طريق أعلانات جوجل", "جميع ما سبق"],
      archived: false,
      correct_answer: "التسويق عن طريق أعلانات جوجل",
      question: "ما هي أفضل طرق التسويق الفعالة للشركات الصغيرة؟",
      employee_answer: ""
    },
    {
      id: 2,
      answers: ["المبيعات عن طريق البريد", "المبيعات عن طريق الشركات التجارية", "المبيعات عن طريق أعلانات جوجل", "جميع ما سبق"],
      archived: false,
      correct_answer: "المبيعات عن طريق أعلانات جوجل",
      question: "ما هي أفضل طرق المبيعات الفعالة للشركات الصغيرة؟",
      employee_answer: "المبيعات عن طريق أعلانات جوجل"
    },
    {
      id: 3,
      answers: ["الخدمات عن طريق البريد", "الخدمات عن طريق الشركات التجارية", "الخدمات عن طريق أعلانات جوجل", "جميع ما سبق"],
      archived: false,
      correct_answer: "الخدمات عن طريق أعلانات جوجل",
      question: "ما هي أفضل طرق الخدمات الفعالة للشركات الصغيرة؟",
      employee_answer: "جميع ما سبق"
    }
  ]

  return (
    <div className="container-fluid">
      <div className="row justify-content-center p-4 pb-0">
        <div className="col-lg-9 col-md-12">
          <ExamProgressCard
            exam={quizStats?.data?.find(
              (exam) => exam?.lesson_log?.id == lessonLogId
            )}
            className="mb-4"
          />

          <h1 className="font_20 font-extrabold mb-0">إجابات الإختبار</h1>
          <p className="font_16 font-color-grey  mb-4">
            هذه الإجابات الخاصة بحل الموظف
          </p>

          <ul>
            {
              questions.map((quez, i) => (
                <li key={quez.id} className="mb-3">
                  <EmployeeQuizAnswerCard quiz={quez} numberOfQuestion={i + 1} correctAnswer={quez.correct_answer} employeeAnswer={quez.employee_answer} />
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
}
