import React from "react";
import Avatar from "../inputs/Avatar";
import ProgressBar from "../../components/ProgressBar";
import { calculateRemaingDays } from "../../utils/serviceUtilities.js";
import { useTranslation } from "react-i18next";

export default function AdminEmployeeCard({
  employeeEnrollment,
  learningTrack,
}) {
  const enrollmentDate = new Date(employeeEnrollment.enrollment_date);
  const completionDate = new Date(employeeEnrollment.complete_by);
  const remainingDaysCount = calculateRemaingDays(completionDate);
  const { t } = useTranslation("common");

  function dateToBeDisplayed(date) {
    return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`;
  }

  return (
    <div className="admin-employee-card">
      <div className="row">
        <div className="col-lg-4  col-sm-12">
          <div className="d-flex flex-wrap align-center">
            <Avatar
              width="40"
              height="40"
              avatarUrl={employeeEnrollment.avatar_url}
            />
            <div className="ms-2">
              <p className="font_14 font-extrabold  mb-0">
                {employeeEnrollment.name}
              </p>
              <p className="font_14 font-color-grey mb-0">
                {employeeEnrollment.email}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12">
          <div>
            <p className="m-0 me-4 font-12 font-bold font-color-grey">
              {employeeEnrollment.completed_courses_count}{" "}
              {t("completedCoursesCount")}
              <p className="d-inline font-regular">
                {" "}
                {t("learningPaths:totalCoursesCount", {
                  number: learningTrack.courses_count,
                })}
              </p>
            </p>
            <ProgressBar percentageText={`${employeeEnrollment.progress}%`} />
          </div>
        </div>
        <div className="col-lg-3  col-sm-12">
          <div>
            <p className="font_14 font-bold font-color-grey mb-0">
              {t("learningPaths:subscribedAt")}{" "}
              {dateToBeDisplayed(enrollmentDate)}
            </p>
            <p className="font_12 font-color-grey mb-0">
              {t("learningPaths:learningPathsDeadline", {
                date: dateToBeDisplayed(completionDate),
              })}
              &nbsp;
              {remainingDaysCount > 0
                ? t("learningPaths:remainingTime", { days: remainingDaysCount })
                : t("learningPaths:deadlinePassed", {
                    lateDays: Math.abs(remainingDaysCount),
                  })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
