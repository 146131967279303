import React from "react";
import CourseCoverCard from "../../../../../components/cards/CourseCoverCard";
import { useTranslation } from "react-i18next";

export default function Details({ learningTrack }) {
  const { t } = useTranslation(["learningPaths"]);
  return (
    <div id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
      <div className="container-fluid">
        <div className="row p-4">
          <CourseCoverCard learningTrack={learningTrack} />
          <h1 className="font_20 font-bold mt-4 mb-4">
            {t("learningPaths:trackLearningObjectives")}{" "}
          </h1>
          {learningTrack.key_points?.map((keyPoint, index) => {
            return (
              <p key={`keyPoint-${index}`} className="font_16 font-color-grey">
                <span className="fas fa-check fa-fw me-2 font-color-green mb-1"></span>
                {keyPoint}
              </p>
            );
          })}

          <hr className="mt-2 mb-2" />
          <h1 className="font_20 font-bold mt-4 mb-4">
            {" "}
            {t("learningPaths:pathDetails")}{" "}
          </h1>
          <p
            className="font_16 font-color-grey"
            style={{
              maxWidth: "634px",
              direction: "ltr",
              wordBreak: "break-all",
            }}
            dangerouslySetInnerHTML={{ __html: learningTrack.description }}
          ></p>
          <hr className="mt-2 mb-2" />
          <h1 className="font_20 font-bold mt-4 mb-4">
            {" "}
            {t("learningPaths:learnedSkills")}
          </h1>
          <ul className="d-flex align-center flex-wrap">
            {learningTrack.skills?.map((skill, index) => {
              return (
                <li key={`skill-${index}`} className="me-2 mb-2">
                  <span className="label label-black">
                    <span className="font-color-grey">{skill}</span>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
