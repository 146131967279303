import { ChromePicker } from "react-color";
export default function ColorPicker({
  blockPickerColor,
  setBlockPickerColor,
  visible,
}) {
  return (
    <div
      className="bubble"
      style={{ visibility: visible ? "visible" : "hidden" }}
    >
      <ChromePicker
        disableAlpha
        color={blockPickerColor}
        onChange={(color) => {
          setBlockPickerColor(color.hex);
        }}
      />
    </div>
  );
}
