const SocialMedia = ({ url }) => {
  const socialsMedia = [
    {
      id: 1,
      name: "instagram",
      href: url?.instagram,
    },
    {
      id: 2,
      name: "facebook",
      href: url?.facebook,
    },
    {
      id: 3,
      name: "snapchat",
      href: url?.snapchat,
    },
    {
      id: 4,
      name: "linkedin",
      href: url?.linkedin,
    },
    {
      id: 5,
      name: "twitter",
      href: url?.twitter,
    },
  ];

  function isValidHttpUrl(string) {
    try {
      const newUrl = new URL(string);
      return newUrl.protocol === "http:" || newUrl.protocol === "https:";
    } catch (err) {
      return false;
    }
  }

  return (
    <ul className="d-flex social-media-h-menu">
      {socialsMedia.map((socialMedia) =>
        isValidHttpUrl(socialMedia.href) ? (
          <li key={socialMedia.id} className={`icon icon-${socialMedia.name}`}>
            <a href={socialMedia.href} target="_blank" rel="noreferrer">
              <i className={`fab fa-${socialMedia.name} font_20`}></i>
            </a>
          </li>
        ) : null
      )}
    </ul>
  );
};

export default SocialMedia;
