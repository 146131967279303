import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import PostCommunityCard from "../../components/cards/PostCommunityCard";
import {
  useGetPostQuery,
  useCreateCommentMutation,
  useLikePostMutation,
  useUnlikePostMutation,
  useDeletePostMutation,
} from "../../services/users/community";
import NotFoundPage from "../404";

const SinglePost = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const { data: postData, refetch, isLoading } = useGetPostQuery(id);
  const [addComment] = useCreateCommentMutation();
  const [like] = useLikePostMutation();
  const [unlike] = useUnlikePostMutation();
  const [deletePost] = useDeletePostMutation();

  const handleToggleLike = async ({ postId, isLikedByMe }) => {
    let result;
    if (isLikedByMe) {
      result = await unlike(postId);
    } else {
      result = await like(postId);
    }
    if (!result?.error) await refetch(postId);
  };

  const handleSubmittingComment = async ({ postId, comment, setComment }) => {
    let dataForm = new FormData();
    dataForm.append("body", comment);
    if (comment) {
      const result = await addComment({ postId, body: dataForm });
      if (!result?.error) {
        await refetch(postId);
        setComment("");
      }
    }
  };

  const handleDeletingPost = async () => {
    const result = await deletePost(id);
    if (!result.error) navigate("/employee/community");
  };

  if (isLoading) return null;

  if (!postData) {
    return <NotFoundPage />;
  }

  return (
    <div className="container-fluid mt-4">
      <div className="row justify-content-center p-4 community-page pt-0">
        <div className="col-lg-6 col-md-12">
          <ul className="">
            <li className="mb-3">
              <PostCommunityCard
                post={postData?.data}
                handleToggleLike={handleToggleLike}
                handleSubmittingComment={handleSubmittingComment}
                handleDeletingPost={handleDeletingPost}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;
