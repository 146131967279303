import { api } from "../index";

export const departments = api.injectEndpoints({
  endpoints: (builder) => ({
    listDepartments: builder.query({
      query: () => `/api/v1/departments`,
    }),
  }),
});

export const { useListDepartmentsQuery } = departments;
