import { useState } from "react";
import { useNavigate } from "react-router-dom";
import StatsCard from "../cards/StatsCard";
import State from "../State";
import Modal from "./Modal";

const PassedTestModal = ({
  id,
  isOpened,
  correctAnswersCount,
  wrongAnswersCount,
  onClick,
}) => {
  const [showModal, setShowModal] = useState(isOpened);

  const navigate = useNavigate();
  const gotoHome = () => {
    setShowModal(!isOpened);
    navigate("/");
  };

  let percentage = parseInt(
    (correctAnswersCount / (correctAnswersCount + wrongAnswersCount)) * 100
  );

  return (
    <Modal id={id} maxWidth="600" isOpened={showModal} hasCloseBtn={false}>
      <State
        titleFontSize={24}
        descFontSize={14}
        className="passed-test"
        color="#01c299"
        id="success-test"
        hasIconStroke
        type="success"
        title="مبروك, لقد اجتزت الاختبار بنجاح"
        desc="هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي  التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة"
      >
        <div className="row state__details">
          <div className="col-sm-4 mb-3 mb-sm-0">
            <StatsCard
              title="درجتك"
              num={`${percentage}%`}
              state="default"
              titleFontColor="font-color-grey"
            />
          </div>
          <div className="col-sm-4 mb-3 mb-sm-0">
            <StatsCard
              title="الإجابات الصحيحة"
              num={correctAnswersCount}
              state="success"
            />
          </div>
          <div className="col-sm-4 mb-3 mb-sm-0">
            <StatsCard
              title="الإجابات الخاطئة"
              num={wrongAnswersCount}
              state="fail"
            />
          </div>
        </div>
        <div className="d-grid gap-2 state__actions">
          <button
            className="btn btn-main-color"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={onClick}
          >
            اكمل مشاهدة الدورة
          </button>

          <button
            className="btn btn-grey-color"
            onClick={gotoHome}
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            العودة إلى الصفحة الرئيسية
          </button>
        </div>
      </State>
    </Modal>
  );
};

export default PassedTestModal;
