import PropTypes from "prop-types";
import { useState } from "react";
import Radio from "./inputs/Radio";
import InputField from "./inputs/InputField";

const AnswerQuestion = ({
  num
}) => {
  const [checked, setChecked] = useState(false);

  console.log(checked);
  return (
    <>
      <div className={`answer-question d-flex flex-column flex-md-row align-items-md-center gap-3 gap-md-0 `}>
        <div className="d-flex flex-grow-1">
          <div className={`answer-question__number btn-icon-border font-color-grey`}> {num} </div>

          <div className={`answer-question__answer flex-grow-1`}>
            <InputField />
          </div>
        </div>

        <div className={`answer-question__actions d-flex align-items-center`}>
          <button className="btn btn-icon-border">
            <span className="far fa-trash-alt fa-fw font-color-grey"></span>
          </button>

          <Radio
            id="correct-answer"
            checked={checked}
            setChecked={setChecked}
            className={`mb-0 radio-check-box--green ${checked ? 'radio-check-box--green--checked' : ''}`}>
            <p className="mb-0">إجابة صحيحة</p>
          </Radio>
        </div>
      </div>
    </>
  )
}

export default AnswerQuestion;

AnswerQuestion.prototype = {
  num: PropTypes.number.isRequired
}