import React, { useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "./Avatar";
import { useTranslation } from "react-i18next";

const InputComment = ({ postId, handleSubmittingComment }) => {
  const [comment, setComment] = useState("");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation("communityFeed");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmittingComment({ postId, comment, setComment });
    }
  };

  return (
    <div className="input-comment">
      <Avatar height="40" width="40" avatarUrl={currentUser?.avatar_url} />
      <input
        type="text"
        placeholder={t("communityFeed:addComment")}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <button
        className="ms-auto"
        type="submit"
        onClick={() => handleSubmittingComment({ postId, comment, setComment })}
      >
        <img className="icon " alt="" src="/images/icons/send.svg" />
      </button>
    </div>
  );
};

export default InputComment;
