import React, { useState } from "react";
import { toast } from "react-toastify";
import EditLearningTrackCoursesModal from "../../../../../components/Modals/EditLearningTrackCoursesModal";
import AdminLearningPathdetailsCard from "../../../../../components/cards/AdminLearningPathdetailsCard";
import {
  useAddCourseToLearningTrackMutation,
  useRemoveCourseFromLearningTrackMutation,
} from "../../../../../services/organization/adminLearningTrack";
import EmptyState from "../../../../EmptyState";
import { useTranslation } from "react-i18next";
export default function Courses({ learningTrack }) {
  const [courses, setCourses] = useState(learningTrack.courses || []);
  const [deletedCoursesIds, setDeltedCoursesIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation(["common", "learningPaths"]);

  const [addCourseToLearningTrack] = useAddCourseToLearningTrackMutation();
  const [removeCourseFromLearningTrack] =
    useRemoveCourseFromLearningTrackMutation();
  let learningTrackCourseIdsSet = new Set(
    learningTrack?.courses?.map((course) => course.id)
  );

  function handleSave() {
    try {
      const courseIds = courses
        .map((course) => course.id)
        .filter((courseId) => !learningTrackCourseIdsSet.has(courseId));

      if (courseIds.length === 0 && deletedCoursesIds.length === 0) {
        toast.error(t("nochanges"));
        return;
      }

      if (courseIds.length) {
        addCourseToLearningTrack({
          learningTrackId: learningTrack.id,
          body: { course_ids: courseIds },
        });
      }
      if (deletedCoursesIds.length) {
        removeCourseFromLearningTrack({
          learningTrackId: learningTrack.id,
          body: { course_ids: deletedCoursesIds },
        });
      }

      toast.success(t("addedSuccessully"));
      setShowModal(false);
      setDeltedCoursesIds([]);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
      <div className="container-fluid">
        <div className="row p-4">
          <div className="d-flex align-center flex-wrap">
            <div>
              <h1 className="font_20 font-extrabold">
                {learningTrack.courses.length}{" "}
                {t("learningPaths:coursesInTracks")}
              </h1>
            </div>

            {!learningTrack?.archived && (
              <button
                type="button"
                className="btn btn-red-light-color font-15 ms-auto"
                data-bs-toggle="modal"
                data-bs-target="#add-course-learning-path"
                onClick={() => setShowModal(true)}
              >
                <span className="fas fa-plus fa-fw me-2"></span>
                {t("learningPaths:addCourseToTrack")}
              </button>
            )}
            <EditLearningTrackCoursesModal
              id="add-course-learning-path"
              isOpened={showModal}
              onClose={() => setShowModal(false)}
              handleSave={handleSave}
              courses={courses}
              setCourses={setCourses}
              deletedCoursesIds={deletedCoursesIds}
              setDeltedCoursesIds={setDeltedCoursesIds}
            />
          </div>
          <ul className="list-progressbar-cards">
            {learningTrack.courses.length > 0 ? (
              learningTrack.courses.map((course, index) => (
                <li key={`course-${course.id}`}>
                  <hr className="border-dashed" />
                  <span className="list-progressbar-cards-state">
                    {index + 1}
                  </span>
                  <AdminLearningPathdetailsCard
                    listDetails
                    course={course}
                    courseLabel={course.topics[0]?.title}
                    courseLabelColor={course.topics[0]?.color || "#C15258"}
                    title={course.title}
                    tutorName={course.tutor?.name}
                    courseImage={course.image_url}
                  />
                </li>
              ))
            ) : (
              <EmptyState
                iconComponent={<i className="far fa-play-circle" />}
                title={t("emptyStates.courses")}
                emptyStateText={t("learningPaths:emptyCourseTrack")}
              />
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
