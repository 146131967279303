import { api } from "../index";

export const learningTrack = api.injectEndpoints({
  endpoints: (builder) => ({
    listAdminLearningTracks: builder.query({
      query: ({ filters = null, page = 1 }) =>
        filters
          ? `/api/v1/organization/learning_tracks?page=${page}&per_page=10&${filters}`
          : `/api/v1/organization/learning_tracks?page=${page}&per_page=10`,
      providesTags: ["learningTracks"],
    }),

    getAdminLearningTrackById: builder.query({
      query: (trackId) => `/api/v1/organization/learning_tracks/${trackId}`,
      providesTags: ["learningTrack"],
    }),

    getAdminLearningTrackByIdForEdit: builder.query({
      query: (trackId) =>
        `/api/v1/organization/learning_tracks/${trackId}/edit`,
      providesTags: ["learningTrack"],
    }),

    assignLearningTrackToEmployee: builder.mutation({
      query: ({ learningTrackId, body }) => ({
        url: `/api/v1/organization/learning_tracks/${learningTrackId}/assign`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["learningTracks", "learningTracksLearners"],
    }),

    getLearningTrackJoinedEmployees: builder.query({
      query: ({ learningTrackId, page = 1 }) => ({
        url: `/api/v1/organization/learning_tracks/${learningTrackId}/learners?page=${page}&per_page=10`,
        method: "get",
      }),
      providesTags: ["learningTracksLearners"],
    }),

    addCourseToLearningTrack: builder.mutation({
      query: ({ learningTrackId, body }) => ({
        url: `/api/v1/organization/learning_tracks/${learningTrackId}/add_courses`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["learningTrack"],
    }),

    removeCourseFromLearningTrack: builder.mutation({
      query: ({ learningTrackId, body }) => ({
        url: `/api/v1/organization/learning_tracks/${learningTrackId}/remove_courses`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["learningTrack"],
    }),

    createNewLearningTrack: builder.mutation({
      query: (body) => ({
        url: "/api/v1/organization/learning_tracks",
        method: "POST",
        body,
      }),
    }),

    updateLearningTrack: builder.mutation({
      query: ({ learningTrackId, body }) => ({
        url: `/api/v1/organization/learning_tracks/${learningTrackId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["learningTrack"],
    }),
  }),
});

export const {
  useLazyListAdminLearningTracksQuery,
  useGetAdminLearningTrackByIdQuery,
  useGetAdminLearningTrackByIdForEditQuery,
  useAssignLearningTrackToEmployeeMutation,
  useGetLearningTrackJoinedEmployeesQuery,
  useAddCourseToLearningTrackMutation,
  useRemoveCourseFromLearningTrackMutation,
  useCreateNewLearningTrackMutation,
  useUpdateLearningTrackMutation,
} = learningTrack;
