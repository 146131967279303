import { api } from "../index";

export const leaderboard = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserLeaderboard: builder.query({
      query: ({ page = 1, perPage = 10, keyword }) =>
        keyword
          ? `/api/v1/user/users/leaderboard?page=${page}&per_page=${perPage}&filters[keyword]=${keyword}`
          : `/api/v1/user/users/leaderboard?page=${page}&per_page=${perPage}`,
    }),
  }),
});

export const { useGetUserLeaderboardQuery } = leaderboard;
