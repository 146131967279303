import React from "react";
import { Link } from "react-router-dom";
import ProgressBar from "./ProgressBar";

const HeaderPage = ({
  progressBarFullDetails,
  progressBarPercentageText,
  progressBarSubDetails,
  sectionTitle,
  listItems,
}) => {
  return (
    <div className="page-header">
      <div className="page-header__title">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {listItems.map((listItem) => (
              <li
                className={
                  listItem?.href == "#"
                    ? "breadcrumb-item active"
                    : "breadcrumb-item"
                }
              >
                <Link
                  to={listItem?.href}
                  style={listItem?.href == "#" ? { pointerEvents: "none" } : {}}
                >
                  {listItem?.name}
                </Link>
              </li>
            ))}
          </ol>
        </nav>

        <h1 className="font_18 font-extrabold">{sectionTitle}</h1>
      </div>

      <div className="page-header__progress">
        <span className="ms-auto">
          <ProgressBar
            fulldetails={progressBarFullDetails}
            percentageText={progressBarPercentageText}
            subDetails={progressBarSubDetails}
          />
        </span>
      </div>
    </div>
  );
};

export default HeaderPage;
