import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PostCommunityCard from "../../components/cards/PostCommunityCard";
import {
  useCreateCommentMutation,
  useLazyGetPostQuery,
  useLikePostMutation,
  useUnlikePostMutation,
  useDeletePostMutation,
} from "../../services/users/community";
import CreatePostModal from "../../components/Modals/CreatePostModal";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { useTranslation } from "react-i18next";

export default function CommunityFeed({ listPosts, posts }) {
  const [allPosts, setAllPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [postId, setPostId] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  const [getPost] = useLazyGetPostQuery();
  const [addComment] = useCreateCommentMutation();
  const [like] = useLikePostMutation();
  const [unlike] = useUnlikePostMutation();
  const [deletePost] = useDeletePostMutation();
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    listPosts(page);
  }, [page]);

  useEffect(() => {
    if (posts?.data?.length) {
      if (posts?.pagination?.current == 1) {
        setAllPosts(posts?.data);
      } else {
        setAllPosts((prev) => {
          return [...prev, ...posts?.data];
        });
      }
    }
  }, [posts]);

  const handleToggleLike = async ({ postId, isLikedByMe }) => {
    let result;
    if (isLikedByMe) {
      result = await unlike(postId);
    } else {
      result = await like(postId);
    }
    if (!result?.error) await updateSinglePost(postId);
  };

  const handleSubmittingComment = async ({ postId, comment, setComment }) => {
    let dataForm = new FormData();
    dataForm.append("body", comment);
    if (comment) {
      const result = await addComment({ postId, body: dataForm });
      if (!result?.error) {
        await updateSinglePost(postId);
        setComment("");
      }
    }
  };

  const updateSinglePost = async (postId) => {
    const post = await getPost(postId);
    setAllPosts((prevPosts) =>
      prevPosts.map((prevPost) => {
        if (prevPost?.id != postId) return prevPost;
        return post?.data?.data;
      })
    );
  };

  const handleDeletingPost = async (postId) => {
    const result = await deletePost(postId);
    if (!result.error) await listPosts();
  };

  return (
    <>
      <CreatePostModal listPosts={listPosts}  isVerticallyCentered />
      <ul className="">
        <InfiniteScroll
          className="feed-list"
          dataLength={allPosts.length}
          next={() => setPage(posts?.pagination?.next)}
          hasMore={posts?.pagination?.next}
          loader={<h4>{t("Loading")}</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              {t("communityFeed:successfulPostsDisplay")}
            </p>
          }
          style={{
            overflow: "unset",
          }}
        >
          {allPosts.map((post) => {
            return (
              <li className="mb-3">
                <PostCommunityCard
                  post={post}
                  handleToggleLike={handleToggleLike}
                  handleSubmittingComment={handleSubmittingComment}
                  setPostId={setPostId}
                  containerClassName="post-community-card--employee"
                />
              </li>
            );
          })}
          <ConfirmationModal
            confirmationMessage={t("communityFeed:confirmPostDelete")}
            handleConfirmation={async () => {
              await handleDeletingPost(postId);
              setIsOpen(false);
            }}
            isOpened={isOpen}
            onClose={() => setIsOpen((status) => !status)}
          />
        </InfiniteScroll>
      </ul>
    </>
  );
}
