import React from "react";

export default function Checkbox({ children, id, checked, setChecked }) {
  return (
    <div className="form-check checkbox-main">
      {/* checked */}
      <input
        type="checkbox"
        className="form-check-input"
        id={id}
        name="option1"
        value="something"
        defaultChecked={checked}
        onChange={() => setChecked(!checked)}
      />
      <label className="form-check-label" htmlFor={id}>
        {children}
      </label>
    </div>
  );
}
