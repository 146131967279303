import React, { useState, useEffect } from "react";
import InputFieldFloat from "../../inputs/InputFieldFloat";
import UploadLogo from "../../inputs/UploadLogo";
import {
  useAdminGetDesignQuery,
  useAdminUpdateOrganizationMutation,
} from "../../../services/organization/settings";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import ColorPicker from "../../inputs/ColorPicker";
import hexToRgba from "hex-to-rgba";
import { useTranslation } from "react-i18next";
import { getRenderColor } from "../../../utils/helpers";
import { useSelector } from "react-redux";

export default function WebsiteDesign({ submittingController }) {
  const currentOrganization = useSelector(
    (state) => state.auth.currentOrganization
  );

  const [blockPickerColor, setBlockPickerColor] = useState(
    currentOrganization?.renderColor
  );
  const [viewPicker, setViewPicker] = useState(false);
  const [image, setImage] = useState(currentOrganization?.orgLogo);
  const [isImageChanged, setIsImageChanged] = useState(false);

  const { data: organizationDesign } = useAdminGetDesignQuery();
  const [updateOrganization] = useAdminUpdateOrganizationMutation();

  const { t } = useTranslation(["common", "settings"]);

  const addLogo = (e) => {
    const imageFile = e?.target?.files[0];
    setIsImageChanged(true);
    setImage(imageFile);
  };

  const validationSchema = yup.object({
    name_ar: yup.string().required(t("settings:oragnizationNameARRequired")),
    name_en: yup.string().required(t("settings:oragnizationNameENRequired")),
    domain: yup
      .string()
      .test(
        "includeSpace",
        t("settings:DomainNoSpace"),
        (value) => !/\s/.test(value)
      )
      .test("onlyEnglishAlphabets", t("settings:DomainNoAr"), (value) =>
        /^[a-zA-Z-]+$/.test(value)
      )
      .required(t("settings:DomainRequired")),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name_ar: organizationDesign?.data?.name_ar
        ? organizationDesign?.data?.name_ar
        : "",
      name_en: organizationDesign?.data?.name_en
        ? organizationDesign?.data?.name_en
        : "",
      domain: currentOrganization?.domain ? currentOrganization?.domain : "",
    },

    validationSchema: validationSchema,

    onSubmit: async (form_values) => {
      try {
        let dataForm = new FormData();
        dataForm.append("name_ar", form_values?.name_ar);
        dataForm.append("name_en", form_values?.name_en);
        dataForm.append("domain", form_values?.domain);
        dataForm.append("color", blockPickerColor);
        if (isImageChanged) {
          dataForm.append("image", image);
        }
        const result = await updateOrganization(dataForm);
        toast.success(t("common:successfulSave"));
      } catch (e) {
        toast.error(t("common:failedSaving"));
      }
    },
  });

  useEffect(() => {
    if (submittingController.isSaving) {
      formik.handleSubmit();
      submittingController.setIsSaving(false);
    }
  }, [submittingController.isSaving]);

  return (
    <div className="py-4 setting__tabs-content">
      <p className="font_14 font-color-grey pb-3">
        {" "}
        {t("settings:siteDesignHint")}{" "}
      </p>
      <form
        className="content-form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <div>
              <div>
                <InputFieldFloat
                  label={t("settings:orgNameAr")}
                  type="text"
                  name="name_ar"
                  value={formik.values.name_ar}
                  onChange={formik.handleChange}
                  error={formik.errors.name_ar}
                  touched={formik.touched.name_ar}
                />
              </div>

              <div>
                <InputFieldFloat
                  label={t("settings:orgNameEn")}
                  type="text"
                  name="name_en"
                  value={formik.values.name_en}
                  onChange={formik.handleChange}
                  error={formik.errors.name_en}
                  touched={formik.touched.name_en}
                />
              </div>

              <div>
                <InputFieldFloat
                  label="website Domain"
                  type="text"
                  name="domain"
                  value={formik.values.domain}
                  onChange={formik.handleChange}
                  error={formik.errors.domain}
                  touched={formik.touched.domain}
                  marginBottom="5"
                />
                <p
                  style={{
                    marginTop:
                      formik.errors.domain && formik.touched.domain ? 21 : 0,
                  }}
                  className="mb-0 font_12 font-color-grey"
                >
                  <i className="fas fa-info-circle me-2" />
                  {t("settings:DomainNameHint")}
                </p>
              </div>
              <div>
                <h1 style={{ marginTop: 40 }} className="title-page ">
                  {t("settings:mainColor")}
                </h1>
                <p className="font_14 font-color-grey mb-0 text-start">
                  {t("settings:mainColorHint")}
                </p>
                <div style={{ marginTop: 25 }} className="row">
                  <div className="col-6 col-md-3">
                    <div
                      onClick={() => {
                        setViewPicker(!viewPicker);
                      }}
                      style={{
                        cursor: "pointer",
                        width: 90,
                        height: 50,
                        border: "2px solid #70707033",
                        borderRadius: 12,
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                      }}
                    >
                      <div
                        style={{
                          position: "center",
                          width: 80,
                          height: 40,
                          borderRadius: 8,
                          background: `${blockPickerColor} 0% 0% no-repeat padding-box`,
                          margin: 3,
                          marginTop: 3,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4">
                    <InputFieldFloat
                      label={t("settings:colorCode")}
                      type="text"
                      name="color"
                      value={blockPickerColor}
                      direction="ltr"
                    />
                  </div>
                </div>
                <ColorPicker
                  blockPickerColor={blockPickerColor}
                  setBlockPickerColor={setBlockPickerColor}
                  visible={viewPicker}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <UploadLogo image={image} onChange={(e) => addLogo(e)} />
          </div>
        </div>
      </form>
      <br />
    </div>
  );
}
