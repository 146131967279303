import React, { useState, useEffect, memo, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = ({
  createLessonLog,
  currentLessonId,
  currentLessonType,
  currentLessonVideoSrc,
  setIsAboveVideoExam,
  inVideoQuestions,
  setCurrentInVideoQuestion,
  forwardedRef,
}) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  console.log("component rerenders");

  const options =
    currentLessonType == "Video"
      ? {
          autoplay: true,
          controls: true,
          responsive: true,
          fluid: true,
          sources: [
            {
              src: currentLessonVideoSrc,
              type: "video/mp4",
            },
          ],
        }
      : {};

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(
        videoElement,
        options,
        function onPlayerReady() {
          // videojs.log("player is ready");
          // onReady && onReady(player);
          player.on("waiting", () => {
            videojs.log("player is waiting");
          });

          player.on("dispose", () => {
            videojs.log("player will dispose");
          });

          player.on("ended", function (e) {
            createLessonLog({ lesson_id: currentLessonId });
          });
          player.on("timeupdate", function (e) {
            let videoCurrentSecond = parseInt(player.currentTime());
            let currentInVideoQues = inVideoQuestions.current.find(
              (question) => question.timeInSeconds == videoCurrentSecond
            );
            if (currentInVideoQues) {
              let isQuestionAnsweredBefore = false;
              let currentLessonLogs = forwardedRef?.current.find(
                (log) => log.lesson_id == currentLessonId
              );
              if (currentLessonLogs?.answers?.length) {
                isQuestionAnsweredBefore = currentLessonLogs.answers?.find(
                  (log) => log.id == currentInVideoQues?.id
                );
              }
              if (!isQuestionAnsweredBefore) {
                player.pause();
                setIsAboveVideoExam(true);
                setCurrentInVideoQuestion(currentInVideoQues);
              }
              // player.play();
            }
          });
        }
      ));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;
      // player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef.current]);

  return (
    <div data-vjs-player className="main-video">
      <div ref={videoRef} />
    </div>
  );
};

export default memo(VideoJS);
