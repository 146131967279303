import React, { useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "../inputs/Avatar";
import PostCommunityComment from "../../components/cards/PostCommunityComment";
import { displayDate } from "../../utils/arabicDatesUtilities";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const PostCommunityCard = ({
  post,
  handleToggleLike,
  handleSubmittingComment,
  setPostId,
  containerClassName
}) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  let navigate = useNavigate();
  const { t } = useTranslation("communityFeed");

  const navigateToUserprofile = () =>
    navigate(`/employee/profile/${post.user.id}`);

  return (
    <div className={`card-style post-community-card ${containerClassName}`}>
      <div className="post-community-card__header d-flex flex-wrap align-center mb-4">
        <div className="d-flex flex-wrap align-center">
          <Avatar width="60" height="60" avatarUrl={post?.user?.avatar_url} />
          <div className="ms-2">
            <h1
              onClick={navigateToUserprofile}
              role="button"
              className="mb-0 font_18 font-extrabold clickable "
            >
              {post?.user?.name}
            </h1>
            <p className="mb-0 font_14 font-color-grey">
              {post?.user?.department?.name}
            </p>
          </div>
        </div>

        <div className="d-flex flex-wrap align-center ms-auto">
          <p className="mb-0 font_14 font-color-grey">
            <span>{displayDate(post?.created_at)}</span>
          </p>

          {post?.user?.id == currentUser?.id && (
            <div className="dots-tools ms-2">
              <div className="dropdown tools">
                <button
                  className=" dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="fas fa-ellipsis-h fa-fw font_14 font-color-grey"></span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <button
                      className="dropdown-item font_14 font-bold"
                      data-bs-toggle="modal"
                      data-bs-target="#confirmation-modal"
                      onClick={() => setPostId(post?.id)}
                    >
                      <span className="fas fa-trash-alt fa-fw font-color-grey me-2 "></span>
                      <span className="">{t("communityFeed:remove")} </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="post-community-card__content"
        style={{ whiteSpace: "pre-wrap" }}
      >
        <p className="mb-0 font_16 mb-4 font-bold text-break">{post?.body}</p>

        {post?.image_urls?.length > 0 && (
          <img
            className="img"
            alt=""
            style={{ width: "100%" }}
            src={post?.image_urls[0]}
          />
        )}
      </div>

      <div>
        <PostCommunityComment
          post={post}
          handleToggleLike={handleToggleLike}
          handleSubmittingComment={handleSubmittingComment}
        />
      </div>
    </div>
  );
};

export default PostCommunityCard;

PostCommunityCard.prototype = {
  containerClassName: PropTypes.string
}

PostCommunityCard.defaultProps = {
  containerClassName: ""
}
