import React, { useEffect, useState, useRef } from "react";

export default function SearchAutoComplete({
  name,
  label,
  type,
  disabled,
  marginBottom,
  value,
  onBlur,
  onChange,
  onSelect,
  error,
  touched,
  options,
  optionComponent: OptionComponent,
}) {
  const [showDDL, setShowDDL] = useState(true);
  const dropDownRef = useRef();
  const inputFieldRef = useRef();

  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, []);

  useEffect(() => {
    setShowDDL(true);
  }, [options]);

  function clickHandler(e) {
    if (e.target == inputFieldRef.current) {
      return;
    }
    if (!dropDownRef?.current?.contains(e.target)) {
      setShowDDL(false);
    }
  }

  return (
    <div className="input-text auto completee">
      <div
        className="did-floating-label-content"
        style={{ marginBottom: marginBottom ? marginBottom + "px" : "27px" }}
      >
        <input
          ref={inputFieldRef}
          name={name}
          className="did-floating-input"
          type={type}
          placeholder=" "
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          autoComplete="off"
        />
        {showDDL && (
          <div
            ref={dropDownRef}
            className=""
            style={{
              position: "absolute",
              zIndex: "1",
              width: "100%",
              background: "white",
              filter: "drop-shadow(1px 13px 9px rgba(0,0,0,0.5))",
            }}
          >
            {options.map((option) => (
              <div
                key={`option-${option.id}`}
                onClick={() => {
                  onSelect(option);
                  setShowDDL(false);
                }}
              >
                <OptionComponent option={option} />
              </div>
            ))}
          </div>
        )}
        <label className="did-floating-label">{label}</label>
        {error && touched ? <div className="input-error">{error}</div> : null}
      </div>
    </div>
  );
}
