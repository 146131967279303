import Proptypes from "prop-types";
import AnswerQuestion from "../AnswersQuestion";
import InputField from "../inputs/InputField";
import SelectFilter from "../inputs/SelectFilter";
import Modal from "./Modal";

const AnswersQuestionModal = ({
  id,
  type,
}) => {

  const sectionsOptions = [
    { value: 'two', label: 'تربية الأطفال' }
  ];

  const tagesOptions = [
    { value: 'two', label: 'الدرس الأول - المقدمة' }
  ];

  const levelOptions = [
    { value: 'good', label: '13:00' },
  ];
  return (
    <Modal
      id={id}
      maxWidth={1000}
      title={type === "add" ? "إضافة سؤال جديد" : "تعديل بيانات السؤال"}
      className="answers-modal"
    >
      <div className="answers-modal__body">

        <InputField />

        <div className="answers-modal__select-course">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col">
              <SelectFilter
                label="الدورة"
                options={sectionsOptions}
                className="border border-2 shadow-none py-1"
                controlMinHeight={0}
                dropdownIndicatorColor="#999"
              />
            </div>
            <div className="col">
              <SelectFilter
                label="الدرس"
                options={tagesOptions}
                className="border border-2 shadow-none py-1"
                controlMinHeight={0}
                dropdownIndicatorColor="#999"
              />
            </div>
            <div className="col">
              <SelectFilter
                label="الوقت"
                options={levelOptions}
                className="border border-2 shadow-none py-1"
                controlMinHeight={0}
                dropdownIndicatorColor="#999"
              />
            </div>
          </div>
        </div>

        <div>
          <h3 className="font_24 font-extrabold my-4">إجابات السؤال</h3>
          <AnswerQuestion num={1} />
          <AnswerQuestion num={2} />
          <AnswerQuestion num={3} />
          <AnswerQuestion num={4} />
        </div>
      </div>

      <div className={`answers-modal__footer d-flex`}>
        <button className="btn btn-main-color me-2"> حفظ السؤال</button>
        <button className="btn btn-grey-color">  إلغاء</button>
      </div>

    </Modal>
  )
}

export default AnswersQuestionModal;

AnswersQuestionModal.prototype = {
  id: Proptypes.string.isRequired,
  type: Proptypes.oneOf(["edit", "add"]).isRequired,
}