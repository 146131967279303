import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function EmployeeWelcomeCard({}) {
  const currentUser = useSelector((state) => state.auth.currentUser);
  let navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  return (
    <div className="emp-welcome-card card-style">
      <h1 className="font_20 font-extrabold mb-2">
        {t("welcome")} {currentUser?.name}
      </h1>
      <p className="font_16 font-color-grey">{t("continueJourney")}</p>
      <button
        className="btn btn-main-color-border font-15"
        onClick={() => navigate("/employee/learning_paths")}
      >
        {t("goToLearningTracks")}
        <span className="fas fa-arrow-right fa-fw ms-2"></span>
      </button>
    </div>
  );
}
