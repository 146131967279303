import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useLazyGetMyPostsQuery } from "../../services/users/community";
import { useGetDashboardStatsQuery } from "../../services/users/statistics";
import Profile from "../../components/Profile/Tabs/Profile";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function MyProfile() {
  const currentUser = useSelector((state) => state.auth.currentUser);

  const { data: dashboardStats, isLoading: isDashboardStatsLoading } =
    useGetDashboardStatsQuery();
  const [listMyPosts, { data: posts }] = useLazyGetMyPostsQuery();

  const getMyPosts = async (page) => {
    await listMyPosts(page);
  };

  if (isDashboardStatsLoading && !currentUser) return <LoadingIndicator />;



  return (
    <Profile
      currentUser={currentUser}
      dashboardStats={dashboardStats}
      listPosts={getMyPosts}
      posts={posts}
    />
  );
}
