import { useState, useEffect } from "react";
import Avatar from "../inputs/Avatar";
import { useTranslation } from "react-i18next";
import Icon from "../icons/Icon";
import { EllipsisHIcon, ShareIcon } from "../icons/Icons";
import SocialMedia from "../SocialMedia";

const EmployeeProfileCard = ({ currentUser }) => {
  const [socialMediaLinksAvailable, SetSocialMediaLinksAvailable] =
    useState(false);
  const { t, i18n } = useTranslation(["common", "profile"]);
  const arabicName = currentUser?.name_ar
    ? currentUser?.name_ar
    : currentUser?.name_en;
  const userName = i18n.language === "ar" ? arabicName : currentUser?.name_en;

  useEffect(() => {
    SetSocialMediaLinksAvailable(
      currentUser.facebook ||
        currentUser.instagram ||
        currentUser.linkedin ||
        currentUser.twitter ||
        currentUser.snapchat
    );
  }, [currentUser]);

  return (
    <div className="card-style employee-profile__card">
      <section>
        <Avatar
          avatarUrl={currentUser?.avatar_url}
          className="mb-1"
          width={60}
          height={60}
        />
        <h5 className="font-color-dark font_20 font-extrabold mb-0">
          {userName}
        </h5>
        <p className="font_16 font-bold font-color-grey mb-0">
          {currentUser?.email}
        </p>
        <p className="font_14 font-color-grey mb-0">
          {currentUser?.department?.name}
        </p>
      </section>

      <section className="d-flex employee-profile__card__actions">
        <button className="btn btn-main-color btn-send-message font-extrabold">
          <i className="far fa-comment me-2"></i>
          {t("sendMessage")}
        </button>

        <button className="btn btn-dark">
          <Icon
            icon={<ShareIcon />}
            iconName="share"
            fill="#222"
            iconSize={18}
            height={15.48}
            viewBox="0 0 18 18"
          />
        </button>

        <button className="btn btn-dark">
          <span className="fas fa-ellipsis-h font_18 font-color-dark" />
        </button>
      </section>

      <section>
        <h6 className="font_16 font-extrabold font-color-dark">
          {" "}
          {t("profile:myAccountSocialMedia")}{" "}
        </h6>
        {socialMediaLinksAvailable ? (
          <SocialMedia url={currentUser} />
        ) : (
          <p className="font_12 font-color-grey-light mb-4">لا يوجد</p>
        )}
      </section>

      <section className="employee-profile__card__about-me">
        <h6 className="font_16 font-extrabold font-color-dark">
          {t("profile:aboutMe")}
        </h6>
        <p className="font_14 font-color-grey">
          {currentUser?.bio ? currentUser?.bio : "لا يوجد"}
        </p>
      </section>

      <section className="employee-profile__card__skills">
        <h6 className="font_16 font-extrabold font-color-dark">
          {t("profile:personalSkills")}
        </h6>
        {currentUser?.skills ? (
          currentUser?.skills.map((skill) => (
            <span
              key={skill}
              className="badge rounded-pill font_12 font-color-grey font-bold"
            >
              {skill}
            </span>
          ))
        ) : (
          <p className="font_14 font-color-grey">
            {" "}
            {t(`notFound`, { name: t("skills") })}
          </p>
        )}
      </section>
    </div>
  );
};

export default EmployeeProfileCard;
