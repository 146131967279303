import { api } from "../index";

export const adminUsers = api.injectEndpoints({
  endpoints: (builder) => ({
    AdminListUsers: builder.query({
      query: ({ filters, page = 1 }) =>
        filters
          ? `/api/v1/organization/users?page=${page}&per_page=10&${filters}`
          : `/api/v1/organization/users?page=${page}&per_page=10`,
      providesTags: ["Users"],
    }),

    AdminGetUser: builder.query({
      query: (userId) => `/api/v1/organization/users/${userId}`,
      providesTags: ["User"],
    }),

    AdminGetUserStats: builder.query({
      query: (userId) => `/api/v1/organization/users/${userId}/stats`,
    }),

    AdminGetUserPosts: builder.query({
      query: ({ userId, page }) =>
        `/api/v1/organization/users/${userId}/posts?page=${page}&per_page=10`,
    }),

    AdminGetUserLearningTracks: builder.query({
      query: ({ userId, page = 1, perPage = 10 }) =>
        `/api/v1/organization/users/${userId}/learning_tracks/progress?page=${page}&per_page=${perPage}`,
    }),

    AdminGetUserQuizStats: builder.query({
      query: (userId) => `/api/v1/organization/users/${userId}/quiz_stats`,
    }),

    AdminGetUserCompletedCourses: builder.query({
      query: (userId) =>
        `/api/v1/organization/users/${userId}/courses/completed`,
    }),

    AdminGetUserPostStats: builder.query({
      query: (userId) => `/api/v1/organization/users/${userId}/statistics`,
    }),

    searchUsers: builder.query({
      query: (filters) => ({
        url: `api/v1/organization/users?${filters}`,
        method: "GET",
      }),
    }),

    inviteUser: builder.mutation({
      query: (email) => ({
        url: `/api/v1/organization/users/invite`,
        method: "POST",
        body: { email },
      }),
    }),

    inviteByCSV: builder.mutation({
      query: (body) => ({
        url: `/api/v1/organization/users/invite_by_csv`,
        method: "POST",
        body,
      }),
    }),

    updateUser: builder.mutation({
      query: ({ userId, updates }) => ({
        url: `/api/v1/organization/users/${userId}`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["Users", "User"],
    }),
  }),
});

export const {
  useAdminListUsersQuery,
  useAdminGetUserQuery,
  useAdminGetUserStatsQuery,
  useLazyAdminGetUserPostsQuery,
  useAdminGetUserPostStatsQuery,
  useAdminGetUserLearningTracksQuery,
  useAdminGetUserQuizStatsQuery,
  useAdminGetUserCompletedCoursesQuery,
  useInviteUserMutation,
  useInviteByCSVMutation,
  useUpdateUserMutation,
  useLazySearchUsersQuery,
} = adminUsers;
