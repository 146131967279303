import React from "react";

const ChoiceQuestion = ({
  title,
  resultType,
  raidoName,
  answers,
  handleChangingAnswer,
  disabled,
}) => {
  return (
    <div className="choice-questions">
      {title && <h1 className="font_24 font-extrabold mb-4">{title}</h1>}

      <div className={`form-group radio-input-question ${resultType}`}>
        {answers?.map((answer) => (
          <div className="radio custom" onChange={handleChangingAnswer}>
            <label>
              <input
                type="radio"
                name={raidoName}
                value={answer}
                disabled={disabled}
              />
              {answer}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChoiceQuestion;
