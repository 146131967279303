import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetUserProfileDataQuery,
  useLazyGetUserPostsQuery,
} from "../../services/users/userProfile";
import Profile from "../../components/Profile/Tabs/Profile";
import NotFoundPage from "../404";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function UserProfile() {
  const { userID } = useParams();
  const [listUserPosts, { data: posts }] = useLazyGetUserPostsQuery();
  const { data, isLoading } = useGetUserProfileDataQuery(userID);

  const getUserPosts = async (page) => {
    await listUserPosts({ page, userID });
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!data) {
    return <NotFoundPage />;
  }

  return (
    <Profile currentUser={data?.data} listPosts={getUserPosts} posts={posts} />
  );
}
