import { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const SelectFilter = ({
  label,
  name,
  options,
  hasIcon,
  className,
  controlMinHeight,
  dropdownIndicatorColor,
  onchange,
}) => {
  const currentOrganization = useSelector(
    (state) => state.auth.currentOrganization
  );

  const styles = {
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        color: isDisabled ? "#ccc" : isSelected ? "white" : "black",
        cursor: isDisabled ? "not-allowed" : "pointer",
      };
    },

    control: (styles) => {
      return {
        ...styles,
        backgroundColor: "white",
        border: "none",
        boxShadow: "none",
        minHeight: controlMinHeight,

        ":hover": {
          ...styles[":hover"],
          border: "none",
        },
      };
    },
    input: (styles) => ({ ...styles }),
    menu: (styles) => ({ ...styles, backgroundColor: "white" }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: dropdownIndicatorColor,
    }),
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const { i18n } = useTranslation();

  return (
    <div className={`card-style select-filter ${className}`}>
      <p className="font_14 font-bold font-color-grey mb-0">{label}</p>
      {hasIcon && (
        <span className="icon">
          <i className="icon__level icon__level--good"></i>
          <i className="icon__level icon__level--med"></i>
          <i className="icon__level icon__level--weak"></i>
        </span>
      )}

      <div style={hasIcon ? { marginInlineStart: "30px" } : null}>
        <Select
          className="basic-single border-0 select-control--menu-is-open"
          classNamePrefix="select"
          defaultValue={options[0]}
          isDisabled={isDisabled}
          isRtl={i18n.language === "ar" ? true : false}
          name={name}
          options={options}
          styles={styles}
          onChange={onchange}
          theme={{
            colors: {
              primary: currentOrganization?.renderColor,
            },
          }}
        />
      </div>
    </div>
  );
};

export default SelectFilter;

SelectFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  label: PropTypes.string,
  hasIcon: PropTypes.bool,
  className: PropTypes.string,
  dropdownIndicatorColor: PropTypes.string,
  controlMinHeight: PropTypes.number,
};

SelectFilter.defaultProps = {
  options: [],
  label: "",
  hasIcon: false,
  className: "",
  controlMinHeight: 36,
  dropdownIndicatorColor: "",
};
