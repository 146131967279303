

import React from 'react'

export default function learningPaths() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="20.5" viewBox="0 0 22.5 20.5">
  <g id="learningPaths" transform="translate(-0.75 -1.75)">
    <path id="Path_34333" data-name="Path 34333" d="M2,3H8a4,4,0,0,1,4,4V21a3,3,0,0,0-3-3H2Z" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
    <path id="Path_34334" data-name="Path 34334" d="M22,3H16a4,4,0,0,0-4,4V21a3,3,0,0,1,3-3h7Z" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
  </g>
</svg>
  )
}
