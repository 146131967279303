import React from "react";

const Pagination = ({ current = 1, pages, handlePagination }) => {
  return (
    <ul className="pagination justify-content-center">
      <li className="page-item">
        <button
          className="page-link Next"
          aria-label="Next"
          onClick={() => handlePagination(--current)}
          disabled={current <= 1}
        >
          <span aria-hidden="true" className="fas fa-chevron-right fa-fw"></span>
        </button>
      </li>

      {[...Array(pages)].map((e, i) => {
        let pageNumber = ++i;
        return (
          <li
            key={`page-${i}`}
            className={current === pageNumber ? "page-item active" : "page-item"}
            onClick={() => handlePagination(pageNumber)}
          >
            <button className="page-link">{pageNumber}</button>
          </li>
        );
      })}

      {/* <li className="page-item d-flex align-center">
        <span className="dots ">...</span>
      </li> */}

      <li className="page-item">
        <button
          className="page-link Previous"
          aria-label="Previous"
          onClick={() => handlePagination(++current)}
          disabled={current >= pages}
        >
          <span aria-hidden="true" className="fas fa-chevron-left fa-fw"></span>
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
