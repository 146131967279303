import React from "react";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Avatar from "../inputs/Avatar";
import Switch from "../inputs/Switch";
import { useUpdateUserMutation } from "../../services/organization/adminUsers";
import { useNavigate } from "react-router-dom";
import { getAccountStatus } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import EmptyState from "../EmptyState";
import Icon from "../icons/Icon";
import { UsersStrokeIcon } from "../icons/Icons";

export default function TableAdminLeaderBoard({ usersList }) {
  const [updateUser] = useUpdateUserMutation();
  const navigate = useNavigate();

  const { t } = useTranslation(["common", "leaderboard", "learningPaths"]);
  return (
    <Table className="table fixed-header">
      <Thead className="table-header">
        <Tr>
          <Th>{t("employeeName")}</Th>
          <Th>{t("leaderboard:employeePoints")} </Th>

          <Th>{t("learningPaths:completedTracks")}</Th>
          <Th>{t("learningPaths:completedCourses")}</Th>

          <Th className="text-center">{t("actions")}</Th>
        </Tr>
      </Thead>
      <Tbody className="table__content">
        {usersList?.data?.length ? (
          usersList.data.map((user, key) => {
            let accountStatus = getAccountStatus(
              user?.registered,
              user?.active,
              t
            );

            return (
              <Tr key={key}>
                <Td>
                  <div className="item leader-board">
                    {/* modifier --> leader-1 , leader-2 , leader-3 , myself-selected  */}
                    <div className={`leader-board-card  leader-${user?.rank}`}>
                      <span className="num me-2 font_14 font-extrabold">
                        {user?.rank}
                      </span>
                      <Link to={`/admin/employee/${user?.id}`} className="me-2">
                        <Avatar
                          width="40"
                          height="40"
                          avatarUrl={user?.avatar_url}
                        />
                      </Link>
                      <Link
                        to={`/admin/employee/${user?.id}`}
                        className="bold font_14 font-extrabold "
                      >
                        {user?.name}
                      </Link>
                    </div>
                  </div>
                </Td>
                <Td>
                  <div className="item">
                    <p className="mb-0 font_14 ">
                      <span className="fas fa-star fa-fw me-2 font-color-gold"></span>
                      <span className="font-extrabold">{user?.points}</span>{" "}
                    </p>
                  </div>
                </Td>
                <Td>
                  <div className="item">
                    <p className="mb-0 font_14 ">
                      {" "}
                      <span className="font-extrabold">
                        {user?.completed_learning_tracks_count}
                      </span>{" "}
                      {t("learningPaths:track")}
                    </p>
                  </div>
                </Td>
                <Td>
                  <div className="item">
                    <p className="mb-0 font_14 ">
                      {" "}
                      <span className="font-extrabold">
                        {user?.completed_courses_count}
                      </span>{" "}
                      {t("learningPaths:course")}
                    </p>
                  </div>
                </Td>

                <Td>
                  <div className="item text-center">
                    <div className="dropdown tools">
                      <button
                        className=" dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="fas fa-ellipsis-h fa-fw font_14 font-color-grey"></span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <div>
                            <p className="bold mb-0 font_12 font-extrabold">
                              {user?.name}
                            </p>
                            <p className="mb-0 font_12 font-color-grey">
                              {user?.email}
                            </p>
                          </div>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <Link
                            className="dropdown-item font_14 font-bold"
                            to={`/admin/employee/${user?.id}`}
                          >
                            <span className="far fa-eye fa-fw font-color-grey me-2"></span>
                            <span className=" ">{t("seeAccount")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item font_14 font-bold"
                            to="#"
                          >
                            <span className="far fa-comment fa-fw font-color-grey me-2"></span>

                            <span className=" ">{t("sendMessage")}</span>
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>

                        <li>
                          <Switch
                            label={t("accountStatus")}
                            disabled={accountStatus.type == "notResigtered"}
                            checked={accountStatus.type == "active"}
                            onChange={() =>
                              updateUser({
                                userId: user?.id,
                                updates: {
                                  active: !(accountStatus.type == "active"),
                                },
                              })
                            }
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Td>
              </Tr>
            );
          })
        ) : (
          <tr>
            <td className="border-bottom-0" colSpan={6}>
              <EmptyState
                iconComponent={
                  <Icon
                    icon={<UsersStrokeIcon />}
                    iconName="users"
                    iconSize={38}
                    viewBox="0 0 25 25"
                    iconColor="#bbbabf"
                    hasStroke
                  />
                }
                title={t("emptyStates.employees")}
                emptyStateText={t("employees:addEmployeeToAppear")}
              />
            </td>
          </tr>
        )}
      </Tbody>
    </Table>
  );
}
