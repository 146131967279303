import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Avatar from "../../components/inputs/Avatar";

const HomeAdminsCard = ({ totalUsersNumber }) => {
  const { t } = useTranslation(["common", "employees"]);

  return (
    <div className="card-style">
      <div className="d-flex flex-wrap align-center">
        <h1 className="mb-0 font_20 font-extrabold mb-4">
          {t("employees:theEmployees")}
        </h1>
        <Link
          to="/admin/employee"
          className="ms-auto mb-0 font_14 font-extrabold mb-4 font-color-grey"
        >
          {t("showAll")}
          <span className="fas fa-arrow-right fa-fw ms-2"></span>
        </Link>
      </div>

      <div className="d-flex align-center flex-wrap">
        <p className="font_18 font-color-grey font-bold">
          {" "}
          {totalUsersNumber} {t("employee")}
        </p>
        <ul className="avatar-group ms-auto">
          {totalUsersNumber > 0 && (
            <li>
              <Avatar width="30" height="30" />
            </li>
          )}
          {totalUsersNumber > 1 && (
            <li>
              <Avatar width="30" height="30" />
            </li>
          )}
          {totalUsersNumber > 2 && (
            <li>
              <Avatar width="30" height="30" />
            </li>
          )}
          <li>
            {totalUsersNumber > 3 && (
              <div className="avatar-group-total" width="30" height="30">
                +{totalUsersNumber - 3}
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HomeAdminsCard;
