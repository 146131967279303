import React from "react";
import { useTranslation } from "react-i18next";

const ViewsHoursCard = ({ img }) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="card-style ">
      <div className="d-flex flex-wrap align-center">
        <h1 className="mb-0 font_20 font-extrabold mb-4">
          {t("watchingHoursPercentage")}
        </h1>
      </div>

      <img
        style={{ maxWidth: "100%" }}
        src="https://i.stack.imgur.com/hjX6C.png"
        alt=""
      />
    </div>
  );
};

export default ViewsHoursCard;
