import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import RadioGroup from "../inputs/RadioGroup";
import * as yup from "yup";
import AutoCompleteMultiSelectInput from "../inputs/AutoCompleteMultiSelectInput";
import DatePickerInput from "../inputs/DatePickerInput";
import { useLazySearchUsersQuery } from "../../services/organization/adminUsers";
import { useAssignLearningTrackToEmployeeMutation } from "../../services/organization/adminLearningTrack";
import { useListDepartmentsQuery } from "../../services/public/departments";
import InputSelectFloat from "../inputs/InputSelectFloat";

import Modal from "../../components/Modals/Modal";
import { useTranslation } from "react-i18next";

function EmailOptionComponent({ option }) {
  return (
    <div className="d-flex" style={{ padding: "5px", cursor: "pointer" }}>
      <img src={option.avatar_url} alt="user-avatar" style={{ width: "30px", height: "30px" }} />
      <p style={{ marginRight: "10px", marginBottom: "0px" }}> {option.email}</p>
    </div>
  );
}

function SelectedComponent({ option, onRemove }) {
  return (
    <div className="selected-component">
      <img className="selected-component__avatar" src={option.avatar_url} alt="user-avatar" />
      <p className="selected-component__name"> {option.email}</p>
      <span
        onClick={onRemove}
        className="fas fa-times selected-component__remove-btn font-color-grey"
      />
    </div>
  );
}

export default function AddEmployeeToLearningTrackModal({ id, isOpened, onClose, learningTrack }) {
  const [email, setEmail] = useState("");
  const [departmentId, setDepartmentId] = useState(0);
  const [assignLearningTrackToEmployee] = useAssignLearningTrackToEmployeeMutation();

  const [searchUsers, { data: userEmailsOptions }] = useLazySearchUsersQuery();
  const { data: departmentsList } = useListDepartmentsQuery();
  const { t } = useTranslation(["common", "learningPaths"]);

  const validationSchema = yup.object({
    // employees: yup.array().min(1, "من فضلك ادخل بريد الكتروني واحد علي الاقل"),
    employees: yup.array(),
    // employees: yup.array().when("type", {
    //   is: (type) => type === "email",
    //   then: yup.array().min(1, "من فضلك ادخل بريد الكتروني واحد علي الاقل"),
    // }),
    date: yup.string().required(t("learningPaths:setTrackDeadline")),
  });

  useEffect(() => {
    let filters = [];
    if (email.trim().length > 1) {
      filters.push("filters[active]=true");
      filters.push(`filters[keyword]=${encodeURIComponent(email.trim())}`);
      filters.push(`filters[skip_in_learning_tracks]=${learningTrack?.id}`);
      searchUsers(filters.join("&"));
    }
  }, [email, learningTrack, searchUsers]);

  const formik = useFormik({
    initialValues: {
      employees: [],
      date: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let body;
        if (values?.type === "email") {
          if (values.employees.length === 0) {
            toast.error(t("learningPaths:inviteEmployeeModalError"));
            return;
          }
          if (values.employees.length > 0) {
            body = {
              complete_by: values.date,
              user_ids: values.employees.map((employee) => employee.id),
            };
          } else {
          }
        } else if (values?.type === "department" && departmentId) {
          body = {
            complete_by: values.date,
            department_id: departmentId,
          };
        }

        if (body) {
          assignLearningTrackToEmployee({
            learningTrackId: learningTrack.id,
            body,
          });
          resetForm();
          onClose();
          toast.success(t("addedSuccessully"));
        }
      } catch (e) {
        toast.error(e?.data?.message);
      }
    },
  });

  function handleClose() {
    formik.resetForm();
    onClose();
  }

  return (
    <Modal
      id={id}
      title={t("learningPaths:addEmployee")}
      mainBtn={t("add")}
      mainBtnOnclick={formik.handleSubmit}
      className="add-course-learning-path-modal add-employee-to-track-modal"
      maxWidth="670"
      onClose={handleClose}
      isOpened={isOpened}
    >
      <RadioGroup
        values={[
          {
            id: 1,
            text: t("learningPaths:oneEmployee"),
            groupName: "type",
            value: "email",
          },
          {
            id: 2,
            text: t("common:employeeDepartment"),
            groupName: "type",
            value: "department",
          },
        ]}
        formik={formik}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        {formik?.values?.type === "email" && (
          <>
            <p className="font-16 font-color-grey">{t("learningPaths:addEmployeeHint")}</p>
            <AutoCompleteMultiSelectInput
              marginBottom="30"
              label={t("learningPaths:searchViaEmail")}
              type="email"
              name="employees"
              autocomplete="off"
              noMatchError={t("learningPaths:noMatchEmailError")}
              setSearchKeyword={setEmail}
              options={userEmailsOptions?.data || []}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              error={formik.errors.employees}
              touched={formik.touched.employees}
              optionComponent={EmailOptionComponent}
              selectedComponent={SelectedComponent}
            />

            <DatePickerInput
              marginBottom="0"
              label={t("Deadline")}
              name="date"
              minDate={new Date()}
              value={formik.values.date}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              error={formik.errors.date}
              touched={formik.touched.date}
            />
          </>
        )}

        {formik?.values?.type === "department" && (
          <>
            <p className="font-16 font-color-grey">{t("learningPaths:addDepartmentHint")}</p>
            <InputSelectFloat
              label={t("common:employeeDepartment")}
              type="text"
              // value=""
              onChange={(e) => {
                setDepartmentId(e.target.value);
              }}
            >
              <option selected="selected" value={""} disabled>
                {t("common:chooseDepartment")}
              </option>
              {departmentsList?.data?.map((department) => (
                <option key={department?.id} value={department?.id}>
                  {department?.name}
                </option>
              ))}
            </InputSelectFloat>
            <DatePickerInput
              marginBottom="0"
              label={t("Deadline")}
              name="date"
              minDate={new Date()}
              value={formik.values.date}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              error={formik.errors.date}
              touched={formik.touched.date}
            />
          </>
        )}
      </form>
    </Modal>
  );
}
