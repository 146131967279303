import React, { useState } from "react";
import EditLearningTrackCoursesModal from "../../../../Modals/EditLearningTrackCoursesModal";
import AdminLearningPathdetailsCard from "../../../../cards/AdminLearningPathdetailsCard";
import { useTranslation } from "react-i18next";
export default function Courses({
  courses,
  setCourses,
  deletedCoursesIds,
  setDeltedCoursesIds,
}) {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation(["learningPaths"]);

  async function deleteCourse(courseId) {
    try {
      const tempCourses = courses.filter((course) => course.id !== courseId);
      setDeltedCoursesIds([...deletedCoursesIds, courseId]);
      setCourses(tempCourses);
    } catch (error) {}
  }
  return (
    <div
      className="mt-2"
      id="nav-learning-track-courses"
      role="tabpanel"
      aria-labelledby="learning-track-courses-tab-tab"
    >
      <div className="container-fluid">
        <div className="row p-4">
          <div className="d-flex align-center flex-wrap">
            <div>
              <h1 className="font_20 font-extrabold">
                {t("learningPaths:coursesInTrack")}{" "}
              </h1>
            </div>
            <button
              type="button"
              className="btn btn-red-light-color font-15 ms-auto"
              data-bs-toggle="modal"
              data-bs-target="#add-course-learning-path"
              onClick={() => setShowModal(true)}
            >
              <span className="fas fa-plus fa-fw me-2"></span>
              {t("learningPaths:addCourseToTrack")}
            </button>
            <EditLearningTrackCoursesModal
              id="add-course-learning-path"
              isOpened={showModal}
              onClose={() => setShowModal(false)}
              handleSave={() => setShowModal(false)}
              courses={courses}
              setCourses={setCourses}
              deletedCoursesIds={deletedCoursesIds}
              setDeltedCoursesIds={setDeltedCoursesIds}
            />
          </div>
          {courses?.length === 0 && (
            <div className="learinig-path-edit-courses-empty-state">
              <h3 className="title">{t("learningPaths:noCoursesinTrack")}</h3>
              <p className="description">
                {t("learningPaths:noCoursesinTrackHint")}{" "}
              </p>
            </div>
          )}
          <ul className="list-progressbar-cards">
            {courses?.map((course, index) => (
              <li key={`course-${course.id}`}>
                <hr className="border-dashed" />
                <span className="list-progressbar-cards-state">
                  {index + 1}
                </span>
                <AdminLearningPathdetailsCard
                  listDetails
                  course={course}
                  courseLabel={course.topics[0]?.title}
                  courseLabelColor={course.topics[0]?.color || "#C15258"}
                  title={course.title}
                  tutorName={course.tutor?.name}
                  courseImage={course.image_url}
                />
                <button
                  className="flex-0"
                  onClick={() => {
                    deleteCourse(course.id);
                  }}
                >
                  <span className="fas fa-times fa-fw font-color-grey"></span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
