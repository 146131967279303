import React from "react";
import HomeStatsCard from "../../cards/HomeStatsCard";
import TotalCoursesCard from "../../cards/TotalCoursesCard";
import { useAdminGetUserStatsQuery } from "../../../services/organization/adminUsers";
import LoadingIndicator from "../../LoadingIndicator";
import { useTranslation } from "react-i18next";

export default function Reports({ user }) {
  const { data: userStats, isLoading: isGetUserStatsLoading } =
    useAdminGetUserStatsQuery(user?.id);

  const { t } = useTranslation(["common", "employees"]);

  if (isGetUserStatsLoading) return <LoadingIndicator />;

  return (
    <div className="profile-about">
      <div className="row">
        <h1 className="font_20 font-extrabold mb-4">
          {t("employees:employeeReports")}
        </h1>
        <div className="col-lg-3 col-sm-12">
          <HomeStatsCard
            bgColor="rgba(157, 78, 221, 0.06)"
            img="graduation-cap-solid.svg"
            title={t("learningTracksCount")}
            num={userStats?.data?.learning_tracks_count}
            singleNumber
          />
        </div>
        <div className="col-lg-3 col-sm-12">
          <HomeStatsCard
            bgColor="rgba(241, 115, 0, 0.06)"
            img="courses.svg"
            title={t("coursesCount")}
            num={userStats?.data?.courses_count}
            singleNumber
          />
        </div>
        <div className="col-lg-3 col-sm-12">
          <HomeStatsCard
            bgColor="rgba(247, 181, 0, 0.06)"
            img="star.svg"
            title={t("pointsCollected")}
            num={userStats?.data?.points}
            singleNumber
          />
        </div>
        <div className="col-lg-3 col-sm-12">
          <HomeStatsCard
            bgColor="rgba(179, 106, 94, 0.06)"
            img="time-brown.svg"
            title={t("watchingHoursNumber")}
            num={userStats?.data?.watching_hours}
            singleNumber
            text={t("hoursRoughly")}
          />
        </div>
        <div className="col-lg-6 col-sm-12">
          <TotalCoursesCard
            totalCourses={userStats?.data?.courses_count}
            completedCourses={userStats?.data?.completed_courses_count}
          />
        </div>
        <div className="col-lg-3 col-sm-12">
          <HomeStatsCard
            bgColor="rgba(1, 194, 153, 0.06)"
            img="pass.svg"
            title={t("succeedInExams")}
            num={userStats?.data?.passed_quizes_count}
            totalNum={
              parseInt(userStats?.data?.passed_quizes_count) +
              parseInt(userStats?.data?.failed_quizes_count)
            }
          />
        </div>
        <div className="col-lg-3 col-sm-12">
          <HomeStatsCard
            bgColor="rgba(239, 35, 60, 0.06)"
            img="fail.svg"
            title={t("failedInExams")}
            num={userStats?.data?.failed_quizes_count}
            totalNum={
              parseInt(userStats?.data?.passed_quizes_count) +
              parseInt(userStats?.data?.failed_quizes_count)
            }
          />
        </div>
      </div>
    </div>
  );
}
