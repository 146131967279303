const StartCourseExamCard = ({
  duration,
  numberOfQuestion,
  handleStartingExam,
  examTitle,
  currentLessonLog,
}) => {
  return (
    <div className="card-style card-course-exam">
      <h4 className="font_24 font-extrabold">{examTitle}</h4>

      {currentLessonLog?.progress == 100 ? (
        <p>لقد اتممت هذا الامتحان بنجاح</p>
      ) : (
        <>
          <ul className="d-flex mt-4">
            {duration && (
              <li className="d-flex me-5 align-items-center">
                <i className="fas fa-clock font-color-red font_20"></i>
                <div className="ms-2">
                  <small className="font_12 font-color-grey">
                    مدة الاختبار
                  </small>
                  <p className="font_18 font-extrabold mb-0">{duration}</p>
                </div>
              </li>
            )}
            <li className="d-flex ms-5 align-items-center">
              <i className="fas fa-question-circle font-color-red font_20"></i>
              <div className="ms-2">
                <small className="font_12 font-color-grey">عدد الاسئلة</small>
                <p className="font_18 font-extrabold mb-0">
                  {numberOfQuestion} سؤال
                </p>
              </div>
            </li>
          </ul>

          <div className="card-course-exam__list">
            <h6 className="font_16 font-extrabold font-color-grey card-course-exam__title">
              شروط الاختبار
            </h6>
            <ul>
              <li className="font_14 font-color-grey card-course-exam__list__item">
                هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة
              </li>
              <li className="font_14 font-color-grey card-course-exam__list__item">
                المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل
                الخارجي للنص
              </li>
              <li className="font_14 font-color-grey card-course-exam__list__item">
                المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل
                الخارجي للنص
              </li>
              <li className="font_14 font-color-grey card-course-exam__list__item">
                هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة
              </li>
            </ul>
          </div>

          <button
            className="btn btn-main-color card-course-exam__action"
            onClick={handleStartingExam}
          >
            ابدأ الاختبار
            <span className="fas fa-arrow-right fa-fw ms-2"></span>
          </button>
        </>
      )}
    </div>
  );
};

export default StartCourseExamCard;
