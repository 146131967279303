import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../components/Tables/Pagination";
import InputSearch from "../../components/inputs/InputSearch";
import SelectWithIcon from "../../components/inputs/SelectWithIcon";
import AdminLearningPathCard from "../../components/cards/AdminLearningPathCard";
import AddEmployeeToLearningTrackModal from "../../components/Modals/AddEmployeeToLearningTrackModal";
import { useLazyListAdminLearningTracksQuery } from "../../services/organization/adminLearningTrack";
import LoadingIndicator from "../../components/LoadingIndicator";
import EmptyState from "../../components/EmptyState";
import Icon from "../../components/icons/Icon";
import { LearningPathsStrokeIcon } from "../../components/icons/Icons";
import { useTranslation } from "react-i18next";

export default function LearningPaths() {
  const [page, setPage] = useState(1);
  const [modalTrack, setModalTrack] = useState();
  const [learningTracksTitleFilter, setLearningTracksTitleFilter] =
    useState("");
  const [sortKeyword, setSortKeyword] = useState("-created_at");
  const [filters, setFilters] = useState(["sort=-created_at"]);

  const { t } = useTranslation(["common", "learningPaths"]);
  const [
    listTracks,
    { data: tracksList, isLoading: isListLearningTracksLoading },
  ] = useLazyListAdminLearningTracksQuery();

  useEffect(() => {
    listTracks({
      filters: filters.join("&"),
      page,
    });
  }, []);

  useEffect(() => {
    let result = [];
    if (learningTracksTitleFilter?.length > 1) {
      result.push(`filters[keyword]=${learningTracksTitleFilter.trim()}`);
    }
    if (sortKeyword) result.push(`sort=${sortKeyword}`);
    setFilters(result);
  }, [learningTracksTitleFilter, sortKeyword]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  function updateLearningTracksTitleFilter(e) {
    setLearningTracksTitleFilter(e.target.value.trim());
    setPage(1);
  }

  function isSearching() {
    return learningTracksTitleFilter?.length > 1;
  }

  if (isListLearningTracksLoading) return <LoadingIndicator />;

  return (
    <div className="employee">
      <div className="sticky-header header-table padding-b-20">
        <div className="d-flex flex-wrap">
          <div className="left-side">
            <h1 className="title-page ">{t("learningPaths:learningTracks")}</h1>
            <ul className="d-flex">
              <li className="me-2">
                <span className="label label-black">
                  <span className="me-2">{tracksList?.pagination?.count}</span>
                  {t("learningPaths:learningTracks")}{" "}
                </span>
              </li>
            </ul>
          </div>

          <div className="ms-auto right-side">
            <ul className="list-filter">
              <li className="me-2">
                <InputSearch
                  placeHolder={t("learningPaths:searchLearningPath")}
                  onChange={updateLearningTracksTitleFilter}
                />
              </li>
              <li className="me-2">
                <SelectWithIcon
                  value={sortKeyword}
                  onChange={(e) => {
                    setPage(1);
                    setSortKeyword(e.target.value);
                  }}
                >
                  <option defaultValue value="">
                    {t("sorting.sortBy")}
                  </option>
                  <option value="-created_at">{t("sorting.newFirst")}</option>
                  <option value="created_at">{t("sorting.oldFirst")}</option>
                </SelectWithIcon>
              </li>

              <li>
                <Link to="/admin/learning_paths/new">
                  <button
                    type="button"
                    className="btn btn-main-color font-15"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <span className="fas fa-plus fa-fw me-2"></span>
                    {t("learningPaths:addNewPath")}
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center p-4">
          <div className="col-lg-12">
            <ul className="list-cards">
              {tracksList?.data.length > 0 ? (
                tracksList?.data?.map((track) => (
                  <li key={`track-${track.id}`}>
                    <AdminLearningPathCard
                      setModalTrack={setModalTrack}
                      track={track}
                      modalId="add-employee-learning-path"
                    />
                  </li>
                ))
              ) : (
                <EmptyState
                  iconComponent={
                    <Icon
                      icon={<LearningPathsStrokeIcon />}
                      hasStroke
                      iconName="learning-path-stroke"
                      iconColor="#bbbabf"
                      iconSize={38}
                      viewBox="0 0 23 19"
                    />
                  }
                  title={t("emptyStates.learningPaths")}
                  customTitle={
                    isSearching() ? t("learningPaths:noTracksFound") : null
                  }
                  emptyStateText={t("learningPaths:addTracks")}
                />
              )}
            </ul>
            {tracksList?.data?.length ? (
              <Pagination
                current={tracksList?.pagination?.current}
                pages={tracksList?.pagination?.pages}
                handlePagination={(page) => setPage(page)}
              />
            ) : null}
          </div>
        </div>
      </div>
      <AddEmployeeToLearningTrackModal
        id="add-employee-learning-path"
        learningTrack={modalTrack}
        isOpened={modalTrack != null}
        onClose={() => setModalTrack(null)}
      />
    </div>
  );
}
