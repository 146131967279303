import PropTypes from "prop-types";

const Icon = ({
  icon,
  iconName,
  className,
  iconSize,
  iconColor,
  style,
  height,
  opacity,
  hasStroke,
  viewBox,
}) => {
  const customHeight = height ? height : iconSize;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon-${iconName} ${className}`}
      width={iconSize + "px"}
      style={style}
      fill={!hasStroke ? iconColor : "none"}
      opacity={opacity}
      viewBox= {viewBox ? viewBox : "0 0 30 30" }
      stroke={hasStroke ? iconColor : "none"}
      preserveAspectRatio="xMidYMin slice"
    >
      {icon}
    </svg>
  );
};

export default Icon;

Icon.propTypes = {
  icon: PropTypes.node,
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  opacity: PropTypes.number,
  style: PropTypes.shape({}),
  height: PropTypes.number,
  stroke: PropTypes.string,
};

Icon.defaultProps = {
  iconSize: 20,
  iconColor: "#000",
  opacity: 1,
  style: null,
  height: null,
  className: "",
  stroke: "#000",
};
