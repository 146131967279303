import React, { Component } from "react";
import moment from "moment";
import { SwiperSlide } from "swiper/react";
import EmployeeScoreCard from "../../components/cards/EmployeeScoreCard";
import EmployeeWelcomeCard from "../../components/cards/EmployeeWelcomeCard";
import HomeStatsCard from "../../components/cards/HomeStatsCard";
import LearningPathCard from "../../components/cards/LearningPathCard";
import SwiperSlider from "../../components/SwiperSlider";
import { useGetDashboardStatsQuery } from "../../services/users/statistics";
import { useListTracksQuery } from "../../services/users/learningTrack";
import LoadingIndicator from "../../components/LoadingIndicator";
import useMyPoints from "../../app/hooks/useMyPoints";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ClockTimeFillIcon } from "../../components/icons/Icons";

const Home = () => {
  const { t } = useTranslation(["common"]);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userPoints = useMyPoints(0);

  const { data: dashboardStats, isLoading: isDashboardStatsLoading } =
    useGetDashboardStatsQuery();
  const { data: learningTracks, isLearningTracksLoading } = useListTracksQuery({
    page: 1,
  });

  if (isDashboardStatsLoading || isLearningTracksLoading)
    return <LoadingIndicator />;

  const renderPoints = () => userPoints || currentUser?.points;

  return (
    <div className="container-fluid">
      <div className="row justify-content-center p-4 slider-page">
        <div className="col-lg-10 col-md-12">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <EmployeeWelcomeCard />
            </div>
            <div className="col-lg-4 col-sm-12">
              <EmployeeScoreCard points={renderPoints()} />
            </div>
          </div>
          <ul className="row">
            <li className="col-lg-3 col-sm-6">
              <HomeStatsCard
                bgColor="rgba(157, 78, 221, 0.06)"
                img="graduation-cap-solid.svg"
                title={t("learningTracksCount")}
                num={dashboardStats?.data?.completed_learning_tracks_count}
                totalNum={dashboardStats?.data?.learning_tracks_count}
              />
            </li>
            <li className="col-lg-3 col-sm-6">
              <HomeStatsCard
                bgColor="rgba(241, 115, 0, 0.06)"
                img="courses.svg"
                title={t("coursesCount")}
                num={dashboardStats?.data?.completed_courses_count}
                totalNum={dashboardStats?.data?.courses_count}
              />
            </li>
            <li className="col-lg-3 col-sm-6">
              <HomeStatsCard
                bgColor="rgba(1, 194, 153, 0.06)"
                img="pass.svg"
                title={t("succeedInExams")}
                num={dashboardStats?.data?.passed_quizes_count}
                totalNum={dashboardStats?.data?.total_quizes_count}
              />
            </li>
            <li className="col-lg-3 col-sm-6">
              <HomeStatsCard
                bgColor="rgba(91, 192, 235, 0.06)"
                iconComponent={<ClockTimeFillIcon />}
                iconColor="#5bc0eb"
                title={t("watchingHours")}
                num={dashboardStats?.data?.watching_hours}
                singleNumber
                text={t("hoursRoughly")}
              />
            </li>
          </ul>
          {learningTracks?.data?.length > 0 && (
            <div className="row">
              <SwiperSlider
                sliderName="learining-path-slider"
                title={
                  learningTracks?.pagination?.count + " " + t("learningPaths")
                }
                text={t("latestSubscription")}
                showAllNavigationUrl={"/employee/learning_paths"}
                hasShowMore
              >
                {learningTracks?.data?.map((learningTrack) => {
                  let ProgressBarPercentage = `${learningTrack?.enrollment?.progress}%`;
                  let completeDate = moment(
                    new Date(learningTrack?.enrollment?.complete_by)
                  );
                  let oneDay = 24 * 60 * 60 * 1000;
                  return (
                    <SwiperSlide
                      key={learningTrack?.id}
                      style={{
                        cursor: learningTrack?.archived ? "auto" : "pointer",
                      }}
                    >
                      <LearningPathCard
                        id={learningTrack?.id}
                        title={learningTrack?.title}
                        image={learningTrack?.image_url}
                        ProgressBarPercentage={ProgressBarPercentage}
                        ProgressBarWidth="100%"
                        coursesCounts={learningTrack?.courses_count}
                        completedCoursesCount={
                          learningTrack?.enrollment?.completed_courses_count
                        }
                        completeBy={completeDate.format("DD / MM / YYYY")}
                        remainingDays={Math.round(
                          Math.abs((completeDate - new Date()) / oneDay)
                        )}
                        isArchived={learningTrack?.archived}
                      />
                    </SwiperSlide>
                  );
                })}
              </SwiperSlider>
            </div>
          )}
          {/* <div className="row">
            <SwiperSlider
              sliderName="learining-path-slider"
              title="الدروس القادمة"
            >
              <SwiperSlide>
                <LessonCard title="أفضل طرق تنظيم واسغلال الوقت" />
              </SwiperSlide>
              <SwiperSlide>
                <LessonCard title="أفضل طرق تنظيم واسغلال الوقت" />
              </SwiperSlide>
            </SwiperSlider>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
