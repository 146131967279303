import React, { useState, useEffect } from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import LearningPathCard from "../../components/cards/LearningPathCard";
import { useListTracksQuery } from "../../services/users/learningTrack";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";

export default function Learningpaths() {
  const [allLearningTracks, setAllLearningTracks] = useState([]);
  const [page, setPage] = useState(1);
  const { t } = useTranslation(["common", "learningPaths"]);
  const { data: learningTracks, isLoading: isLearningTracksLoading } =
    useListTracksQuery({ page });

  useEffect(() => {
    if (learningTracks?.data?.length) {
      if (learningTracks?.pagination?.current == 1) {
        setAllLearningTracks(learningTracks?.data);
      } else {
        setAllLearningTracks((prev) => {
          return [...prev, ...learningTracks?.data];
        });
      }
    }
  }, [learningTracks]);

  if (isLearningTracksLoading) return <LoadingIndicator />;

  return (
    <div className="container-fluid">
      <div className="row justify-content-center p-4">
        <div className="col-lg-8 col-md-12">
          <h1 className="mb-5 font_20 head-text-title">
            <span className="font-extrabold me-2">
              {learningTracks?.pagination?.count} {t("learningPaths")}
            </span>{" "}
            <span>{t("latestSubscription")}</span>
          </h1>

          <ul className="list-cards">
            <InfiniteScroll
              className="feed-list"
              dataLength={allLearningTracks.length}
              next={() => setPage(learningTracks?.pagination?.next)}
              hasMore={learningTracks?.pagination?.next}
              loader={<h4>{t("Loading")}</h4>}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>{t("successfulLearningPathDisplay")}</b>
                </p>
              }
              style={{
                overflow: "unset",
              }}
            >
              {allLearningTracks?.map((learningTrack) => {
                let ProgressBarPercentage = `${learningTrack?.enrollment?.progress}%`;
                let completeDate = moment(
                  new Date(learningTrack?.enrollment?.complete_by)
                );
                let oneDay = 24 * 60 * 60 * 1000;
                return (
                  <li className="mb-4">
                    <LearningPathCard
                      id={learningTrack?.id}
                      title={learningTrack?.title}
                      image={learningTrack?.image_url}
                      ProgressBarPercentage={ProgressBarPercentage}
                      coursesCounts={learningTrack?.courses_count}
                      completedCoursesCount={
                        learningTrack?.enrollment?.completed_courses_count
                      }
                      completeBy={completeDate.format("DD / MM / YYYY")}
                      remainingDays={Math.round(
                        (completeDate - new Date()) / oneDay
                      )}
                      learningTrackPageUrl={
                        learningTrack?.archived
                          ? "#"
                          : `/employee/learning_paths/${learningTrack?.id}`
                      }
                      buttonText={
                        learningTrack?.archived
                          ? t("learningPaths:deletedTrack")
                          : learningTrack?.enrollment?.progress
                          ? t("learningPaths:continueLearning")
                          : t("learningPaths:startLearning")
                      }
                      buttonType={
                        learningTrack?.enrollment?.progress
                          ? "inprogress"
                          : "start"
                      }
                      buttonIcon={
                        !learningTrack?.archived &&
                        learningTrack?.enrollment?.progress !== 100
                      }
                      isArchived={learningTrack?.archived}
                    />
                  </li>
                );
              })}
            </InfiniteScroll>
          </ul>
        </div>
      </div>
    </div>
  );
}
