
import React from 'react'

export default function leaderboard() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="24.5" viewBox="0 0 16.5 24.5">
    <g id="leaderboard" transform="translate(-3.75 0.25)">
      <circle id="Ellipse_485" data-name="Ellipse 485" cx="7" cy="7" r="7" transform="translate(5 1)" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
      <path id="Path_34330" data-name="Path 34330" d="M8.21,13.89,7,23l5-3,5,3-1.21-9.12" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
    </g>
  </svg>
  )
}
