import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWebSocket } from "../contexts/SocketContext";
import { setUserPoints } from "../store/reducers/points";

const channel = "EmployeeChannel";

function useMyPoints(initialPointsVal) {
  const dispatch = useDispatch();
  const ReduxPoints = useSelector((state) => state.points.points);
  const { connect, disconnect, subscribe } = useWebSocket();
  const [points, setPoints] = useState(initialPointsVal);

  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_SOCKET_URL}/api/v1/cable?token=${token}`;
  useEffect(() => {
    connect(url).then(() => {
      subscribe(channel, (message) => {
        setPoints(message.payload.points_count);
        dispatch(setUserPoints(message.payload.points_count));
      });
    });
    return () => {
      disconnect();
      setPoints(0);
    };
  }, []);

  return ReduxPoints || points;
}

export default useMyPoints;
