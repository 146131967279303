import Avatar from "../inputs/Avatar";

const DiscoverCourseCard = ({
  imageUrl,
  cousreName,
  courseTitle,
  courseTag,
  color,
  avatarUrl,
  userName,
}) => {
  return (
    <>
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 discover-card-col ">
        <div className="card card-style h-100 border-0 p-0 discover-card">
          {courseTag && (
            <>
              <span
                className="course-label mb-1"
                style={{ backgroundColor: `${color}` }}
              >
                {courseTag.title}
              </span>
            </>
          )}
          <img
            src={imageUrl}
            className="card-img-top discover-card__image"
            alt={cousreName}
          />
          <div className="card-body">
            <div className="d-flex">
              <Avatar
                avatarUrl={avatarUrl}
                width={40}
                height={40}
                className="me-2"
              />
              <div>
                <h5 className="card-title font_16 font-extrabold mb-0">
                  {courseTitle}
                </h5>
                <p className="font_13 font-color-grey">{userName}</p>
              </div>
            </div>

            {/* <div className="d-flex discover-card__action">
              <button className="btn btn-main-color font-15 flex-grow-1 me-2">
                <i className="fas fa-plus me-2"></i>
                <span className="font-extrabold">إضافة إلى مسار</span>
              </button>

              <button className="btn btn-red-light-color font-15">
                <i className="fas fa-user-plus fa-fw"></i>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverCourseCard;
