import React from "react";

const InputField = ({
  name,
  label,
  type,
  disabled,
  marginBottom,
  value,
  onChange,
  error,
  touched,
  placeholder
}) => {
  return (
    <div className="input-text">
      <div
        className=""
      >
        <input
          name={name}
          className="did-floating-input"
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        {error && touched ? <div className="input-error">{error}</div> : null}
      </div>
    </div>
  );
}

export default InputField
