import React, { useState } from "react";
import InputSearch from "../../components/inputs/InputSearch";
import TableLeaderBoard from "../../components/Tables/TableLeaderBoard";
import { useGetUserLeaderboardQuery } from "../../services/users/leaderboard";
import Pagination from "../../components/Tables/Pagination";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";

const LeaderBoard = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);
  let { data: usersList, isLoading: isLeaderboardLoading } =
    useGetUserLeaderboardQuery({
      page: page,
      keyword: searchKeyword,
    });
  const { t } = useTranslation(["common", "leaderboard"]);
  if (isLeaderboardLoading) return <LoadingIndicator />;

  return (
    <div className="container-fluid">
      <div className="row justify-content-center p-4 ranks">
        <div className="col-lg-10 col-md-12">
          <div className="d-flex flex-wrap">
            <div className="mb-4">
              <h1 className="mb-0 font_24 font-extrabold ">
                {t("leaderboard:leaderboard")}
              </h1>
              <p className="mb-0 font_16 font-color-grey ">
                {t("leaderboard:leaderboardDescription")}{" "}
              </p>
            </div>
            <div className="ms-auto mb-4 search-part ">
              <InputSearch
                placeHolder={t("employeeSearch")}
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                  setPage(1);
                }}
              />
            </div>
          </div>
          <div className="table-white">
            <TableLeaderBoard usersList={usersList} />
            {usersList?.data?.length !== 0 ? (
              <Pagination
                current={usersList?.pagination?.current}
                pages={usersList?.pagination?.pages}
                handlePagination={(page) => setPage(page)}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeaderBoard;
