import React from "react";
import InputSelectFloat from "../../inputs/InputSelectFloat";
import { useListDepartmentsQuery } from "../../../services/public/departments";
import { useUpdateUserMutation } from "../../../services/organization/adminUsers";
import { useTranslation } from "react-i18next";

export default function About({ user }) {
  const { data: departmentsList } = useListDepartmentsQuery();
  const [updateUser] = useUpdateUserMutation();

  const { t } = useTranslation(["common", "employees"]);

  return (
    <div className="profile-about">
      <div className="row">
        <div className="col-lg-3 col-sm-12">
          <InputSelectFloat
            label={t("employeeDepartment")}
            type="text"
            value=""
            onChange={(e) => {
              updateUser({
                userId: user?.id,
                updates: { department_id: e.target.value },
              });
            }}
          >
            <option defaultValue>{user?.department?.name}</option>
            {departmentsList?.data?.map(
              (department) =>
                department?.id != user?.department?.id && (
                  <option value={department?.id}>{department?.name}</option>
                )
            )}
          </InputSelectFloat>
        </div>

        <h1 className="font_20 font-extrabold mb-3">
          {t("employees:noteAboutEmployee")}
        </h1>
        <p className="font_14 font-color-grey mb-5">
          {user?.bio ? user?.bio : t("notExist")}
        </p>

        <h1 className="font_20 font-extrabold mb-3">{t("theSkills")}</h1>
        <div className="d-flex flex-wrap align-center mb-5">
          {user?.skills?.length > 0 ? (
            user?.skills?.map((skill) => (
              <span className="label label-grey me-3 mb-2">{skill}</span>
            ))
          ) : (
            <p className="font_14 font-color-grey mb-5">{t("notExist")}</p>
          )}
        </div>

        <h1 className="font_20 font-extrabold mb-3">
          {t("employees:personalInfo")}
        </h1>
        <div className="col-lg-2 col-sm-3 mb-3">
          <h2 className="font_14 font-bold font-color-grey mb-0">
            {t("mobileNumber")}
          </h2>
          <a
            dir="ltr"
            href="tel:985 4542 5692"
            className="font-16 font-extrabold"
          >
            {user?.phone ? user?.phone : t("notExist")}
          </a>
        </div>
        <div className="col-lg-2 col-sm-3 mb-3">
          <h2 className="font_14 font-bold font-color-grey mb-0">
            {t("theCountry")}
          </h2>
          <p className="font-16 font-extrabold">
            {/* <span className="fi fi-kw fis me-2"></span> */}
            {user?.country?.name ? user?.country?.name : t("notExist")}
          </p>
        </div>
        <div className="col-lg-2 col-sm-3 mb-3">
          <h2 className="font_14 font-bold font-color-grey mb-0">
            {t("theCity")}
          </h2>
          <p className="font-16 font-extrabold">
            {user?.city?.name ? user?.city?.name : t("notExist")}
          </p>
        </div>
      </div>
    </div>
  );
}
