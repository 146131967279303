import React, { useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Avatar from "../inputs/Avatar";
import Switch from "../inputs/Switch";
import { useUpdateUserMutation } from "../../services/organization/adminUsers";
import { getAccountStatus } from "../../utils/helpers";
import EmptyState from "../EmptyState";
import Icon from "../icons/Icon";
import { UsersStrokeIcon } from "../icons/Icons";
import { useTranslation } from "react-i18next";

const TableEmployee = ({ usersList }) => {
  const [updateUser] = useUpdateUserMutation();

  const { t } = useTranslation(["common", "employees"]);

  return (
    <Table className="table fixed-header">
      <Thead className="table-header">
        <Tr>
          <Th>{t("employeeName")}</Th>
          <Th>{t("inputs.email")}</Th>

          <Th>{t("accountStatus")}</Th>
          <Th>{t("employeeDepartment")}</Th>

          <Th>{t("lastActivity")}</Th>
          <Th className="text-center">{t("actions")}</Th>
        </Tr>
      </Thead>
      <Tbody className="table__content">
        {usersList?.data?.length ? (
          usersList.data.map((element, key) => {
            let accountStatus = getAccountStatus(
              element?.registered,
              element?.active,
              t
            );
            return (
              <>
                <Tr key={key}>
                  <Td>
                    <div className="item">
                      <Link
                        to={`/admin/employee/${element?.id}`}
                        className="me-2"
                      >
                        <Avatar
                          width="40"
                          height="40"
                          avatarUrl={element?.avatar_url}
                        />
                      </Link>
                      <Link
                        to={`/admin/employee/${element?.id}`}
                        className="bold font_14 font-extrabold "
                      >
                        {element?.name}
                      </Link>
                    </div>
                  </Td>
                  <Td>
                    <div className="item">
                      <p className="mb-0 font_14 font-color-grey">
                        {element?.email}
                      </p>
                    </div>
                  </Td>
                  <Td>
                    <div className="item d-flex align-center state-green">
                      <span
                        className="fas fa-circle fa-fw font_10 me-2"
                        style={{ color: accountStatus?.color }}
                      ></span>
                      <p
                        className="font_14 mb-0"
                        style={{ color: accountStatus?.color }}
                      >
                        {accountStatus?.name}
                      </p>
                    </div>
                  </Td>
                  <Td>
                    <div className="item">
                      <p className="mb-0 font_14 ">
                        {element?.department?.name
                          ? element?.department?.name
                          : "_"}
                      </p>
                    </div>
                  </Td>
                  <Td>
                    {" "}
                    <div className="item">
                      <p className="mb-0 font_14 ">
                        {element?.last_activity
                          ? moment(element?.last_activity).fromNow()
                          : "_"}
                      </p>
                    </div>
                  </Td>
                  <Td>
                    <div className="item text-center">
                      <div className="dropdown tools">
                        <button
                          className=" dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="fas fa-ellipsis-h fa-fw font_14 font-color-grey"></span>
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <div>
                              <p className="bold mb-0 font_12 font-extrabold">
                                {element?.name}
                              </p>
                              <p className="mb-0 font_12 font-color-grey">
                                {element?.email}
                              </p>
                            </div>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li>
                            <Link
                              className="dropdown-item font_14 font-bold"
                              to={`/admin/employee/${element?.id}`}
                            >
                              <span className="far fa-eye fa-fw font-color-grey me-2"></span>
                              <span className=" ">{t("seeAccount")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item font_14 font-bold"
                              to="#"
                            >
                              <span className="far fa-comment fa-fw font-color-grey me-2"></span>

                              <span className=" ">{t("sendMessage")}</span>
                            </Link>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>

                          <li>
                            <Switch
                              label={t("accountStatus")}
                              disabled={accountStatus.type == "notResigtered"}
                              checked={accountStatus.type == "active"}
                              onChange={() =>
                                updateUser({
                                  userId: element?.id,
                                  updates: {
                                    active: !(accountStatus.type == "active"),
                                  },
                                })
                              }
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Td>
                </Tr>
              </>
            );
          })
        ) : (
          <tr>
            <td className="border-bottom-0" colSpan={6}>
              <EmptyState
                iconComponent={
                  <Icon
                    icon={<UsersStrokeIcon />}
                    iconName="users"
                    iconSize={38}
                    viewBox="0 0 25 25"
                    iconColor="#bbbabf"
                    hasStroke
                  />
                }
                title={t("emptyStates.employees")}
                emptyStateText={t("employees:addEmployeeToAppear")}
              />
            </td>
          </tr>
        )}
      </Tbody>
    </Table>
  );
};

export default TableEmployee;
