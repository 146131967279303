import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import PasswordInputFloat from "../../components/inputs/PasswordInputFloat";
import {
  useSendUpdatedPasswordMutation,
  useVerifyResetPasswordTokenQuery,
} from "../../services/users/auth";
import {
  useAdminSendUpdatedPasswordMutation,
  useAdminVerifyResetPasswordTokenQuery,
} from "../../services/organization/auth";
import { useEffect } from "react";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(3, "كلمة المرور يجب ان تكون مكونة من 3 حروف كحد ادني")
    .required("كلمة المرور مطلوبة"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "الكلمتان غير متطابقتان")
    .required("اعادة كلمة المرور مطلوبة"),
});

const EmployeeResetPassword = ({ isAdmin }) => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [isValidToken, setIsValidToken] = useState(false);
  const [isError, setIsError] = useState(false);

  const { data: isEmployeeValidToken, isEmployeeError } =
    useVerifyResetPasswordTokenQuery({
      token,
    });

  const { data: isAdminValidToken, isAdminError } =
    useAdminVerifyResetPasswordTokenQuery({
      token,
    });

  const [updatePassword] = useSendUpdatedPasswordMutation();
  const [updateAdminPassword] = useAdminSendUpdatedPasswordMutation();

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      let result;
      if (isAdmin) {
        result = await updateAdminPassword({
          token: token,
          password: values.password,
        });
      } else {
        result = await updatePassword({
          token: token,
          password: values.password,
        });
      }

      if (result?.error) {
        toast.error(result?.error?.data?.message);
      } else {
        toast.success("تم التعديل");
        if (isAdmin) {
          navigate("/login/admin");
        } else {
          navigate("/login");
        }
      }
    },
  });

  useEffect(() => {
    if (isAdmin) {
      setIsError(isAdminError);
      setIsValidToken(isAdminValidToken);
    } else {
      setIsError(isEmployeeError);
      setIsValidToken(isEmployeeValidToken);
    }
  }, []);

  useEffect(() => {
    if (isError && !isValidToken) {
      toast.error("هذا الرابط لم يعد متاح");
      if (isAdmin) {
        navigate("/admin/login");
      } else {
        navigate("/login");
      }
    }
  }, [isError]);

  return (
    <div className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-6 login-page__banner"
            style={{
              backgroundImage: `url('/images/admin-bg.jpg')`,
            }}
          >
            <div className="content-admin">
              <h1>
                تعلم كل يوم
                <span>درس جديد...</span>
              </h1>
              <p>استثمر وقتك في تعلم دورات أون لاين</p>
            </div>
          </div>
          <div className="col">
            <div className="login-page__content">
              <div className="content-header">
                <h1 className="font-extrabold">اعادة تعيين كلمة المرور</h1>
              </div>
              <form
                className="content-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <PasswordInputFloat
                  label="كلمة المرور"
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                />

                <PasswordInputFloat
                  label="كلمة المرور"
                  type="password"
                  name="passwordConfirmation"
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  error={formik.errors.passwordConfirmation}
                  touched={formik.touched.passwordConfirmation}
                />

                <button
                  type="submit"
                  className="btn btn-main-color d-block w-100"
                >
                  حفظ
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeResetPassword;
