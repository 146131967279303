import React from "react";

export default function InputFieldFloat({
  name,
  label,
  type,
  disabled,
  marginBottom,
  value,
  onChange,
  error,
  touched,
  containerClassName,
  direction,
}) {
  return (
    <div className="input-text">
      <div
        className={`did-floating-label-content ${
          containerClassName ? containerClassName : ""
        }`}
        style={{ marginBottom: marginBottom ? marginBottom + "px" : "27px" }}
      >
        <input
          name={name}
          className="did-floating-input"
          type={type}
          placeholder=" "
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={{ direction: direction }}
        />
        <label className="did-floating-label">{label}</label>
        {error && touched ? <div className="input-error">{error}</div> : null}
      </div>
    </div>
  );
}
