import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import AddEmployeeToLearningTrackModal from "../../components/Modals/AddEmployeeToLearningTrackModal";
import { useGetAdminLearningTrackByIdQuery } from "../../services/organization/adminLearningTrack";
import Courses from "../../components/LearningTracks/Admin/Details/Tabs/Courses";
import Details from "../../components/LearningTracks/Admin/Details/Tabs/Details";
import Employees from "../../components/LearningTracks/Admin/Details/Tabs/Employees";
import Quizzes from "../../components/LearningTracks/Admin/Details/Tabs/Quizzes";
import TabsNav from "../../components/tabs/Tabs";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { useUpdateLearningTrackMutation } from "../../services/organization/adminLearningTrack";
import NotFoundPage from "../404";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";

const learningTrackFakeApi = {
  quizzes: [
    {
      id: 1,
      title: "ما هي أفضل طرق التسويق الفعالة للشركات الصغيرة؟",
      course: "استغلال أوقات الفراغ",
      time: "05:49",
      lesson: "الدرس الأول - المقدمة",
      answers: [
        {
          id: 1,
          title: "التسويق عن طريق البريد",
          isCorrect: false,
        },
        {
          id: 2,
          title: "التسويق عن طريق الشراكات التجاري",
          isCorrect: true,
        },
        {
          id: 3,
          title: "التسويق عن طريق إعلانات جوجل",
          isCorrect: false,
        },
        {
          id: 4,
          title: "جميع ما سبق",
          isCorrect: false,
        },
      ],
    },
    {
      id: 2,
      title: "هل تنظيم الوقت من المبادىء العشرة لإدارة الذات؟",
      course: "المبادىء العشرة لإدارة الذات",
      time: "05:00",
      lesson: "الدرس الأول - المقدمة",
      answers: [
        {
          id: 1,
          title: "نعم",
          isCorrect: false,
        },
        {
          id: 2,
          title: "لا",
          isCorrect: true,
        },
      ],
    },
    {
      id: 3,
      title: "ما هي أفضل طرق التسويق الفعالة للشركات المتوسطة و الكبرى؟",
      course: "استغلال أوقات الفراغ",
      time: "05:49",
      lesson: "الدرس الثالث - تنظيم الوقت",
      answers: [
        {
          id: 1,
          title: "التسويق عن طريق البريد",
          isCorrect: false,
        },
        {
          id: 2,
          title: "التسويق عن طريق الشراكات التجاري",
          isCorrect: false,
        },
        {
          id: 3,
          title: "التسويق عن طريق إعلانات جوجل",
          isCorrect: true,
        },
      ],
    },
    {
      id: 4,
      title: "هل تنظيم الوقت من المبادىء العشرة لإدارة الذات؟",
      course: "المبادىء العشرة لإدارة الذات",
      time: "05:49",
      lesson: "الدرس الأول - المقدمة",
      answers: [
        {
          id: 1,
          title: "نعم",
          isCorrect: true,
        },
        {
          id: 2,
          title: "لا",
          isCorrect: false,
        },
      ],
    },
  ],
};

const TABS = {
  DETAILS: 1,
  COURSES: 2,
  QUIZZES: 3,
  EMPLOYEES: 4,
};

export default function AdminLearningPathDetails() {
  const { learningPathId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const { data: learningTrack, isLoading: isLearningTrackLoading } =
    useGetAdminLearningTrackByIdQuery(learningPathId);

  const [updateLearningTrack] = useUpdateLearningTrackMutation();
  let navigate = useNavigate();

  const { t } = useTranslation("learningPaths", "learningPaths");

  if (isLearningTrackLoading) return <LoadingIndicator />;

  if (!learningTrack) {
    return <NotFoundPage />;
  }

  return (
    <div className="employee">
      <AddEmployeeToLearningTrackModal
        id="add-employee-learning-path"
        learningTrack={learningTrack.data}
        isOpened={showModal}
        onClose={() => setShowModal(false)}
      />

      <ConfirmationModal
        confirmationMessage={t("learningPaths:confirmLearningTrackDelete")}
        handleConfirmation={async () => {
          await updateLearningTrack({
            learningTrackId: learningPathId,
            body: {
              archived: true,
            },
          });
          setIsOpen(false);
          navigate("/admin/learning_paths");
        }}
        isOpened={isOpen}
        onClose={() => setIsOpen((status) => !status)}
      >
        <p className="text-danger">- {t("learningPaths:actionIrreversible")}</p>
        <p className="text-danger">- {t("learningPaths:willNotAppear")}</p>
        <p className="text-danger">- {t("learningPaths:cannotAccessTrack")}</p>
      </ConfirmationModal>

      <div className="sticky-header header-table no-padding-b">
        <div className="d-flex align-center flex-wrap">
          <div className="left-side">
            <h1 className="title-page mt-1">{learningTrack.data.title}</h1>
          </div>

          {!learningTrack?.data?.archived && (
            <div className="ms-auto right-side">
              <ul className="list-filter">
                <li className="me-2">
                  <button
                    type="button"
                    className="btn btn-main-color font-15"
                    data-bs-toggle="modal"
                    data-bs-target="#add-employee-learning-path"
                    onClick={() => setShowModal(true)}
                  >
                    <span className="fas fa-share me-2"></span>
                    {t("learningPaths:inviteEmployee")}
                  </button>
                </li>
                <li className="me-2">
                  <Link to={`/admin/learning_paths/${learningPathId}/edit`}>
                    <button
                      type="button"
                      className="btn btn-grey-color font-15"
                    >
                      <span className="fas fa-edit fa-lg fa-fw me-2"></span>
                      {t("learningPaths:EditPath")}
                    </button>
                  </Link>
                </li>
                <li>
                  <div className="dots-tools ms-2">
                    <div className="dropdown tools">
                      <button
                        type="button"
                        className="btn btn-grey-color font-15 h-100"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="fas fa-ellipsis-h fa-fw font_14 font-color-grey"></span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <div>
                            <button
                              className="bold mb-0 font_12 font-extrabold"
                              data-bs-toggle="modal"
                              data-bs-target="#confirmation-modal"
                            >
                              {t("learningPaths:deleteTrack")}
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>

        <TabsNav
          tabsConfig={[
            {
              name: "home",
              label: t("learningPaths:pathInfo"),
              clickHandler: () => setCurrentTab(TABS.DETAILS),
            },

            {
              name: "profile",
              label: t("learningPaths:pathCourse"),
              clickHandler: () => setCurrentTab(TABS.COURSES),
            },
            {
              name: "quizzes",
              label: t("learningPaths:QuestionsAndExams"),
              clickHandler: () => setCurrentTab(TABS.QUIZZES),
            },
            {
              name: "employees",
              label: t("learningPaths:enrolledEmployees"),
              clickHandler: () => setCurrentTab(TABS.EMPLOYEES),
            },
          ]}
        />
      </div>

      <div className="tab-content" id="nav-tabContent">
        {currentTab === TABS.DETAILS && (
          <Details learningTrack={learningTrack.data} />
        )}
        {currentTab === TABS.COURSES && (
          <Courses learningTrack={learningTrack.data} />
        )}
        {currentTab === TABS.QUIZZES && (
          <Quizzes learningTrack={learningTrackFakeApi} />
        )}
        {currentTab === TABS.EMPLOYEES && (
          <Employees learningTrack={learningTrack.data} />
        )}
      </div>
    </div>
  );
}
