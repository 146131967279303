import React from "react";
import Icon from "../icons/Icon";
import PropTypes from "prop-types";

const HomeStatsCard = ({
  img,
  title,
  num,
  totalNum,
  bgColor,
  text,
  singleNumber,
  iconComponent,
  iconColor,
  titleClassName,
  titleFontSize,
  numFontSize
}) => {
  return (
    <div
      className="card-style home-stats-card"
      style={{ backgroundColor: bgColor }}
    >
      {img && <img className="mb-2" alt="" src={`/images/icons/${img}`} /> }
      {iconComponent && <Icon icon={iconComponent} iconName="icon" iconSize={30} iconColor={iconColor} className="mb-2" /> }

      <h1 className={`font_${titleFontSize} font-color-grey mb-0 ${titleClassName}`}>{title}</h1>
      <p className={`font_${numFontSize} font-extrabold`}>
        {" "}
        <span>{num}</span>
        {!singleNumber ? (
          <span className="font_18 font-color-grey"> /{totalNum}</span>
        ) : null}
        {text && <span className="font_18 me-1">{text}</span>}{" "}
      </p>
    </div>
  );
};

export default HomeStatsCard;

HomeStatsCard.prototype = {
  titleClassName: PropTypes.string,
  titleFontSize: PropTypes.number
}

HomeStatsCard.defaultProps = {
  titleClassName: "",
  titleFontSize: 14,
  numFontSize: 31
}
