import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSendResetPasswordEmailMutation } from "../../services/users/auth";
import { useAdminResetPasswordEmailMutation } from "../../services/organization/auth";
import InputFieldFloat from "../../components/inputs/InputFieldFloat";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("من فضلك ادخل بريد الكتروني صحيح")
    .required("البريد الالكتروني مطلوب"),
});

const EmployeeResetPasswordViaEmail = ({ isAdmin }) => {
  const [cookies] = useCookies(["cookie-name"]);
  const [sendResetEmail] = useSendResetPasswordEmailMutation();
  const [AdminSendResetEmail] = useAdminResetPasswordEmailMutation();

  let navigate = useNavigate();

  const currentOrganization = useSelector(
    (state) => state.auth.currentOrganization
  );

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      let result;
      if (isAdmin) {
        result = await AdminSendResetEmail({
          email: values.email.toLowerCase(),
          organization_domain: currentOrganization?.domain || cookies?.domain,
        });
      } else {
        result = await sendResetEmail({
          email: values.email.toLowerCase(),
          organization_domain: currentOrganization?.domain || cookies?.domain,
        });
      }
      if (result?.error) {
        toast.error(result?.error?.data?.message);
      } else {
        toast.success("تم ارسال رسالة لبريدك");
        if (isAdmin) {
          navigate("/login/admin");
        } else {
          navigate("/login");
        }
      }
    },
  });

  return (
    <div className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-6 login-page__banner"
            style={{
              backgroundImage: `url('/images/admin-bg.jpg')`,
            }}
          >
            <div className="content-admin">
              <h1>
                تعلم كل يوم
                <span>درس جديد...</span>
              </h1>
              <p>استثمر وقتك في تعلم دورات أون لاين</p>
            </div>
          </div>
          <div className="col">
            <div className="login-page__content">
              <div className="content-header">
                <h1 className="font-extrabold">اعادة تعيين كلمة المرور</h1>
              </div>
              <form
                className="content-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <InputFieldFloat
                  marginBottom="0"
                  label="البريد الالكتروني"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                />

                <button
                  type="submit"
                  className="btn btn-main-color d-block w-100 mt-4"
                >
                  ارسال
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeResetPasswordViaEmail;
