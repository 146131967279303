import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Avatar from "./inputs/Avatar";
import { setCurrentUser } from "../app/store/reducers/authSlice";
import { useLogoutMutation } from "../services/users/auth";
import { useAdminLogoutMutation } from "../services/organization/auth";
import { useTranslation } from "react-i18next";

export default function SideBarMobile({ items, additionalItems, isAdmin }) {
  const { i18n } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);

  const [adminLogout] = useAdminLogoutMutation();
  const [logout] = useLogoutMutation();

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    const result = isAdmin ? await adminLogout() : await logout();
    if (!result.error || result?.error?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("is_admin");
      dispatch(setCurrentUser(null));
      isAdmin ? navigate("/login/admin") : navigate("/login");
    } else {
      toast.error(result?.error?.data?.message);
    }
  };


  return (
    <div className={`col-lg-3 col-md-12 sidebar-main sidebar-mobile`}>
      <button
        className="sidebar-main-toggle"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#sideBar"
        aria-controls="sideBar"
      >
        <div className={`hamburger`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </button>
      <div
        className={`offcanvas offcanvas-${i18n.language === 'ar' ? 'end' : 'start'}`}
        tabIndex="-1"
        id="sideBar"
        aria-labelledby="sideBarLabel"
      >
        <div className="offcanvas-header">
          <button type="button" className="btn-close opacity-100" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body sidebar-main__content sidebar-mobile__content p-0">
          <ul>
            <li>
              <div className="sidebar-profile-details">
                <Avatar width="40" height="40" avatarUrl={currentUser?.avatar_url} />

                <p className="font_16 font-bold m-0">{currentUser?.admin?.name}</p>
                <p className="font_16 font-color-grey m-0">{currentUser?.email || currentUser?.admin?.email}</p>
                <p className="font_16 font-extrabold m-0">{currentUser?.name}</p>
              </div>
            </li>
          </ul>
          <ul>
            {items.map((item, key) => (
              <li key={key} className={`${item.active ? "active" : ""}`}>
                <Link to={item.link}>
                  {item.icon}
                  <p>{item.text}</p>
                </Link>
              </li>
            ))}
          </ul>
          {additionalItems?.length ? (
            <ul>
              {additionalItems.map((item, key) => (
                <li key={key} className={`${item.active ? "active" : ""}`}>
                  <Link to={item.link}>
                    {item.icon}
                    <p>{item.text}</p>
                  </Link>
                </li>
              ))}
            </ul>
          ) : null}
          <ul className="border-bottom-0">
            <li>
              <a onClick={handleLogout} style={{ cursor: "pointer" }}>
                <span className="icon fas fa-sign-out-alt fa-fw"></span>
                <p>تسجيل الخروج</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
