import React from "react";

export default function Avatar({ width, height, avatarUrl, className }) {
  return (
    <div className={`upload-profile d-inline-block ${className}`}>
      <div
        className="upload-profile__container"
        style={{ width: `${width}px`, height: `${height}px` }}
      >
        {avatarUrl ? (
          <img className="img" alt="" src={avatarUrl} />
        ) : (
          <img className="img" alt="" src="/images/profile.png" />
        )}
      </div>
    </div>
  );
}
