import { api } from "../index";

export const auth = api.injectEndpoints({
  endpoints: (builder) => ({
    adminLogin: builder.mutation({
      query: (body) => ({
        url: `/api/v1/organization/login`,
        method: "POST",
        body,
      }),
    }),

    adminLogout: builder.mutation({
      query: () => ({
        url: `/api/v1/organization/logout`,
        method: "DELETE",
      }),
    }),

    showAdminProfile: builder.query({
      query: () => `/api/v1/organization/profile`,
      providesTags: [{ type: "OrganizationInfo" }]
    }),

    adminRegister: builder.mutation({
      query: (body) => ({
        url: `/api/v1/organization/registrations`,
        method: "POST",
        body,
      }),
    }),

    adminShowToken: builder.query({
      query: (token) => `/api/v1/organization/registrations/${token}`,
    }),
    adminResetPasswordEmail: builder.mutation({
      query: (body) => {
        return {
          url: "/api/v1/organization/password",
          method: "POST",
          body,
        };
      },
    }),

    adminVerifyResetPasswordToken: builder.query({
      query: ({ token }) => `/api/v1/organization/password/verify_token?token=${token}`,
    }),

    adminSendUpdatedPassword: builder.mutation({
      query: (body) => ({
        url: "/api/v1/organization/password",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useAdminLoginMutation,
  useAdminLogoutMutation,
  useShowAdminProfileQuery,
  useAdminRegisterMutation,
  useAdminShowTokenQuery,
  useAdminResetPasswordEmailMutation, useAdminVerifyResetPasswordTokenQuery, useAdminSendUpdatedPasswordMutation
} = auth;
