import React, { useState } from "react";
import { CongratulationIcon } from "../components/icons/Icons";
import AnswersQuestionModal from "../components/Modals/AnswersQuestionModal";
import Modal from "../components/Modals/Modal";
import PassedTestModal from "../components/Modals/PassedTestModal";
import State from "../components/State";

const ModalsGuide = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="login-page">
      <div className="m-5">
        <button
          data-bs-toggle="modal"
          data-bs-target="#PassedTestModal"
          className="btn btn-main-color font-15 me-3"
          style={{ maxWidth: "240px" }}
          onClick={() => setShowModal(true)}
        >
          إنهاء الاختبار ( الاختبار بنجاح)
        </button>
        <PassedTestModal id="PassedTestModal" isOpened={showModal} />
      </div>

      <div className="m-5">
        <button
          data-bs-toggle="modal"
          data-bs-target="#AnswersQuestionModal"
          className="btn btn-main-color font-15 me-3"
          style={{ maxWidth: "340px" }}
          onClick={() => setShowModal(true)}
        >
          Create Questions (ignored now)
        </button>
        <AnswersQuestionModal id="AnswersQuestionModal" isOpened={showModal} />
      </div>
      <div className="m-5">
        <button
          data-bs-toggle="modal"
          data-bs-target="#successModal"
          className="btn btn-main-color font-15 me-3"
          style={{ maxWidth: "240px" }}
          onClick={() => setShowModal(true)}
        >
          success Modal
        </button>
        <Modal
          id="successModal"
          mainBtn="موافق"
          mainBtnOnclick={() => setShowModal(false)}
          className=""
          maxWidth="500"
        >
          <State
            titleFontSize={24}
            descFontSize={16}
            className="passed-test"
            id="success-test"
            hasIconStroke
            type="success"
            title="تم إرسال الدعوة بنجاح"
            desc="لقد تم إرسال الدعوة إلى البريد الإلكتروني الخاص بالموظف"
          ></State>
        </Modal>
      </div>

      <div className="m-5">
        <button
          data-bs-toggle="modal"
          data-bs-target="#failModal"
          className="btn btn-main-color font-15 me-3"
          style={{ maxWidth: "240px" }}
          onClick={() => setShowModal(true)}
        >
          fail Modal
        </button>
        <Modal
          id="failModal"
          mainBtn="موافق"
          mainBtnOnclick={() => setShowModal(false)}
          className=""
          maxWidth="500"
        >
          <State
            titleFontSize={24}
            descFontSize={16}
            className="passed-test"
            hasIconStroke
            type="fail"
            title="فشل"
            desc="فشل"
          ></State>
        </Modal>
      </div>
      <div className="m-5">
        <button
          data-bs-toggle="modal"
          data-bs-target="#congratsCertificate"
          className="btn btn-main-color font-15 me-3"
          style={{ maxWidth: "240px" }}
          onClick={() => setShowModal(true)}
        >
          congrats Certificate
        </button>
        <Modal id="congratsCertificate" className="" maxWidth="1000">
          <div className="row mb-4 ">
            <div className="col-lg-6 col-sm-12 mt-sm-3">
              <img
                src="/images/Cretifacte.png"
                alt=""
                style={{ maxWidth: "100%" }}
              />
            </div>
            <div className="col-lg-6 col-sm-12 mt-sm-3">
              <State
                hasIconStroke
                color="#af151f"
                iconSize="50"
                icon={<CongratulationIcon />}
              >
                <p className="font_15 font-color-grey mb-1">
                  شهادة اتمام الدورة
                </p>
                <h1 className="font_24 font-extrabold">
                  مبرووووك يا عبدالكريم
                </h1>
                <p className="font_14 font-color-grey mb-0">
                  لقد فعلتها ! لقد نجحت في إتمام الدورة بنجاح
                </p>
                <p className="font_14 font-color-grey">
                  يمكنك الآن تحميل الشهادة ومشاركتها مع أصدقائك
                </p>
                <button
                  className="btn btn-main-color font-15 mb-3"
                  style={{ width: "100%" }}
                >
                  <img
                    className="me-2"
                    alt=""
                    src="/images/downloadFile.svg"
                    style={{ filter: "brightness(0) invert(1)" }}
                    width="20px"
                  />
                  تحميل الشهادة
                </button>
                <button
                  className="btn btn-grey-color font-15 "
                  style={{ width: "100%" }}
                >
                  <i className="fas fa-share fa-fw me-2"></i>
                  مشاركة الشهادة على المنتدى
                </button>
              </State>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ModalsGuide;
