import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import ProgressBar from "../../components/ProgressBar";
import { useTranslation } from "react-i18next";
export default function LearningPathCard(props) {
  const {
    title,
    image,
    id,
    ProgressBarPercentage,
    ProgressBarWidth,
    coursesCounts,
    completedCoursesCount,
    subscribedAt,
    completeBy,
    remainingDays,
    learningTrackPageUrl,
    buttonText,
    buttonType,
    buttonIcon,
    leftSideContent,
    backGroundColor,
    boxShadow,
    isAdmin,
    isArchived,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "learningPaths"]);

  function openDetails(e) {
    if (!isArchived)
      navigate(
        isAdmin
          ? `/admin/learning_paths/${id}`
          : `/employee/learning_paths/${id}`
      );
  }
  return (
    <div
      onClick={openDetails}
      className="card-style learinig-path-card"
      style={{
        backgroundColor: backGroundColor,
        boxShadow: boxShadow,
        opacity: isArchived ? "0.5" : "1",
      }}
    >
      <div className="learinig-path-card__img">
        <img className="" alt="" src={image} />
      </div>

      <div className="learinig-path-card__content">
        <h2 className="card-title">{title}</h2>
        <ProgressBar
          width={ProgressBarWidth}
          percentageText={ProgressBarPercentage}
        />
        <p className="card-detail">
          <span className="me-2 font-bold">
            {completedCoursesCount ? completedCoursesCount : 0}{" "}
            {t("completedCoursesCount")}
          </span>
          <span>
            {t("learningPaths:totalCoursesCount", { number: coursesCounts })}
          </span>
        </p>
        {completeBy && remainingDays && !leftSideContent ? (
          <p className="card-date">
            {t("learningPaths:learningPathsDeadline", { date: completeBy })}

            {remainingDays > 0
              ? t("learningPaths:remainingTime", { days: remainingDays })
              : t("learningPaths:deadlinePassed", {
                  lateDays: Math.abs(remainingDays),
                })}
          </p>
        ) : null}
      </div>

      {learningTrackPageUrl && (
        <div className="learinig-path-card__btn">
          <Link
            className={`btn btn-course ${buttonType}-course`}
            to={learningTrackPageUrl}
          >
            {buttonText}
            {buttonIcon && (
              <span className="fas fa-arrow-right fa-fw ms-2"></span>
            )}
          </Link>
        </div>
      )}
      {leftSideContent && (
        <div className="me-auto ms-auto  d-flex flex-wrap align-center">
          <div>
            <p className="font_14 font-color-grey font-bold mb-0">
              {t("learningPaths:subscribedAt")} {subscribedAt}
            </p>
            <p className="font_12 font-color-grey">
              {t("learningPaths:learningPathsDeadline", { date: completeBy })}{" "}
              {remainingDays > 0
                ? t("learningPaths:remainingTime", { days: remainingDays })
                : t("learningPaths:deadlinePassed", {
                    lateDays: Math.abs(remainingDays),
                  })}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
