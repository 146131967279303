import { api } from "../index";

export const statistics = api.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardStats: builder.query({
      query: () => `/api/v1/user/stats/dashboard`,
    }),
  }),
});

export const { useGetDashboardStatsQuery } = statistics;
