import React, { useState } from "react";
import PassedTestModal from "../../Modals/PassedTestModal";
import ChoiceQuestion from "./ChoiceQuestion";
import ExamProgressFloat from "./ExamProgressFloat";

const CourseExamContent = ({
  questions,
  createLessonLog,
  currentLessonId,
  refetch,
  setCurrentLesson,
  getLesson,
}) => {
  const [myAnswers, setMyAnswers] = useState([]);
  const [correctAnswersCount, setCorrectAnswersCount] = useState();
  const [wrongAnswersCount, setWrongAnswersCount] = useState();

  const handleSubmittingExam = async () => {
    const result = await createLessonLog({
      lesson_id: currentLessonId,
      answers: myAnswers,
    });
    if (!result.error) {
      let correctCount = 0;
      let wrongCount = 0;
      result?.data?.data?.answers?.map((answer) => {
        answer?.correct ? ++correctCount : ++wrongCount;
      });
      setCorrectAnswersCount(correctCount);
      setWrongAnswersCount(wrongCount);
      // refetch();
      // setIsExamStart(false);
    }
  };

  return (
    <div className="content-course-exam">
      <ExamProgressFloat
        answeredQuestionNumber={myAnswers.length}
        totalQuestionNumber={questions.length}
      />
      <p className="font-color-grey font_20 mb-4">
        <span className="font-extrabold">{questions?.length}</span> سؤال{" "}
      </p>
      {questions?.map((question, i) => {
        const handleChangingAnswer = (e) => {
          let selectedAnswer = {
            id: question?.id,
            answer: e.target.value,
            answerd_at: new Date(),
          };
          const filteredAnswers = myAnswers.filter(
            (answer) => answer?.id != selectedAnswer?.id
          );
          setMyAnswers((prev) => [...filteredAnswers, selectedAnswer]);
        };

        return (
          <div className="card-style mb-4">
            <h1 className="font_16 font-color-grey">السؤال {++i}</h1>
            <ChoiceQuestion
              raidoName={question?.id}
              title={question?.question}
              answers={question?.answers}
              handleChangingAnswer={handleChangingAnswer}
            />
          </div>
        );
      })}

      <div className="card-style mb-4">
        <h1 className="font_20 font-color-grey mb-4">
          هل انتهيت من إجابة الاسئلة وتريد إنهاء الاختبار؟
        </h1>

        <button
          data-bs-toggle="modal"
          data-bs-target="#passed-test"
          className="btn btn-main-color font-15 me-3"
          style={{ maxWidth: "240px" }}
          onClick={handleSubmittingExam}
          disabled={myAnswers?.length != questions?.length}
        >
          إنهاء الاختبار
        </button>
      </div>
      <PassedTestModal
        id="passed-test"
        correctAnswersCount={correctAnswersCount}
        wrongAnswersCount={wrongAnswersCount}
        onClick={async () => {
          window.location.reload(false);
          // const lesson = await getLesson(currentLessonId);
          // setCurrentLesson(lesson);
        }}
      />
    </div>
  );
};

export default CourseExamContent;
