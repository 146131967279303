import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute, ProtectedLogin, RouteToHome } from "./auth";

//login
import AdminLogin from "../pages/AdminLogin";
import EmployeeLogin from "../pages/EmployeeLogin";
import EmployeeInvitation from "../pages/EmployeeInvitation";
import AdminInvitation from "../pages/AdminInvitation";
import NotFound from "../pages/404";

// layouts
import AdminLayout from "../layout/AdminLayout";
import EmployeeLayout from "../layout/EmployeeLayout";

//admin
import AdminHome from "../pages/admin/Home";
import AdminEmployee from "../pages/admin/Employees";
import AdminLearningPaths from "../pages/admin/LearningPaths";
import AdminLearningPathDetails from "../pages/admin/LearningPathDetails";
import CreateLearningPath from "../pages/admin/CreateLearningPath";
import EditLearningPath from "../pages/admin/EditLearningPath";
import AdminLeaderBoard from "../pages/admin/LeaderBoard";
import AdminEmployeeProfile from "../pages/admin/EmployeeProfile";
import Departments from "../pages/admin/Settings";
import AdminQuestionsAnswers from "../pages/admin/QuestionsAnswers";
import Reports from "../pages/admin/Reports";
// employee
import EmployeeHome from "../pages/employee/Home";
import EmployeeCommunity from "../pages/employee/Community";
import EmployeeLeaderBoard from "../pages/employee/LeaderBoard";
import LearningPaths from "../pages/employee/LearningPaths";
import LearningPathDetails from "../pages/employee/LearningPathDetails";
import CoursePage from "../pages/employee/CoursePage";
import EditProfile from "../pages/employee/EditProfile";
import Notification from "../pages/Notification";
import SinglePost from "../pages/employee/SinglePost";
import MyProfile from "../pages/employee/MyProfile";
import UserProfile from "../pages/employee/UserProfile";
import DiscoverCourses from "../pages/admin/DiscoverCourses";
import ChangePassword from "../pages/employee/ChangePassword";
import EmployeeResetPassword from "../pages/employee/EmployeeResetPassword";
import EmployeeResetPasswordViaEmail from "../pages/employee/EmployeeResetPasswordViaEmail";
// modals guide
import ModalsGuide from "../pages/ModalsGuide";
const SystemRoutes = () => {
  return (
    <BrowserRouter>
      {/* {routeToHome()} */}
      <div id="maincontent" className="main">
        <Routes>
          <Route path="/">
            <Route path="/" element={<RouteToHome />}></Route>
          </Route>
          {/* Page all  modals style guide  */}
          {/* Must be deleted after use  */}
          <Route path="/">
            <Route element={<AdminLayout />}>
              <Route path="/modals_guide" element={<ModalsGuide />} />
            </Route>
          </Route>
          {/* End test  */}
          <Route path="/" element={<ProtectedLogin />}>
            <Route
              path="/reset-password/email-confirmation"
              element={<EmployeeResetPasswordViaEmail isAdmin={false} />}
            />
            <Route path="/reset-password" element={<EmployeeResetPassword isAdmin={false} />} />
          </Route>
          <Route path="/login" element={<ProtectedLogin />}>
            <Route path="/login" element={<EmployeeLogin />} />
          </Route>
          <Route path="/login/admin" element={<ProtectedLogin />}>
            <Route path="/login/admin" element={<AdminLogin />} />
          </Route>
          <Route path="/invitation" element={<ProtectedLogin />}>
            <Route path="/invitation" element={<EmployeeInvitation />} />
          </Route>
          <Route path="/admin/invitation" element={<ProtectedLogin />}>
            <Route path="/admin/invitation" element={<AdminInvitation />} />
          </Route>
          <Route path="/" element={<ProtectedRoute requireAdminAccess />}>
            <Route element={<AdminLayout activeSidebar="home" />}>
              {/* <Route path="/" element={<AdminHome />} /> */}
              <Route path="/admin" element={<AdminHome />} />
            </Route>
          </Route>
          <Route path="/" element={<ProtectedLogin />}>
            <Route
              path="/admin/reset-password/email-confirmation"
              element={<EmployeeResetPasswordViaEmail isAdmin={true} />}
            />
            <Route path="/admin/reset-password" element={<EmployeeResetPassword isAdmin={true} />} />

          </Route>
          <Route path="/" element={<ProtectedRoute requireAdminAccess />}>
            <Route element={<AdminLayout activeSidebar="leaderboard" />}>
              <Route path="/admin/leaderboard" element={<AdminLeaderBoard />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute requireAdminAccess />}>
            <Route element={<AdminLayout activeSidebar="employee" />}>
              <Route path="/admin/employee" element={<AdminEmployee />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute requireAdminAccess />}>
            <Route element={<AdminLayout />}>
              <Route
                path="/admin/employee/:employeeId"
                element={<AdminEmployeeProfile />}
              />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute requireAdminAccess />}>
            <Route element={<AdminLayout activeSidebar="" />}>
              <Route
                path="/admin/:employeeId/exams/:lessonLogId"
                element={<AdminQuestionsAnswers />}
              />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute requireAdminAccess />}>
            <Route element={<AdminLayout activeSidebar="reports" />}>
              <Route path="/admin/reports" element={<Reports />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute requireAdminAccess />}>
            <Route element={<AdminLayout activeSidebar="learning_paths" />}>
              <Route
                path="/admin/learning_paths"
                element={<AdminLearningPaths />}
              />
              <Route
                path="/admin/learning_paths/:learningPathId"
                element={<AdminLearningPathDetails />}
              />
              <Route
                path="/admin/learning_paths/:learningPathId/edit"
                element={<EditLearningPath />}
              />
              <Route
                path="/admin/learning_paths/details"
                element={<AdminLearningPathDetails />}
              />
              <Route
                path="/admin/learning_paths/new"
                element={<CreateLearningPath />}
              />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute requireAdminAccess />}>
            <Route element={<AdminLayout activeSidebar="settings" />}>
              <Route path="/admin/settings" element={<Departments />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute requireAdminAccess />}>
            <Route element={<AdminLayout activeSidebar="discover" />}>
              <Route
                path="/admin/discover-courses"
                element={<DiscoverCourses />}
              />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout active="home" />}>
              <Route path="/employee" element={<EmployeeHome />} />
            </Route>
          </Route>
          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout active="learning_paths" />}>
              <Route
                path="/employee/learning_paths"
                element={<LearningPaths />}
              />
            </Route>
          </Route>
          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout active="learning_paths" />}>
              <Route
                path="/employee/learning_paths/:id"
                element={<LearningPathDetails />}
              />
            </Route>
            <Route element={<EmployeeLayout active="community" />}>
              <Route
                path="/employee/community"
                element={<EmployeeCommunity />}
              />
            </Route>
            <Route element={<EmployeeLayout active="community" />}>
              <Route path="/employee/community/:id" element={<SinglePost />} />
            </Route>
          </Route>
          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout active="leaderboard" />}>
              <Route
                path="/employee/leaderboard"
                element={<EmployeeLeaderBoard />}
              />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout active="profile" />}>
              <Route path="/employee/profile" element={<MyProfile />} />
              <Route
                path="/employee/profile/:userID"
                element={<UserProfile />}
              />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout active="learning_paths" />}>
              <Route
                path="/employee/learning_paths"
                element={<LearningPaths />}
              />
            </Route>
          </Route>
          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout active="learning_paths" />}>
              <Route
                path="/employee/learning_paths/:id"
                element={<LearningPathDetails />}
              />
            </Route>
          </Route>
          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout active="learning_paths" />}>
              <Route path="/employee/course/:id" element={<CoursePage />} />
            </Route>
          </Route>
          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout />}>
              <Route path="/employee/edit-profile" element={<EditProfile />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout />}>
              <Route
                path="/employee/change-password"
                element={<ChangePassword />}
              />
            </Route>
          </Route>

          {/* Notification */}
          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout />}>
              <Route path="/employee/notification" element={<Notification />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute requireAdminAccess />}>
            <Route element={<AdminLayout />}>
              <Route path="/admin/notification" element={<Notification />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute requireAdminAccess />}>
            <Route element={<AdminLayout />}>
              <Route
                path="/admin/discover-courses"
                element={<DiscoverCourses />}
              />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<AdminLayout activeSidebar={true} />}>
              <Route path="/admin/*" element={<NotFound />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<EmployeeLayout />}>
              <Route path="/employee/*" element={<NotFound />} />
            </Route>
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default SystemRoutes;
