import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "../../components/inputs/Avatar";
import { arabicMonthes } from "../../utils/arabicDatesUtilities.js";
import { useTranslation } from "react-i18next";
import { useUpdateLearningTrackMutation } from "../../services/organization/adminLearningTrack";
import ConfirmationModal from "../Modals/ConfirmationModal";

export default function AdminLearningPathCard({
  track,
  modalId,
  setModalTrack,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const [updateLearningTrack] = useUpdateLearningTrackMutation();

  const createdAt = new Date(track.created_at);
  const arabicMonth = arabicMonthes[createdAt.getMonth()];
  const { t } = useTranslation(["learningPaths"]);

  function moreThanTwoEnrollers() {
    return track.enrollments_count > 2;
  }

  function remainingEnrollersCount() {
    return track.enrollments_count - 2;
  }

  function openDetails(e) {
    navigate(`/admin/learning_paths/${track.id}`);
  }

  function openModal(e) {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    setModalTrack(track);
  }

  return (
    <div onClick={openDetails} className="card-style  admin-learinig-path-card">
      <ConfirmationModal
        confirmationMessage={t("learningPaths:confirmLearningTrackDelete")}
        handleConfirmation={async (e) => {
          e.stopPropagation();
          await updateLearningTrack({
            learningTrackId: track.id,
            body: {
              archived: true,
            },
          });
          setIsOpen(false);
        }}
        isOpened={isOpen}
        onClose={(e) => {
          e.stopPropagation();
          setIsOpen((status) => !status);
        }}
      >
        <p className="text-danger">- {t("learningPaths:actionIrreversible")}</p>
        <p className="text-danger">- {t("learningPaths:willNotAppear")}</p>
        <p className="text-danger">- {t("learningPaths:cannotAccessTrack")}</p>
      </ConfirmationModal>

      <div className="learinig-path-card__img">
        <img className="" alt="" src={track.image_url} />
      </div>

      <div className="admin-learinig-path-card__content w-100">
        <div
          className="d-flex align-center flex-wrap"
          style={{ marginTop: "10px" }}
        >
          <p
            className="card-title align-self-baseline"
            style={{ marginTop: "2px" }}
          >
            {track.title}
          </p>
          {!track?.archived ? (
            <ul className="list-filter ms-auto d-flex">
              <li className="me-2">
                <button
                  onClick={openModal}
                  type="button"
                  className="btn btn-grey-color font-15"
                  data-bs-toggle="modal"
                  data-bs-target={`#${modalId}`}
                >
                  <span className="fas fa-share fa-fw me-2"></span>
                  {t("learningPaths:inviteEmployee")}
                </button>
              </li>

              <li>
                <div className="dots-tools ms-2">
                  <div className="dropdown tools">
                    <button
                      type="button"
                      className="btn btn-grey-color font-15 h-100"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <span className="fas fa-ellipsis-h fa-fw font_14 font-color-grey"></span>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <div>
                          <button
                            className="bold mb-0 font_12 font-extrabold"
                            data-bs-toggle="modal"
                            data-bs-target="#confirmation-modal"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {t("learningPaths:deleteTrack")}
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          ) : (
            <ul className="list-filter ms-auto d-flex">
              <button type="button" className="btn btn-grey-color font-15">
                {t("learningPaths:deletedTrack")}
              </button>
            </ul>
          )}
        </div>

        <p className="card-detail">
          {" "}
          {t("learningPaths:courses", { courses_count: track.courses_count })}
        </p>

        <div
          className="d-flex align-center flex-wrap"
          style={{ marginTop: "17px", minHeight: "33px" }}
        >
          <div className="d-flex align-center flex-wrap">
            <ul className="avatar-group me-2">
              {track.users.map((user) => (
                <li key={`track-${track.id}-user-avatar-${user.id}`}>
                  <Avatar width="30" height="30" avatarUrl={user.avatar_url} />
                </li>
              ))}

              {moreThanTwoEnrollers() && (
                <li key={`track-${track.id}-users-number`}>
                  <div className="avatar-group-total" width="30" height="30">
                    +{remainingEnrollersCount()}
                  </div>
                </li>
              )}
            </ul>
            <p className="m-0 font_12 font-bold">
              {track.enrollments_count} {t("learningPaths:enrolledUsers")}{" "}
            </p>
          </div>

          <p className="card-date ms-auto align-self-end">
            {t("learningPaths:trackCreationDate")}{" "}
            {`${createdAt.getDate()} / ${
              createdAt.getMonth() + 1
            } / ${createdAt.getFullYear()}`}
          </p>
        </div>
      </div>
    </div>
  );
}
