import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "../../components/Modals/Modal";
import Avatar from "../../components/inputs/Avatar";
import { useCreatePostMutation } from "../../services/users/community";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";

const CreatePostModal = ({ listPosts, isVerticallyCentered }) => {
  const [postText, setPostText] = useState("");
  const [postImage, setPostImage] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  const currentUser = useSelector((state) => state.auth.currentUser);
  const [createPost, { isLoading }] = useCreatePostMutation();
  const { t } = useTranslation('communityFeed');

  const handleCreatePost = async () => {
    let dataForm = new FormData();
    dataForm.append("body", postText);
    if (postImage) dataForm.append("images[]", postImage);
    await createPost(dataForm);
    await listPosts(1);
    setIsOpen(false);
    setPostText("");
    setPostImage(null);
  };


  return (
    <Modal
      id="create-post-button"
      title={t('addPostToFeed')}
      maxWidth="550"
      className="create-post-button-modal"
      isOpened={isOpen}
      onClose={() => setIsOpen(status => !status)}
      isVerticallyCentered={isVerticallyCentered}
    >
      <div>
        <div className="d-flex flex-wrap align-center mb-2">
          <Avatar height="30" width="30" avatarUrl={currentUser?.avatar_url} />
          <div className="ms-2">
            <h1 className="mb-0 font_16 font-bold">{currentUser?.name}</h1>
          </div>
        </div>
        <textarea
          id=""
          name=""
          rows="2"
          className="text-area-no-style w-100 mt-1 mb-2"
          placeholder={t('communityFeed:writeWhatYouWant')}
          value={postText}
          onChange={(e) => setPostText(e.target.value)}
        />

        {postImage && (
          <div className="create-post-button-modal__uploaded">
            <div className="image-result">
              <button
                className=" delete-img-icon"
                onClick={() => setPostImage(null)}
              >
                <span className="far fa-trash-alt fa-fw font-color-grey"></span>
              </button>
              <img alt="" src={URL.createObjectURL(postImage)} />
            </div>
          </div>
        )}
        <div className="d-flex flex-wrap align-center">
          <label htmlFor="add-image" className="d-flex flex-wrap align-center">
            <img className="me-2" alt="" src="/images/icons/image-icon.svg" />
            <p
              className="mb-0 font_12 font-color-grey font-bold"
              style={{ cursor: "pointer" }}
            >
              {t('communityFeed:addMedia')}
            </p>
            <input
              accept="image/png, image/gif, image/jpeg"
              type="file"
              id="add-image"
              style={{ display: "none" }}
              onChange={(e) => setPostImage(e.target.files.item(0))}
              onClick={(e) => (e.target.value = null)}
            />
          </label>
          <div className="ms-auto">
            <button
              type="button"
              className="btn p-5 pb-3 pt-3 btn-main-color font-15"
              onClick={handleCreatePost}
              disabled={!postText && !postImage}
              data-bs-dismiss={!isOpen ? "modal" : "none"}
            >
              {isLoading ? (
                <Spinner style={{ width: "20px", height: "20px" }} />
              ) : (
                t('communityFeed:share')
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreatePostModal;
