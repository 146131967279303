import HomeIcon from "../components/icons/HomeIcon";
import Leaderboard from "../components/icons/leaderboard";
import LearningPaths from "../components/icons/learningPaths";
import Rreports from "../components/icons/reports";
import Settings from "../components/icons/settings";
import Users from "../components/icons/users";
import LeaderboardIcon from "../components/icons/leaderboard";
import LearningPathsIcon from "../components/icons/learningPaths";

export const getEmployeeNavbarItems = (active, t) => [
  {
    text: t("homepage"),
    link: "/employee",
    icon: <HomeIcon />,
    active: active == "home" ? true : false,
  },
  {
    text: t("profile:myLearningPaths"),
    link: "/employee/learning_paths",
    icon: <LearningPathsIcon />,
    active: active == "learning_paths" ? true : false,
  },
  {
    text: t("leaderboard:leaderboard"),
    link: "/employee/leaderboard",
    icon: <LeaderboardIcon />,
    active: active == "leaderboard" ? true : false,
  },
  {
    text: t("common:navbar.communityFeed"),
    link: "/employee/community",
    icon: <Users />,
    active: active == "community" ? true : false,
  },
];

export const getAdminNavbarItems = (active, t) => [
  {
    text: t("learningPaths:discoverCourses"),
    link: "/admin/discover-courses",
    icon: "",
    active: active == "discover" ? true : false,
  },
  {
    text: t("help"),
    link: "#",
    icon: "",
    active: active == "help" ? true : false,
  },
];

export const getAdminSidebarItems = (active, t) => [
  {
    text: t("home"),
    link: "/admin",
    icon: <HomeIcon />,
    active: active == "home" ? true : false,
  },
  {
    text: t("theEmployees"),
    link: "/admin/employee",
    icon: <Users />,
    active: active == "employee" ? true : false,
  },
  {
    text: t("leaderboard:leaderboard"),
    link: "/admin/leaderboard",
    icon: <Leaderboard />,
    active: active == "leaderboard" ? true : false,
  },
  {
    text: t("learningPaths:learningTracks"),
    link: "/admin/learning_paths",
    icon: <LearningPaths />,
    active: active == "learning_paths" ? true : false,
  },
  {
    text: t("reports"),
    link: "/admin/reports",
    icon: <Rreports />,
    active: active == "reports" ? true : false,
  },
  {
    text: t("settings"),
    link: "/admin/settings",
    icon: <Settings />,
    active: active == "settings" ? true : false,
  },
];
