import React from "react";
import InputSearch from "../../components/inputs/InputSearch";
import HonorBoardCard from "../../components/cards/HonorBoardCard";
import MyPostsStatisticsCard from "../../components/cards/MyPostsStatisticsCard";
import {
  useGetStatisticsQuery,
  useLazyListPostsQuery,
} from "../../services/users/community";
import { useGetUserLeaderboardQuery } from "../../services/users/leaderboard";
import CommunityFeed from "./CommunityFeed";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";

const Community = () => {
  const { data: statistics } = useGetStatisticsQuery();
  const [listPosts, { data: posts, isLoading: isPostsLoading }] =
    useLazyListPostsQuery();

  const { data: leaderboardUsers } = useGetUserLeaderboardQuery({
    page: 1,
    perPage: 3,
  });
  const { t } = useTranslation(["common", "communityFeed"]);

  if (isPostsLoading) return <LoadingIndicator />;

  return (
    <div className="container-fluid">
      <div className="row justify-content-center p-4 pb-0">
        <div className="col-lg-9 col-md-12">
          <div className="d-flex flex-wrap">
            <div className="mb-4">
              <h1 className="mb-0 font_24 font-extrabold ">{t("allPosts")}</h1>
              <p className="mb-0 font_16 font-color-grey ">
                {t("communityFeed:communityFeedDescription")}
              </p>
            </div>
            <div className="ms-auto mb-4  create-post-button">
              <button
                type="button"
                className="btn btn-main-color font-15"
                data-bs-toggle="modal"
                data-bs-target="#create-post-button"
              >
                {t("communityFeed:addPost")}
                <span className="fas fa-plus fa-fw ms-2"></span>
              </button>
            </div>
            <div className=" mb-4 search-part ">
              <InputSearch placeHolder={t("search")} />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center p-4 community-page pt-0">
        <div className="col-lg-6 col-md-12">
          <CommunityFeed listPosts={listPosts} posts={posts} />
        </div>
        <div className="col-lg-3 col-md-12">
          <ul>
            <li className="mb-3">
              <MyPostsStatisticsCard statistics={statistics?.data} />
            </li>
            <li className="mb-3">
              <HonorBoardCard
                leaderboardUsers={leaderboardUsers?.data}
                currentUserOrder={leaderboardUsers?.current_user_order}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Community;
