import React, { useEffect, useState, useRef } from "react";
import ChoiceQuestion from "./ChoiceQuestion";

export default function AboveCourseExamCard({
  currentInVideoQuestion,
  setIsAboveVideoExam,
  createLessonLog,
  currentLessonId,
  lessonLogs,
}) {
  const [isResult, setisResult] = useState(false);
  const [answerLocked, setAnswerLocked] = useState(false);
  const [rightAnswer, setRightAnswer] = useState("");
  // defult : grey color without result //check the design
  const [isResultSuccess, setisResultSuccess] = useState(false);
  const myAnswer = useRef();

  // console.log({ currentLessonId, lessonLogs });

  const handleSubmittingAnswer = async () => {
    const result = await createLessonLog({
      lesson_id: currentLessonId,
      answers: lessonLogs?.answers
        ? [
            ...lessonLogs.answers,
            { id: currentInVideoQuestion?.id, answer: myAnswer.current },
          ]
        : [{ id: currentInVideoQuestion?.id, answer: myAnswer.current }],
    });
    if (!result.error) {
      setAnswerLocked(true);
      let currentResultResponse = result?.data?.data?.answers?.find(
        (answer) => answer.id == currentInVideoQuestion?.id
      );
      console.log({ result, currentResultResponse });
      if (currentResultResponse?.correct) {
        setisResultSuccess(true);
        setisResult(true);
      } else {
        setisResultSuccess(false);
        setisResult(true);
        setRightAnswer(currentResultResponse?.correct_answer);
      }
    }
  };

  return (
    <div className="card-style above-course-exam-card">
      <div className="above-course-exam-card__content">
        <ChoiceQuestion
          title={currentInVideoQuestion?.question}
          resultType={isResultSuccess ? "success" : "fail"}
          raidoName="foo"
          answers={currentInVideoQuestion?.answers}
          handleChangingAnswer={(e) => (myAnswer.current = e.target.value)}
          disabled={answerLocked}
        />
      </div>
      <div className="above-course-exam-card__bottom">
        {isResult && (
          <div className="above-course-result me-auto">
            <span
              className={`icon me-2 ${isResultSuccess ? "success" : "fail"}`}
            >
              <span
                className={`${
                  isResultSuccess ? "fas fa-check fa-fw" : "fas fa-times fa-fw"
                }`}
              ></span>
            </span>
            <div>
              <p className="font_18 font-extrabold mb-0">
                {isResultSuccess ? "إجابة صحيحة" : "إجابة خاطئة"}
              </p>

              <p className="font_14 font-color-grey mb-0">
                {isResultSuccess
                  ? "أحسنت , لقد اختر الإجابة الصحيحة للسؤال"
                  : `الاجابة الصحيحة: ${rightAnswer}`}
              </p>
            </div>
          </div>
        )}

        <a
          className="btn btn-main-color font-15 me-3"
          style={{ maxWidth: "320px" }}
          onClick={
            !isResult
              ? handleSubmittingAnswer
              : () => setIsAboveVideoExam(false)
          }
        >
          {!isResult ? "التحقق من الإجابة" : "العودة إلى الدرس"}
        </a>
      </div>
    </div>
  );
}
