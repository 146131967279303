import React, { useState } from "react";

export default function InputFieldFloat({
  name,
  label,
  marginBottom,
  value,
  onChange,
  error,
  touched,
  Autocomplete = true,
}) {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <div className="input-text">
      <div
        className="did-floating-label-content"
        style={{ marginBottom: marginBottom + "px" }}
      >
        <input
          name={name}
          className="did-floating-input"
          type={passwordShown ? "text" : "password"}
          placeholder=" "
          value={value}
          onChange={onChange}
          Autocomplete={!Autocomplete ? "new-password" : ""}
        />
        <label className="did-floating-label">{label}</label>
        <button
          onClick={togglePasswordVisiblity}
          type="button"
          className="eye-password"
        >
          <span
            className={`fas ${passwordShown ? "fa-eye-slash" : "fa-eye"}`}
          ></span>
        </button>
        {error && touched ? <div className="input-error">{error}</div> : null}
      </div>
    </div>
  );
}
