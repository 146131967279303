import React from 'react'

export default function ProgressBar(props) {
  const {
    type,
    width,
    height,
    percentageText,
    fulldetails,
    subDetails,
    empty,
  } = props;
  return (
    <div className='progress-container'>
      {(subDetails || fulldetails ) && percentageText && 
      
              <p className='m-0 me-4 font-16 font-bold'> <span> {percentageText} </span>{fulldetails}
              {subDetails && <span className='sub-details'>{subDetails}</span> }             
              </p>
      }
       <div style={{width: width? width : '300px'}} className="progress">
        {/* inprogress or done */}
            <div className={`progress-bar  ${percentageText === '100%' ?'done' : 'inprogress'}`} role="progressbar" style={{width: `${percentageText}`}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
        </div>
        {percentageText && !fulldetails && 
                <p className='m-0 ms-2 font-14 font-bold font-color-grey'> <span>{percentageText}</span> </p>
        }

    </div>
  )
}
