import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoute = ({ requireAdminAccess }) => {
  const isAuthenticated = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("is_admin");
  if (!isAuthenticated) return <Navigate to="/login" />;
  if (!requireAdminAccess) return <Outlet />;
  return isAdmin == "true" ? <Outlet /> : <Navigate to="/login" />;
};

export const ProtectedLogin = () => {
  const isAuthenticated = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("is_admin");
  return isAuthenticated ? (
    isAdmin == "true" ? (
      <Navigate to="/admin" />
    ) : (
      <Navigate to="/employee" />
    )
  ) : (
    <Outlet />
  );
};

export const RouteToHome = () => {
  const isAuthenticated = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("is_admin");
  if (!isAuthenticated) return <Navigate to="/login" />;
  else if (isAdmin == "false") return <Navigate to="/employee" />;
  else return <Navigate to="/admin" />;
};
