import moment from "moment";

export const arabicMonthes = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export const displayDate = (dateTime) => {
  let createdAt = new Date(dateTime);
  return `${moment(createdAt).format(`DD MMMM YYYY , hh:mm a`)}`;
};
