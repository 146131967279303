import React, { Component, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import InputFieldFloat from "../components/inputs/InputFieldFloat";
import PasswordInputFloat from "../components/inputs/PasswordInputFloat";
import { useLoginMutation } from "../services/users/auth";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

const EmployeeLogin = () => {
  const [cookies] = useCookies(["cookie-name"]);
  const [login] = useLoginMutation();
  let navigate = useNavigate();

  const currentOrganization = useSelector(
    (state) => state.auth.currentOrganization
  );

  const { t } = useTranslation(["common", "auth"]);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("validations.emailWrong"))
      .required(t("validations.emailRequired")),
    password: yup
      .string()
      .min(3, t("validations.passwordMin3"))
      .required(t("validations.passwordRequired")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const result = await login({
        email: values.email.toLowerCase(),
        password: values.password,
        organization_domain: currentOrganization?.domain || cookies?.domain,
      });
      if (result?.error) {
        toast.error(result?.error?.data?.message);
      } else {
        localStorage.setItem("token", result?.data?.data?.token);
        localStorage.setItem("is_admin", false);
        navigate("/employee");
      }
    },
  });

  const redirectToResetViaEmailForm = () =>
    navigate("/reset-password/email-confirmation");

  return (
    <div className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-6 login-page__banner"
            style={{
              backgroundImage: `url('/images/admin-bg.jpg')`,
            }}
          >
            <div className="content-admin">
              <h1>
                {t("learnEveryday")}
                <span>{t("newLesson")}</span>
              </h1>
              <p>{t("investYourTime")}</p>
            </div>
          </div>
          <div className="col">
            <div className="login-page__content">
              <div className="content-header">
                <img
                  className="logo"
                  alt=""
                  src={currentOrganization?.orgLogo || cookies?.orgLogo}
                />
                <h1>{t("auth:login")}</h1>
                <p>{t("auth:welcomeAgain")}</p>
              </div>
              <form
                className="content-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <InputFieldFloat
                  label={t("inputs.email")}
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                />
                <PasswordInputFloat
                  label={t("inputs.password")}
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                />
                <button
                  className="btn btn-main-color d-block w-100"
                  type="submit"
                >
                  {t("auth:login")}
                </button>
                <button
                  className="reset-password text-link-red"
                  onClick={redirectToResetViaEmailForm}
                >
                  {t("auth:didYourForgetPassword")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeLogin;
