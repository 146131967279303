import React from "react";

const SelectWithIcon = ({ value, onChange, children }) => {
  return (
    <div className="select-with-icon">
      <label className="input-text" htmlFor="inputGroupSelect01">
        <span className="fas fa-list fa-fw"></span>
      </label>
      <span className="input-icon">
        <span className="fas fa-angle-down fa-fw"></span>
      </span>
      <select
        className="form-select"
        id="inputGroupSelect01"
        value={value}
        onChange={onChange}
      >
        {children}
      </select>
    </div>
  );
};

export default SelectWithIcon;
