import React from "react";

export default function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.5"
      height="22.5"
      viewBox="0 0 20.5 22.5"
      className="align-top"
    >
      <g id="home" transform="translate(-1.75 -0.75)">
        <path
          id="Path_34355"
          data-name="Path 34355"
          d="M3,9l9-7,9,7V20a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2Z"
          fill="none"
          stroke="#bbbabf"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
        <path
          id="Path_34356"
          data-name="Path 34356"
          d="M9,22V12h6V22"
          fill="none"
          stroke="#bbbabf"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
      </g>
    </svg>
  );
}
