import { api } from "../index";

export const adminStatistics = api.injectEndpoints({
  endpoints: (builder) => ({
    getAdminDashboardStats: builder.query({
      query: () => `/api/v1/organization/stats/dashboard`,
    }),
  }),
});

export const { useGetAdminDashboardStatsQuery } = adminStatistics;
