import React, { useState } from "react";
import Avatar from "../inputs/Avatar";
import {
  useLikeCommentMutation,
  useUnlikeCommentMutation,
} from "../../services/users/community";
import { arabicMonthes, displayDate } from "../../utils/arabicDatesUtilities";
import { useNavigate } from "react-router-dom";

const CommentUserCard = ({ comment }) => {
  const [isLike, setIsLike] = useState(false);

  const [like] = useLikeCommentMutation();
  const [unlike] = useUnlikeCommentMutation();

  let navigate = useNavigate();

  const navigateToUserprofile = () =>
    navigate(`/employee/profile/${comment.author.id}`);

  let createdAt = new Date(comment?.created_at);
  const arabicMonth = arabicMonthes[createdAt.getMonth()];
  return (
    <div className="comment-user">
      <Avatar width="40" height="40" avatarUrl={comment?.author?.avatar_url} />
      <div className="ms-2 w-100">
        <div className="d-flex flex-wrap align-center">
          <div className="d-flex flex-wrap align-center">
            <p
              role="button"
              onClick={navigateToUserprofile}
              className="mb-0 font_16 font-extrabold "
            >
              {comment?.author?.name}
            </p>
            <p className="mb-0 ms-2 font_14 font-color-grey-light">
              {displayDate(comment?.created_at)}
            </p>
          </div>
          <span className="mx-auto my-0"></span>
          <div className="ms-auto d-flex align-center">
            {" "}
            <span className="me-2 font_16 font-extrabold">
              {comment?.likes_count}
            </span>
            <button
              className={`${
                comment?.is_liked_by_me
                  ? "fas font-color-red"
                  : "far font-color-grey"
              } font_20 fa-heart fa-fw me-2`}
              onClick={() =>
                comment?.is_liked_by_me
                  ? unlike(comment?.id)
                  : like(comment?.id)
              }
            ></button>
          </div>
        </div>
        <p className="mb-0 font_16 mt-2 text-break">{comment?.body}</p>
      </div>
    </div>
  );
};

export default CommentUserCard;
