import React from "react";

const Radio = ({ children, id, checked, setChecked, className }) => {

  return (
    <div className={`form-check checkbox-main ${className}`}>
      <input
        type="radio"
        className="form-check-input form-check-input--radio"
        id={id}
        name="option1"
        value="something"
        defaultChecked={checked}
        onChange={() => setChecked(!checked)}
      />
      <label className="form-check-label" htmlFor={id}>
        {children}
      </label>
    </div>
  );
}

export default Radio
