import { api } from "../index";

export const countries = api.injectEndpoints({
  endpoints: (builder) => ({
    listComments: builder.query({
      query: ({ postId, page = 1, perPage = 3 }) =>
        `/api/v1/posts/${postId}/comments?page=${page}&per_page=${perPage}`,
    }),
  }),
});

export const { useLazyListCommentsQuery } = countries;
