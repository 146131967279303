import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  useAdminGetUserQuery,
  useUpdateUserMutation,
} from "../../services/organization/adminUsers";
import Avatar from "../../components/inputs/Avatar";
import Switch from "../../components/inputs/Switch";
import About from "../../components/Profile/Tabs/About";
import Cert from "../../components/Profile/Tabs/Cert";
import Exams from "../../components/Profile/Tabs/Exams";
import LearningLevel from "../../components/Profile/Tabs/LearningLevel";
import Posts from "../../components/Profile/Tabs/Posts";
import Reports from "../../components/Profile/Tabs/Reports";
import SocialMedia from "../../components/SocialMedia";
import { getAccountStatus } from "../../utils/helpers";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";
import NotFoundPage from "../404";

export default function AdminEmployeeProfile() {
  const [accountStatusType, setAccountStatusType] = useState();
  const [accountStatusName, setAccountStatusName] = useState();
  const [socialMediaLinksAvailable, SetSocialMediaLinksAvailable] =
    useState(false);

  const { t } = useTranslation([
    "common",
    "employees",
    "communityFeed",
    "learningPaths",
  ]);

  const { employeeId } = useParams();
  const { data: userData, isLoading: isGetUserLoading } =
    useAdminGetUserQuery(employeeId);
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (userData?.data) {
      let accountStatus = getAccountStatus(
        userData?.data?.registered,
        userData?.data?.active,
        t
      );
      setAccountStatusType(accountStatus?.type);
      setAccountStatusName(accountStatus?.name);
      SetSocialMediaLinksAvailable(
        userData?.data?.facebook ||
          userData?.data?.instagram ||
          userData?.data?.linkedin ||
          userData?.data?.twitter ||
          userData?.data?.snapchat
      );
    }
  }, [userData]);

  const TABS = {
    ABOUT: 1,
    REPOORTS: 2,
    LEARNINGLEVEL: 3,
    CERT: 4,
    POSTS: 5,
    EXAMS: 6,
  };

  const [currentTab, setCurrentTab] = useState(1);

  if (isGetUserLoading) return <LoadingIndicator />;

  if (!userData) {
    return <NotFoundPage />;
  }

  return (
    <div className="profile-page__employee ">
      <div className="sticky-header header-table ">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 row  align-center">
              <div className="col-lg-4 mb-1">
                <div className="d-flex flex-wrap align-center">
                  <Avatar
                    width="60"
                    height="60"
                    avatarUrl={userData?.data?.avatar_url}
                  />
                  <div className="ms-2">
                    <p className="font_18 font-extrabold  mb-0">
                      {userData?.data?.name}
                    </p>
                    <p className="font_14 font-color-grey mb-0">
                      {userData?.data?.department?.name}
                    </p>
                    <p className="font_12 font-color-grey mb-0">
                      {userData?.data?.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 mb-1">
                <h6 className="font_12 font-extrabold font-color-dark mb-0">
                  {t("socialMediaLinks")}
                </h6>
                {socialMediaLinksAvailable ? (
                  <SocialMedia
                    url={{
                      facebook: userData?.data?.facebook,
                      instagram: userData?.data?.instagram,
                      linkedin: userData?.data?.linkedin,
                      twitter: userData?.data?.twitter,
                      snapchat: userData?.data?.snapchat,
                    }}
                  />
                ) : (
                  <p className="font_12 font-color-grey-light mb-0">
                    {t("notExist")}
                  </p>
                )}
              </div>
              <div className="col-lg-3 align-center mb-1">
                <div className="">
                  <p className="font_12 font-color-grey mb-0">
                    {t("subscriptionDate")} 30 / 10 / 2022
                  </p>
                  <p className="font_12 font-color-grey-light mb-0">
                    {t("lastActivitySince")} 4 {t("days")}
                  </p>
                </div>
              </div>
              <div className="col-lg-2 mb-1">
                <div className="d-flex flex-wrap align-center">
                  <Switch
                    label={t(accountStatusName)}
                    disabled={accountStatusType == "notResigtered"}
                    checked={accountStatusType == "active"}
                    onChange={() =>
                      updateUser({
                        userId: userData?.data?.id,
                        updates: {
                          active: !(accountStatusType == "active"),
                        },
                      })
                    }
                  />
                  {/* <div className="item text-center dots-tools ms-auto">
                    <div className="dropdown tools">
                      <button
                        className=" dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="fas fa-ellipsis-h fa-fw font_14 font-color-grey"></span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link
                            className="dropdown-item font_14 font-bold"
                            to="#"
                          >
                            <span className="far fa-eye fa-fw font-color-grey me-2"></span>
                            <span className=" ">{t("seeAccount")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item font_14 font-bold"
                            to="#"
                          >
                            <span className="far fa-comment fa-fw font-color-grey me-2"></span>

                            <span className=" ">{t("sendMessage")}</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid nav-tabs-header">
        <div className="row justify-content-center ">
          <div className="col-lg-10 col-md-12">
            <div className="nav nav-tabs " id="nav-tab" role="tablist">
              <button
                className="nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
                onClick={() => setCurrentTab(TABS.ABOUT)}
              >
                {t("employees:aboutEmployee")}
              </button>
              <button
                className="nav-link "
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
                onClick={() => setCurrentTab(TABS.REPOORTS)}
              >
                {t("reports")}
              </button>
              <button
                className="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
                onClick={() => setCurrentTab(TABS.LEARNINGLEVEL)}
              >
                {t("learningPaths:learningLevel")}
              </button>
              <button
                className="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
                onClick={() => setCurrentTab(TABS.CERT)}
              >
                {t("certificates")}
              </button>

              <button
                className="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
                onClick={() => setCurrentTab(TABS.POSTS)}
              >
                {t("communityFeed:thePost")}
              </button>

              <button
                className="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
                onClick={() => setCurrentTab(TABS.EXAMS)}
              >
                {t("exams")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="tab-content" id="nav-tabContent">
        {currentTab === TABS.ABOUT && (
          <div className="container-fluid">
            <div className="row justify-content-center profile-page__employee__content">
              <div className="col-lg-10 col-md-12">
                <About user={userData?.data} />
              </div>
            </div>
          </div>
        )}
        {currentTab === TABS.REPOORTS && (
          <div className="container-fluid">
            <div className="row justify-content-center profile-page__employee__content">
              <div className="col-lg-10 col-md-12">
                <Reports user={userData?.data} />
              </div>
            </div>
          </div>
        )}
        {currentTab === TABS.LEARNINGLEVEL && (
          <div className="container-fluid">
            <div className="row justify-content-center profile-page__employee__content">
              <div className="col-lg-10 col-md-12">
                <LearningLevel user={userData?.data} />
              </div>
            </div>
          </div>
        )}
        {currentTab === TABS.CERT && (
          <div className="container-fluid">
            <div className="row justify-content-center profile-page__employee__content">
              <div className="col-lg-10 col-md-12">
                <Cert user={userData?.data} />
              </div>
            </div>
          </div>
        )}
        {currentTab === TABS.POSTS && (
          <div className="container-fluid">
            <div className="row justify-content-center profile-page__employee__content">
              <div className="col-lg-10 col-md-12">
                <Posts user={userData?.data} />
              </div>
            </div>
          </div>
        )}
        {currentTab === TABS.EXAMS && (
          <div className="container-fluid">
            <div className="row justify-content-center profile-page__employee__content">
              <div className="col-lg-10 col-md-12">
                <Exams user={userData?.data} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
