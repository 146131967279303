import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Details from "../../components/LearningTracks/Admin/MutationPages/Tabs/Details";
import Courses from "../../components/LearningTracks/Admin/MutationPages/Tabs/Courses";
import {
  useCreateNewLearningTrackMutation,
  useAddCourseToLearningTrackMutation,
  useUpdateLearningTrackMutation,
} from "../../services/organization/adminLearningTrack";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";

export default function AdminCreateLearningPath() {
  const TABS = {
    DETAILS: 1,
    COURSES: 2,
  };

  let navigate = useNavigate();
  const [isValidated, setIsValidated] = useState(false);
  const [submmiting, setSubmmiting] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TABS.DETAILS);
  const [createNewLearningTrack] = useCreateNewLearningTrackMutation();
  const [formData, setFormData] = useState(null);
  const [courses, setCourses] = useState([]);
  const [deletedCoursesIds, setDeltedCoursesIds] = useState([]);
  const [addCourseToLearningTrack] = useAddCourseToLearningTrackMutation();
  const [updateLearningTrack] = useUpdateLearningTrackMutation();
  const { t } = useTranslation(["common", "learningPaths"]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // listen  for change in isValidated state and submit if valid.
  useEffect(() => {
    async function submit() {
      try {
        setSubmmiting(true);
        const response = await createNewLearningTrack(formData);
        if (formData.image) {
          const data = new FormData();
          data.append("image", formData.image);
          await updateLearningTrack({
            learningTrackId: response.data.data.id,
            body: data,
          });
        }
        if (response.data) {
          Promise.allSettled(
            courses.map(
              async (course) =>
                await addCourseToLearningTrack({
                  learningTrackId: response.data.data.id,
                  courseId: course.id,
                })
            )
          );
        }
        navigate(`/admin/learning_paths`);
        if (response?.error) {
          toast.error(response?.error?.data?.message);
        } else {
          toast.success(t("successfulSave"));
        }
        setSubmmiting(false);
      } catch (e) {
        setSubmmiting(false);
        toast.error(e?.data?.message);
      }
    }
    if (!isValidated) return;
    submit();
  }, [isValidated]);

  async function onSubmit() {
    try {
      setSubmit(true);
      if (!isValidated) {
        return;
      }
      setSubmmiting(true);
      const response = await createNewLearningTrack(formData);
      if (formData.image) {
        const data = new FormData();
        data.append("image", formData.image);
        await updateLearningTrack({
          learningTrackId: response.data.data.id,
          body: data,
        });
      }
      const courseIds = courses.map((course) => course.id);

      if (response.data && courseIds.length) {
        addCourseToLearningTrack({
          learningTrackId: response.data.data.id,
          body: { course_ids: courseIds },
        });
      }

      navigate(`/admin/learning_paths`);
      if (response?.error) {
        toast.error(response?.error?.data?.message);
      } else {
        toast.success(t("successfulSave"));
      }
      setSubmmiting(false);
    } catch (e) {
      setSubmmiting(false);
      toast.error(e?.data?.message);
    }
  }
  return (
    <div>
      <div className="sticky-header header-table no-padding-b">
        <div className="d-flex align-center flex-wrap justify-content-between">
          <div className="left-side">
            <h1 className="title-page mt-1"> {t("learningPaths:addNewPath")}</h1>
          </div>

          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className="nav-link active p-3 font-extrabold"
              style={{ fontSize: "16px" }}
              id="learning-track-details-tab"
              data-bs-toggle="tab"
              data-bs-target="#learning-track-details"
              type="button"
              role="tab"
              aria-controls="learning-track-details"
              aria-selected="true"
              onClick={() => {
                setSelectedTab(TABS.DETAILS);
              }}
            >
              {t("learningPaths:pathDetails")}
            </button>
            <button
              className="nav-link p-3 font-extrabold"
              style={{ fontSize: "16px" }}
              id="learning-track-courses-tab"
              data-bs-toggle="tab"
              data-bs-target="#learning-track-courses"
              type="button"
              role="tab"
              aria-controls="learning-track-courses"
              aria-selected="false"
              onClick={() => {
                setSelectedTab(TABS.COURSES);
              }}
            >
              {t("learningPaths:pathCourses")}{" "}
            </button>
          </div>

          <div className="right-side">
            <ul className="list-filter">
              <li style={{ marginLeft: "16px" }}>
                <button type="button" className="btn btn-main-color font-15 min-w-140 mt-3 mt-md-0" onClick={onSubmit} disabled={submmiting}>
                  {t("learningPaths:savePath")}
                  {submmiting && (
                    <Spinner
                      style={{
                        marginRight: "10px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  )}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn btn-grey-color font-15 px-4 min-w-140 mt-3 mt-md-0"
                  onClick={() => navigate("/admin/learning_paths")}
                >
                  {t("cancel")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {selectedTab === TABS.DETAILS && (
        <Details submit={submit} setSubmit={setSubmit} setIsValidated={setIsValidated} formData={formData} setFormData={setFormData} />
      )}
      {selectedTab === TABS.COURSES && (
        <Courses courses={courses} setCourses={setCourses} deletedCoursesIds={deletedCoursesIds} setDeltedCoursesIds={setDeltedCoursesIds} />
      )}
    </div>
  );
}
