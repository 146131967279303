import React from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import LearningPathdetailsCard from "../../components/cards/LearningPathdetailsCard";
import HeaderPage from "../../components/HeaderPage";
import { useGetTrackQuery } from "../../services/users/learningTrack";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";
import NotFoundPage from "../404";

let courseStatuses = (progress, t) => [
  {
    name: "inprogress",
    buttonText: progress
      ? t("learningPaths:continueCourse")
      : t("learningPaths:startCourse"),
    buttonIcon: "fas fa-play-circle fa-fw me-3",
  },
  {
    name: "closed",
    buttonText: t("learningPaths:closedCourse"),
    buttonSubText: t("learningPaths:coursePrerequistes"),
    buttonIcon: "fas fa-lock fa-fw",
    btnDisabled: true,
  },
  {
    name: "completed",
    buttonText: t("learningPaths:displayCourseCertificate"),
    showCertificate: true,
  },
];

const LearningPathDetails = () => {
  let { id } = useParams();
  const { data: learningTrack, isLoading: isLearningTrackLoading } =
    useGetTrackQuery(id);

  let ProgressBarPercentage = `${learningTrack?.data?.enrollment?.progress}%`;
  let completeDate = moment(
    new Date(learningTrack?.data?.enrollment?.complete_by)
  );
  let oneDay = 24 * 60 * 60 * 1000;
  let remainingDays = Math.round((completeDate - new Date()) / oneDay);
  let absRemainingDays = Math.abs(remainingDays);
  const { t } = useTranslation(["common", "learningPaths"]);
  let subDetails =
    t("learningPaths:learningPathsDeadline", {
      date: completeDate.format("DD / MM / YYYY"),
    }) +
      " " +
      remainingDays >
    0
      ? t("learningPaths:remainingTime", {
          days: remainingDays,
        })
      : t("learningPaths:deadlinePassed", { lateDays: absRemainingDays });

  let isOneCourseOpened = false;

  if (isLearningTrackLoading) return <LoadingIndicator />;

  if (!learningTrack) {
    return <NotFoundPage />;
  }

  return (
    <>
      {learningTrack?.data ? (
        <HeaderPage
          progressBarFullDetails={t("learningPaths:completedPortion")}
          progressBarPercentageText={ProgressBarPercentage}
          progressBarSubDetails={subDetails}
          sectionTitle={learningTrack?.data?.title}
          listItems={[
            {
              name: t("learningPaths"),
              href: "/employee/learning_paths",
            },
            {
              name: learningTrack?.data?.title,
              href: "#",
            },
          ]}
        />
      ) : null}
      <div className="container-fluid">
        <div className="row justify-content-center p-4">
          <div className="col-lg-10 col-md-12">
            {/* <h1 className="mb-5 font_20 head-text-title">
              <span className="font-extrabold ms-1">4 مسارات تعلم</span>{" "}
              <span>تم الإشتراك بها مؤخراً</span>
            </h1> */}

            <ul className="list-progressbar-cards">
              {learningTrack?.data?.courses?.map((course, i) => {
                let courseStatusName = "closed";
                if (course?.enrollment?.progress == 100)
                  courseStatusName = "completed";
                else if (!isOneCourseOpened) {
                  courseStatusName = "inprogress";
                  isOneCourseOpened = true;
                }

                let courseStatus = courseStatuses(
                  course?.enrollment?.progress,
                  t
                ).find((status) => status.name == courseStatusName);

                return (
                  <li
                    className={`mb-4
                      ${courseStatus?.name == "completed" ? "completed" : null}
                      `}
                  >
                    <hr className="border-dashed" />
                    <span className="list-progressbar-cards-state">{++i}</span>
                    <LearningPathdetailsCard
                      course={course}
                      courseStatus={courseStatus}
                      state={{
                        ProgressBarPercentage,
                        learningPathName: learningTrack?.data?.title,
                        learningPathId: learningTrack?.data?.id,
                      }}
                      courseStatusClassName="text-start"
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LearningPathDetails;
