import React, { useState, useEffect } from "react";
import AdminEmployeeCard from "../../../../../components/cards/AdminEmployeeCard";
import Pagination from "../../../../Tables/Pagination";
import { useGetLearningTrackJoinedEmployeesQuery } from "../../../../../services/organization/adminLearningTrack";
import { useTranslation } from "react-i18next";
import EmptyState from "../../../../EmptyState";
import Icon from "../../../../icons/Icon";
import { UsersStrokeIcon } from "../../../../icons/Icons";

export default function Employees({ learningTrack }) {
  const [page, setPage] = useState(1);
  const { data: employees, isFetching } =
    useGetLearningTrackJoinedEmployeesQuery({
      learningTrackId: learningTrack.id,
      page,
    });
  const { t } = useTranslation("common", "employees");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  if (isFetching) {
    return <></>;
  }

  return (
    <div id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
      <div className="container-fluid">
        {employees.data.length ? (
          <div className="row p-4">
            <div className="d-flex align-center flex-wrap">
              <div>
                <h1 className="font_20 font-extrabold">
                  {t("employees:theEmployees")}
                </h1>
              </div>
            </div>
            <ul className="list-cards mt-4">
              {employees.data.map((employee) => (
                <li key={`employee-${employee.id}`}>
                  <AdminEmployeeCard
                    employeeEnrollment={employee}
                    learningTrack={learningTrack}
                  />
                </li>
              ))}
            </ul>
            <Pagination
              current={employees?.pagination?.current}
              pages={employees?.pagination?.pages}
              handlePagination={(page) => setPage(page)}
            />
          </div>
        ) : (
          <div className="mt-5">
            <EmptyState
              iconComponent={
                <Icon
                  icon={<UsersStrokeIcon />}
                  iconName="users"
                  iconSize={38}
                  viewBox="0 0 25 25"
                  iconColor="#bbbabf"
                  hasStroke
                />
              }
              title={t("emptyStates.employees")}
              emptyStateText={t("employees:addEmployeeToAppear")}
            />
          </div>
        )}
      </div>
    </div>
  );
}
