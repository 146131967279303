import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useListNotificationsQuery } from "../services/users/notifications";
import { displayDate } from "../utils/arabicDatesUtilities";
import EmptyState from "../components/EmptyState";
import { useTranslation } from "react-i18next";

const Notification = () => {
  const { t } = useTranslation(["common"]);

  const { data } = useListNotificationsQuery({
    page: 1,
    perPage: 10,
  });

  let navigate = useNavigate();

  const handleNotificationNavigation = (key, navigationableId) => {
    switch (key) {
      case "post_comment":
      case "post_like":
        navigate(`/employee/community/${navigationableId}`);
        return;

      case "assign_request":
        navigate(`/employee/learning_paths`);
    }
  };

  let currentDate;

  const setCurrentDate = (notificationDateFormatted) => {
    currentDate = notificationDateFormatted;
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center p-4">
        <div className="col-lg-10 col-md-12">
          <div className="d-flex flex-wrap">
            <div className="mb-4">
              <h1 className="mb-0 font_24 font-extrabold ">
                {t("allNotifications")}
              </h1>
            </div>
          </div>
          <div className="notification">
            {data?.data?.notifications.length > 0 ? (
              data?.data?.notifications.map((notification) => {
                let notificationDateFormatted = moment(
                  notification.created_at
                ).format("LL");

                return (
                  <>
                    {notificationDateFormatted !== currentDate && (
                      <p
                        className="font_14 font-color-grey"
                        key={notification.id}
                      >
                        {" "}
                        {notificationDateFormatted}
                        {setCurrentDate(notificationDateFormatted)}
                      </p>
                    )}
                    <ul>
                      <li
                        key={notification.id}
                        className={`notification__item d-flex ${
                          !notification.read ? "notification__item--new" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleNotificationNavigation(
                            notification.key,
                            notification.navigationable_id
                          )
                        }
                      >
                        <div className="notification__item__img">
                          {notification.avatar_url ? (
                            <img
                              src={notification.avatar_url}
                              className="img"
                              alt={`img`}
                              style={{ width: "40px", height: "40px" }}
                            ></img>
                          ) : (
                            <i className="far fa-bell notification__item__icon "></i>
                          )}
                        </div>
                        <div className="notification__item__info">
                          <p className="mb-0">{notification.title}</p>
                          <small className="font_14 font-color-grey">
                            {displayDate(notification.created_at)}
                          </small>
                        </div>
                      </li>
                    </ul>
                  </>
                );
              })
            ) : (
              <EmptyState
                iconComponent={<i className="far fa-bell" />}
                title={t("emptyStates.notifications")}
                emptyStateText={t("addNotificationsToAppear")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
