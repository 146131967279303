import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../../components/inputs/Avatar";
import CertificateIcon from "../../components/icons/Certificate";
import ProgressBar from "../../components/ProgressBar";
import CongratsCertificateModal from "../Modals/CongratsCertificateModal";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const LearningPathdetailsCard = ({
  course,
  courseStatus,
  state,
  courseStatusClassName,
}) => {
  const { t } = useTranslation("learningPaths");

  const displayCourseVideoDuration = (videoDuration) => {
    let hours = parseInt(videoDuration / 60 / 60);
    let minutes = parseInt(videoDuration / 60 - hours * 60);
    return (
      <p className="font_14 font-color-grey ms-1 mb-0">
        {`${hours} ${t("learningPaths:hour")}: ${minutes} ${t(
          "learningPaths:minutes"
        )}`}
      </p>
    );
  };

  return (
    <div className="card-style learinig-path-card learinig-path-details-card">
      <CongratsCertificateModal
        CertificateUrl={course?.enrollment?.certificate_url}
      />
      <div className="learinig-path-card__img">
        <img className="" alt="" src={course?.image_url} />
      </div>

      <div className="learinig-path-card__content">
        {course?.topics?.length && (
          <span
            className="course-label mb-1"
            style={{ backgroundColor: `${course?.topics[0]?.color}` }}
          >
            {course?.topics[0]?.title}
          </span>
        )}
        <h2 className="card-title mb-1">{course?.title}</h2>
        <ProgressBar
          percentageText={
            course?.enrollment?.progress
              ? `${course?.enrollment?.progress}%`
              : "0%"
          }
        />
        <ul className="list-details">
          <li>
            {" "}
            <Avatar
              width="24"
              height="24"
              avatarUrl={course?.tutor?.avatar_url}
            />
            <p className="font_14 font-color-grey ms-1 mb-0">
              {course?.tutor?.name}
            </p>
          </li>
          <li>
            <span className="fas fa-play-circle fa-fw font-color-grey"></span>
            <p className="font_14 font-color-grey ms-1 mb-0">
              {course?.lessons_count + " " + t("learningPaths:lesson")}
            </p>
          </li>
          <li>
            <span className="fas fa-clock fa-fw font-color-grey"></span>
            {displayCourseVideoDuration(course?.video_duration)}
          </li>
        </ul>
      </div>

      <div className="learinig-path-card__btn">
        <Link
          className={`btn btn-course ${courseStatus?.name}-course`}
          style={courseStatus?.btnDisabled ? { pointerEvents: "none" } : {}}
          to={
            courseStatus?.showCertificate
              ? ""
              : `/employee/course/${course?.id}`
          }
          state={state}
          data-bs-toggle={courseStatus?.showCertificate ? "modal" : ""}
          data-bs-target={
            courseStatus?.showCertificate ? "#congratsCertificate" : ""
          }
        >
          {courseStatus?.buttonIcon && (
            <span
              className={`${courseStatus?.buttonIcon} icon font_31 me-2`}
            ></span>
          )}
          {courseStatus?.showCertificate && (
            <span className="me-2">
              <CertificateIcon />
            </span>
          )}
          <div className={`flex-auto mb-0 ${courseStatusClassName}`}>
            {courseStatus?.buttonText}
            {courseStatus?.buttonSubText && (
              <span className="font_12 font-color-grey d-block font-regular ">
                {courseStatus?.buttonSubText}
              </span>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default LearningPathdetailsCard;

LearningPathdetailsCard.prototype = {
  courseStatusClassName: PropTypes.string,
};

LearningPathdetailsCard.deafultProps = {
  courseStatusClassName: "",
};
