import InfiniteScroll from "react-infinite-scroll-component";
import DiscoverCourseCard from "../../components/cards/DiscoverCourseCard";
import SelectFilter from "../../components/inputs/SelectFilter";
import {
  useLazyListCoursesQuery,
  useLazyListTopicsQuery,
} from "../../services/organization/adminCourse";
import { useState, useEffect, useMemo } from "react";
import useCoursesFilter from "../../hooks/useCoursesFilter";
import { useTranslation } from "react-i18next";

const DiscoverCourses = () => {
  const [queryParams, setTopic, setLevel] = useCoursesFilter();
  const [allCourses, setAllCourses] = useState([]);
  const [page, setPage] = useState(1);

  const { t } = useTranslation(["common", "employees", "learningPaths"]);

  const levelOptions = [
    { value: "all_levels", label: t("levels.allLevels"), icon: "icon1" },
    { value: "beginner", label: t("levels.beginner"), icon: "icon2" },
    { value: "intermediate", label: t("levels.intermediate"), icon: "icon3" },
    { value: "advanced", label: t("levels.advanced"), icon: "icon4" },
  ];

  const [fetchCourses, { data: fetchedCourses, isLoading }] =
    useLazyListCoursesQuery({
      page,
    });
  const [fetchTopics, { data: topicsData }] = useLazyListTopicsQuery();

  useEffect(() => {
    fetchTopics();
  }, []);

  useEffect(() => {
    fetchCourses({ page, filters: queryParams });
  }, [page]);

  useEffect(() => {
    fetchCourses({ page: 1, filters: queryParams });
    setPage(1);
  }, [queryParams]);

  useEffect(() => {
    if (fetchedCourses?.data?.length) {
      if (fetchedCourses?.pagination?.current == 1) {
        setAllCourses(fetchedCourses?.data);
      } else {
        setAllCourses((prev) => {
          return [...prev, ...fetchedCourses?.data];
        });
      }
    } else {
      setAllCourses([]);
    }
  }, [fetchedCourses]);

  const tagesOptions = useMemo(
    () =>
      topicsData?.data.length
        ? [
            { value: "all", label: t("all"), isDisabled: true },
            ...topicsData.data.map(({ slug, title }) => {
              return { value: slug, label: title };
            }),
          ]
        : [],
    [topicsData]
  );

  if (isLoading) {
    return null;
  }

  const topicFilterOnchange = (data) => setTopic(data.value);
  const levelFilterOnchange = (data) => setLevel(data.value);

  return (
    <div className="container-fluid">
      <div className="row justify-content-center p-4">
        <div className="col-lg-10 col-md-12">
          <div className="d-flex flex-wrap">
            <div className="mb-4">
              <h1 className="mb-0 font_24 font-extrabold ">
                {t("learningPaths:navigateCourses")}
              </h1>
              <p className="mb-0 font_18 font-color-grey mt-2">
                {t("learningPaths:addCoursesHint")}
              </p>
            </div>
          </div>
          <div className="filter">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              <div className="col">
                <SelectFilter
                  label={t("topics")}
                  options={tagesOptions}
                  onchange={topicFilterOnchange}
                />
              </div>
              <div className="col">
                <SelectFilter
                  label={t("level")}
                  options={levelOptions}
                  hasIcon
                  onchange={levelFilterOnchange}
                />
              </div>
            </div>
          </div>

          <div className="discover-courses">
            {Boolean(allCourses.length) ? (
              <InfiniteScroll
                dataLength={allCourses?.length || 0}
                next={() => setPage((pageNo) => pageNo + 1)}
                hasMore={!!fetchedCourses?.pagination?.next}
                loader={<h4 className="text-center mt-3">{t("Loading")}</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>{t("successfulLearningPathDisplay")}</b>
                  </p>
                }
              >
                <div className="row row-cols-1 row-cols-md-3">
                  {allCourses?.map((courseInfo) => {
                    return (
                      <DiscoverCourseCard
                        key={courseInfo?.id}
                        imageUrl={courseInfo?.image_url}
                        courseTitle={courseInfo?.title}
                        userName={courseInfo.tutor?.name || ""}
                        courseTag={courseInfo.topics[0]}
                        color={courseInfo?.topics[0]?.color}
                        avatarUrl={courseInfo?.tutor?.avatar_url}
                      />
                    );
                  })}
                </div>
              </InfiniteScroll>
            ) : (
              <h4>{t("noMatching")}</h4>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoverCourses;
