import React, { useEffect, useState, useRef } from "react";

export default function AutoCompleteMultiSelectInput({
  name,
  setSearchKeyword,
  setFieldValue,
  onBlur,
  options,
  label,
  disabled,
  marginBottom,
  error,
  touched,
  noMatchError = "",
  optionComponent: OptionComponent,
  selectedComponent: SelectedComponent,
  autocomplete = "on",
}) {
  const [searchKeywordValue, setSearchKeywordValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsSet, setSelectedOptionsSet] = useState(new Set());
  const [showDDL, setShowDDL] = useState(true);

  const dropDownRef = useRef();
  const inputFieldRef = useRef();

  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, []);

  useEffect(() => {
    setShowDDL(true);
  }, [options]);

  useEffect(() => {
    setFieldValue(name, selectedOptions);
  }, [selectedOptions, name, setFieldValue]);

  function clickHandler(e) {
    if (e.target === inputFieldRef.current) {
      return;
    }
    if (!dropDownRef?.current?.contains(e.target)) {
      setShowDDL(false);
    }
  }

  function changeSearchKeyword(e) {
    setSearchKeyword(e.target.value);
    setSearchKeywordValue(e.target.value);
  }

  function addOption(newOption) {
    if (selectedOptionsSet.has(newOption.id)) {
      return;
    }
    const tempSet = selectedOptionsSet;
    tempSet.add(newOption.id);
    setSelectedOptions([...selectedOptions, newOption]);
    setSelectedOptionsSet(tempSet);
    setSearchKeywordValue("");
  }

  function removeSelected(optionToBeRemoved) {
    const tempSelectedOptions = selectedOptions.filter((option) => option.id !== optionToBeRemoved.id);
    const tempSet = selectedOptionsSet;
    tempSet.delete(optionToBeRemoved.id);
    setSelectedOptionsSet(tempSet);
    setSelectedOptions(tempSelectedOptions);
  }

  function isNoMatchFound() {
    return searchKeywordValue && options.length === 0 && noMatchError.length;
  }

  return (
    <div className="input-text">
      <div className="did-floating-label-content" style={{ marginBottom: marginBottom ? marginBottom + "px" : "27px" }}>
        <input
          ref={inputFieldRef}
          name={name}
          className="did-floating-input"
          placeholder=" "
          onBlur={onBlur}
          value={searchKeywordValue}
          onChange={changeSearchKeyword}
          disabled={disabled}
          onClick={() => setShowDDL(!showDDL)}
          autocomplete={autocomplete}
        />

        {showDDL && (
          <div
            ref={dropDownRef}
            className=""
            style={{
              position: "absolute",
              zIndex: "1",
              width: "100%",
              background: "white",
              filter: "drop-shadow(1px 13px 9px rgba(0,0,0,0.5))",
            }}
          >
            {options.map((option) => (
              <div
                key={`option-${option.id}`}
                onClick={() => {
                  addOption(option);
                  setShowDDL(false);
                }}
              >
                <OptionComponent option={option} />
              </div>
            ))}
          </div>
        )}

        <label className="did-floating-label">{label}</label>
        {error && touched ? <div className="input-error">{isNoMatchFound() ? noMatchError : error}</div> : null}

        <div className="d-flex flex-wrap" style={{ marginTop: "10px", gap: "10px" }}>
          {selectedOptions.map((option) => (
            <div key={`selected-${option.id}`}>
              <SelectedComponent option={option} onRemove={() => removeSelected(option)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
