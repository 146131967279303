import { useState, useEffect, useMemo } from "react";
import Routes from "./routes/routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { prepareDateLocal } from "./utils/prepareDateLocal";
import { useLazyGetOrgProfileQuery } from "./services/public/organizations";
import { getRenderColor } from "./utils/helpers";
import { useCookies } from "react-cookie";
import { isLocalhost } from "./utils/helpers";
import { setCurrentOrganization } from "./app/store/reducers/authSlice";
import { useDispatch } from "react-redux";

export default function App() {
  const [cookies, setCookie] = useCookies(["cookie-name"]);

  const dispatch = useDispatch();

  // rubikalapp, mof, testing
  useEffect(() => {
    let fullUrl = window.location.host;
    let websiteDomain;
    isLocalhost(fullUrl)
      ? (websiteDomain = "testing")
      : (websiteDomain = fullUrl.split(".")[0]);
    getOrgProfile(websiteDomain);
  }, []);

  const [getOrgProfile, { data: organizationDesign }] =
    useLazyGetOrgProfileQuery();

  useMemo(() => {
    getRenderColor(cookies.renderColor);
  }, []);

  useEffect(() => {
    if (organizationDesign?.data) {
      setCookie("renderColor", organizationDesign?.data?.color);
      setCookie("orgLogo", organizationDesign?.data?.image_url);
      setCookie("domain", organizationDesign?.data?.domain);

      getRenderColor(organizationDesign?.data?.color);
      dispatch(
        setCurrentOrganization({
          renderColor: organizationDesign?.data?.color,
          orgLogo: organizationDesign?.data?.image_url,
          domain: organizationDesign?.data?.domain,
        })
      );
    }
  }, [organizationDesign?.data]);

  prepareDateLocal();

  return (
    <div className="App">
      <div id="maincontent" className="main">
        <Routes />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      </div>
    </div>
  );
}
