import React, { useState, useEffect } from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import LearningPathCard from "../../cards/LearningPathCard";
import { useAdminGetUserLearningTracksQuery } from "../../../services/organization/adminUsers";
import LoadingIndicator from "../../LoadingIndicator";
import { useTranslation } from "react-i18next";

export default function LearningLevel({ user }) {
  const [allLearningTracks, setAllLearningTracks] = useState([]);
  const [page, setPage] = useState(1);
  const { t } = useTranslation(["common"]);
  const { data: learningTracks, isLoading: isGetUserLearningTracksLoading } =
    useAdminGetUserLearningTracksQuery({
      userId: user?.id,
      page,
    });

  useEffect(() => {
    if (learningTracks?.data?.length) {
      if (learningTracks?.pagination?.current == 1) {
        setAllLearningTracks(learningTracks?.data);
      } else {
        setAllLearningTracks((prev) => {
          return [...prev, ...learningTracks?.data];
        });
      }
    }
  }, [learningTracks]);

  if (isGetUserLearningTracksLoading) return <LoadingIndicator />;

  return (
    <div className="profile-about">
      <div className="row">
        <h1 className="mb-5 font_20 head-text-title">
          <span className="font-extrabold me-2">
            {learningTracks?.pagination?.count} {t("learningPaths")}
          </span>{" "}
          <span>{t("latestSubscription")}</span>
        </h1>
        {allLearningTracks?.length ? (
          <InfiniteScroll
            className="feed-list"
            dataLength={allLearningTracks.length}
            next={() => setPage(learningTracks?.pagination?.next)}
            hasMore={learningTracks?.pagination?.next}
            loader={<h4>{t("Loading")}</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>{t("successfulLearningPathDisplay")}</b>
              </p>
            }
            style={{
              overflow: "unset",
            }}
          >
            {allLearningTracks?.map((learningTrack) => {
              let ProgressBarPercentage = `${learningTrack?.enrollment?.progress}%`;
              let subscribedDate = moment(new Date(learningTrack?.created_at));
              let completeDate = moment(
                new Date(learningTrack?.enrollment?.complete_by)
              );
              let oneDay = 24 * 60 * 60 * 1000;
              return (
                <div className="col-lg-12 col-sm-12 mb-3">
                  <LearningPathCard
                    id={learningTrack?.id}
                    title={learningTrack?.title}
                    image={learningTrack?.image_url}
                    ProgressBarPercentage={ProgressBarPercentage}
                    coursesCounts={learningTrack?.courses_count}
                    completedCoursesCount={
                      learningTrack?.enrollment?.completed_courses_count
                    }
                    leftSideContent
                    boxShadow="none"
                    subscribedAt={subscribedDate.format("DD / MM / YYYY")}
                    completeBy={completeDate.format("DD / MM / YYYY")}
                    remainingDays={Math.round(
                      Math.abs((completeDate - new Date()) / oneDay)
                    )}
                    isArchived={learningTrack?.archived}
                    isAdmin
                  />
                </div>
              );
            })}
          </InfiniteScroll>
        ) : null}
      </div>
    </div>
  );
}
