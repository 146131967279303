import PropTypes from "prop-types";
import Icon from "./icons/Icon";
import hexToRgba from "hex-to-rgba";
import { CheckIcon, TimesIcon } from "./icons/Icons";

const State = ({
  iconName,
  icon,
  iconSize,
  title,
  desc,
  children,
  color,
  type,
  hasIconStroke,
  className,
  titleFontSize,
  descFontSize,
}) => {
  const STATETYPEICON = {
    success: "fas fa-check fa-fw",
    fail: "fas fa-times fa-fw",
  };

  const STATETYPEICONColor = {
    success: "#01c299",
    fail: "#af151f",
  };

  const stateStyle = {
    backgroundColor: hexToRgba(type ? STATETYPEICONColor[type] : color, 0.1),
  };

  return (
    <div className={`state text-center ${className}`}>
      <div className={`state__icon`} style={stateStyle}>
        {" "}
        {STATETYPEICON[type] ? (
          <i
            style={{ color: STATETYPEICONColor[type] }}
            className={STATETYPEICON[type]}
          ></i>
        ) : (
          <Icon
            icon={icon}
            iconName={iconName}
            iconSize={iconSize ? iconSize : "35"}
            iconColor={color}
            hasStroke={hasIconStroke}
          />
        )}
      </div>
      {(title || desc) && (
        <div>
          {title && (
            <h3 className={`font_${titleFontSize} font-extrabold`}>{title}</h3>
          )}
          {desc && (
            <p className={`font-color-grey font_${descFontSize} state__desc`}>
              {desc}
            </p>
          )}
        </div>
      )}

      {children}
    </div>
  );
};
export default State;

State.propTypes = {
  type: PropTypes.oneOf(["success", "fail"]),
  iconName: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  desc: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  className: PropTypes.string,
  hasIconStroke: PropTypes.bool,
  titleFontSize: PropTypes.number,
  descFontSize: PropTypes.number,
};

State.defaultProps = {
  desc: "",
  hasIconStroke: false,
  className: "",
  titleFontSize: 22,
  descFontSize: 16,
};
