import { useState } from "react";
import Modal from "./Modal";
import * as yup from "yup";
import { useFormik } from "formik";
import InputFieldFloat from "../inputs/InputFieldFloat";
import { useAdminInviteOtherAdminMutation } from "../../services/organization/settings";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const InviteAdminModal = () => {
  const [closeModal, setCloseModal] = useState(false);
  const [inviteAdmin] = useAdminInviteOtherAdminMutation();
  const { t } = useTranslation(["common", "settings"]);
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("common:validations.emailWrong"))
      .required(t("common:validations.emailRequired")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const result = await inviteAdmin(values?.email);
      if (result?.error || result == undefined) {
        toast.error(result?.error?.data?.message);
      } else {
        setCloseModal(true);
        toast.success(t("common:invitationSuccess"));
      }
    },
  });

  return (
    <Modal
      id="staticBackdropAdmin"
      title={t("settings:addAdmin")}
      mainBtn={t("common:sendInvitation")}
      mainBtnOnclick={formik.handleSubmit}
      className="invite-employee-modal"
      maxWidth="500"
      closeModal={closeModal}
      onClose={() => {
        setCloseModal(false);
        formik.resetForm();
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <>
          <p className="font-16 font-color-grey">{t("settings:inviteAdmin")}</p>
          <InputFieldFloat
            marginBottom="0"
            label={t("common:inputs.email")}
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
            touched={formik.touched.email}
          />
        </>
      </form>
    </Modal>
  );
};
export default InviteAdminModal;
