import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Quill } from "react-quill";

export default function InputRichTextAreaFloat({ id, name, label, marginBottom, hint, error, touched, value, onChange, setFieldValue }) {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ header: [1, 2, 3, 4, 5, 6, 7] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }, { direction: "rtl" }],
      ["image", "link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "color",
    "background",
    "align",
    "direction",
    "script",
    "code-block",
  ];

  function setValue(e) {
    e = e.replace(/\s+/g, " ");
    if (setFieldValue && name) {
      setFieldValue(name, e);
    } else {
      onChange(e);
    }
  }

  return (
    <div className="input-text">
      <div
        className="did-floating-label-content"
        style={{
          marginBottom: marginBottom ? marginBottom + "px" : "27px",
        }}
      >
        <ReactQuill
          id={id}
          name={name}
          value={value}
          onChange={setValue}
          placeholder={label}
          theme="snow"
          modules={modules}
          formats={formats}
          className="quill-editor-field"
        />
        <p className="input-hint">{hint}</p>
        {error && touched ? <div className="input-error">{error}</div> : null}
      </div>
    </div>
  );
}
