import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "../../components/inputs/Avatar";
import CertificateIcon from "../../components/icons/Certificate";
import ProgressBar from "../../components/ProgressBar";
import { calculateDurationInHoursAndMin } from "../../utils/serviceUtilities";
import { useTranslation } from "react-i18next";
const AdminLearningPathdetailsCard = ({
  course,
  ProgressBarPercentage,
  buttonText,
  buttonSubText,
  buttonType,
  buttonIcon,
  btnDisabled,
  showCertificate,
  BtnNavigation,
  state,
  listDetails,
  smallCard,
}) => {
  const duration = calculateDurationInHoursAndMin(course.video_duration);
  const { t } = useTranslation(["learningPaths"]);
  return (
    <div
      className={`card-style-padding learinig-path-card learinig-path-details-card ${
        smallCard ? "small-card" : ""
      }`}
    >
      <div className="learinig-path-card__img">
        <img className="" alt="" src={course.image_url} />
      </div>

      <div className="learinig-path-card__content">
        {course?.topics?.length && (
          <span
            className="course-label mb-1"
            style={{ backgroundColor: `${course.topics[0].color}` }}
          >
            {course.topics[0].title}
          </span>
        )}
        <h2 className="card-title mb-2">{course.title}</h2>
        <ul className="list-details mb-3 mt-1">
          <li>
            <Avatar
              width="24"
              height="24"
              avatarUrl={course.tutor?.avatar_url}
            />
            <p className="font_14 font-color-grey ms-1 mb-0">
              {course.tutor?.name}
            </p>
          </li>
        </ul>
        {listDetails && (
          <ul className="list-details">
            <li>
              <span className="fas fa-play-circle fa-fw font-color-grey"></span>
              <p className="font_14 font-color-grey ms-1 mb-0">
                {course.lessons_count} {t("learningPaths:lesson")}
              </p>
            </li>
            <li>
              <span className="fas fa-question-circle fa-fw font-color-grey"></span>
              <p className="font_14 font-color-grey ms-1 mb-0">
                4 {t("learningPaths:question")}
              </p>
            </li>
            <li>
              <span className="fas fa-clock fa-fw font-color-grey"></span>
              <span className="font_14 font-color-grey ms-1 mb-0">
                {duration.hours > 0 && (
                  <span>
                    {duration.hours} {t("learningPaths:hour")}{" "}
                  </span>
                )}
                {duration.hours > 0 && duration.minutes > 0 && ":"}
                {duration.minutes > 0 && (
                  <span>
                    {duration.minutes} {t("learningPaths:minutes")}
                  </span>
                )}
              </span>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default AdminLearningPathdetailsCard;
