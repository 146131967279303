
import React from 'react'

export default function users() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="20.641" viewBox="0 0 24.5 20.641">
    <g id="users" transform="translate(0.25 -1.609)">
      <path id="Path_34322" data-name="Path 34322" d="M17,21V19a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
      <circle id="Ellipse_483" data-name="Ellipse 483" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
      <path id="Path_34323" data-name="Path 34323" d="M23,21V19a4,4,0,0,0-3-3.87" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
      <path id="Path_34324" data-name="Path 34324" d="M16,3.13a4,4,0,0,1,0,7.75" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
    </g>
  </svg>
  )
}
