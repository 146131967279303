import { api } from "../index";

export const community = api.injectEndpoints({
  endpoints: (builder) => ({
    listPosts: builder.query({
      query: (page) => `/api/v1/user/posts?page=${page}&per_page=10`,
      providesTags: [{ type: "Posts" }],
    }),

    getPost: builder.query({
      query: (postId) => `/api/v1/user/posts/${postId}`,
    }),

    getMyPosts: builder.query({
      query: (page) => `/api/v1/user/posts/my?page=${page}&per_page=10`,
      providesTags: ["MyPosts"],
    }),

    getStatistics: builder.query({
      query: () => `/api/v1/user/posts/statistics`,
      providesTags: [{ type: "Statistics" }],
    }),

    createPost: builder.mutation({
      query: (body) => ({
        url: `/api/v1/user/posts`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Posts", type: "Statistics", type: "MyPosts" }],
    }),

    deletePost: builder.mutation({
      query: (postId) => ({
        url: `/api/v1/user/posts/${postId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Posts", type: "Statistics", type: "MyPosts" }],
    }),

    likePost: builder.mutation({
      query: (postId) => ({
        url: `/api/v1/user/posts/${postId}/likes`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Posts", type: "Statistics" }],
    }),

    unlikePost: builder.mutation({
      query: (postId) => ({
        url: `/api/v1/user/posts/${postId}/likes`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Posts", type: "Statistics" }],
    }),

    createComment: builder.mutation({
      query: ({ postId, body }) => ({
        url: `/api/v1/user/posts/${postId}/comments`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Statistics" }],
    }),

    likeComment: builder.mutation({
      query: (commentId) => ({
        url: `/api/v1/user/comments/${commentId}/likes`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Posts" }],
    }),

    unlikeComment: builder.mutation({
      query: (commentId) => ({
        url: `/api/v1/user/comments/${commentId}/likes`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Posts" }],
    }),
  }),
});

export const {
  useLazyListPostsQuery,
  useLazyGetPostQuery,
  useGetPostQuery,
  useGetMyPostsQuery,
  useLazyGetMyPostsQuery,
  useGetStatisticsQuery,
  useCreatePostMutation,
  useDeletePostMutation,
  useLikePostMutation,
  useUnlikePostMutation,
  useCreateCommentMutation,
  useLikeCommentMutation,
  useUnlikeCommentMutation,
} = community;
