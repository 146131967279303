import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Navbar from "../components/Navbar";
import SideBarMobile from "../components/SideBarMobile";
import { getEmployeeNavbarItems } from "./navigationItems";
import { useShowProfileQuery } from "../services/users/auth";
import { setCurrentUser } from "../app/store/reducers/authSlice";
import { useTranslation } from "react-i18next";
import { setDocumentLang } from "../utils/helpers";
import i18n from "../i18n";

const EmployeeLayout = ({ active }) => {
  const { data: myProfile, error } = useShowProfileQuery();
  const { t } = useTranslation(["common", "profile", "leaderboard"]);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (myProfile?.status == "success") {
      dispatch(setCurrentUser(myProfile?.data));
      i18n.changeLanguage(myProfile?.data?.language);
      setDocumentLang(myProfile?.data?.language);
    }

    if (error?.status == 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("is_admin");
      dispatch(setCurrentUser(null));
      navigate("/login");
    }
  }, [myProfile, error]);

  return (
    <div className="admin-layout">
      <Navbar
        items={getEmployeeNavbarItems(active, t)}
        oraganizationLogo={
          myProfile?.data ? myProfile?.data?.organization?.image_url : ""
        }
      />
      <div className="container-fluid">
        <div className="row">
          <SideBarMobile items={getEmployeeNavbarItems(active, t)} />
          <div className="col content-page">
            {/* This element will render child of AdminLayout */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeLayout;
