import React from "react";

export default function RadioGroup({ values, formik }) {
  return (
    <ul className="radio-group">
      {values.map((value, key) => (
        <li key={key}>
          <input
            id={`radio-${value.id}`}
            className="radio-custom"
            name={value.groupName}
            type="radio"
            checked={formik.values.type === value.value}
            // value={value.value}
            onChange={() => {}} //just to remove the console error
            value={formik.values.type}
            onClick={() => formik.setFieldValue("type", value.value)}
          />
          <label htmlFor={`radio-${value.id}`} className="radio-custom-label">
            <div className="label-text">
              <p>{value.text}</p>
              {value.subtText && <span>{value.subtText}</span>}
            </div>
          </label>
        </li>
      ))}
    </ul>
  );
}
