import React from "react";
import { useNavigate } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Avatar from "../inputs/Avatar";
import { useTranslation } from "react-i18next";

const TableLeaderBoard = ({ usersList }) => {
  const { t } = useTranslation(["common", "leaderbaord"]);
  const navigate = useNavigate();
  return (
    <Table className="table">
      <Thead className="table-header">
        <Tr>
          <Th> {t("employeeName")} </Th>
          <Th>{t("employeeDepartment")} </Th>

          <Th>{t("leaderboard:employeePoints")}</Th>
          <Th>{t("leaderboard:TheCompletedCourses")}</Th>
        </Tr>
      </Thead>
      <Tbody className="table__content">
        {usersList?.data?.map((user) => {
          return (
            // if row grey add to tr class= bg-grey
            <Tr key={user} className={`leader-${user?.rank} ${
              user?.rank === usersList.current_user_order
                ? "myself-selected"
                : ""
            } `}>
              <Td>
                <div className="item leader-board">
                  {/* modifier --> leader-1 , leader-2 , leader-3 , myself-selected  */}
                  <div
                    className={`leader-board-card  ${
                      user?.rank == usersList.current_user_order
                        ? "myself-selected"
                        : ""
                    } leader-${user?.rank}
                    `}
                  >
                    <span className="num me-3 font_14 font-extrabold">
                      {user?.rank}
                    </span>
                    <Avatar
                      width="40"
                      height="40"
                      avatarUrl={user?.avatar_url}
                    />
                    <div className="ms-2">
                      <h1
                        role="button"
                        onClick={() => navigate(`/employee/profile/${user.id}`)}
                        className="mb-0 font_15 font-extrabold"
                      >
                        {user?.name} {user?.rank === usersList.current_user_order && <span className="ms-2 font-color-grey font_15 font-regular ">(أنت)</span>}
                      </h1>
                    </div>
                  </div>
                </div>
              </Td>
              <Td>
                <div className="item">
                  <p className="mb-0 font_14 ">{user?.department?.name}</p>
                </div>
              </Td>
              <Td>
                <div className="item">
                  <p className="mb-0 font_14 ">
                    <span className="fas fa-star fa-fw me-2 font-color-gold"></span>
                    <span className="font-extrabold font_16">{user?.points}</span>{" "}
                  </p>
                </div>
              </Td>
              <Td>
                <div className="item">
                  <p className="mb-0 font_14 ">
                    {" "}
                    <span className="font-extrabold">
                      {user?.completed_courses_count}
                    </span>{" "}
                    دورة
                  </p>
                </div>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
export default TableLeaderBoard;
