import { useTranslation } from "react-i18next";

const FileInput = ({ id, name, accept, onChange, files, lineCount }) => {
  const { t } = useTranslation(["common"]);

  return (
    <label htmlFor={id} className="input-file">
      <img className="icon me-2" alt="" src="/images/icons/upload.svg" />
      {files.length != 0 ? (
        <div>
          <p className="file-label">{files[0].name}</p>
          {lineCount != 0 ? (
            <div>
              <span style={{ color: "#888888" }}>
                {lineCount} {t("email")}
              </span>
            </div>
          ) : (
            <div className="input-text">
              <div className="input-error">{t("validations.emailWrong")}</div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <p>{t("uploadHere")}</p>
          <span>{t("allowedCSV")}</span>
        </div>
      )}

      <input
        type="file"
        id={id}
        name={name}
        accept={accept}
        onChange={onChange}
      />
    </label>
  );
};

export default FileInput;
