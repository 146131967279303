import React from "react";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Avatar from "../inputs/Avatar";
import Switch from "../inputs/Switch";
import { getAccountStatus } from "../../utils/helpers";
import moment from "moment";
import { useAdminUpdateOtherAdminMutation } from "../../services/organization/settings";
import { useTranslation } from "react-i18next";

const TableAdmin = ({ usersList }) => {
  const [updateAdmin] = useAdminUpdateOtherAdminMutation();

  const { t } = useTranslation(["common", "settings"]);
  return (
    <Table className="table table-admin">
      <Thead className="table-header">
        <Tr>
          <Th className="font_12 font-color-grey font-bold">
            {t("settings:adminName")}
          </Th>
          <Th className="font_12 font-color-grey font-bold">
            {t("common:inputs.email")}{" "}
          </Th>

          <Th className="font_12 font-color-grey font-bold">
            {t("common:accountStatus")}
          </Th>
          <Th className="font_12 font-color-grey font-bold">
            {t("common:lastActivity")}
          </Th>
          <Th className="text-center font_12 font-color-grey font-bold">
            {t("common:actions")}
          </Th>
        </Tr>
      </Thead>
      <Tbody className="table__content">
        {usersList?.data?.length
          ? usersList.data.map((element, key) => {
              let accountStatus = getAccountStatus(
                element?.registered,
                element?.active,
                t
              );
              return (
                <>
                  <Tr key={key}>
                    <Td>
                      <div className="item me-2">
                        <Avatar
                          width="40"
                          height="40"
                          avatarUrl={element?.avatar_url}
                        />
                        <span
                          className="bold font-extrabold font_14"
                          style={{ marginRight: 10 }}
                        >
                          {element?.name}
                        </span>
                      </div>
                    </Td>
                    <Td>
                      <div className="item">
                        <p className="mb-0 font_14 font-color-grey">
                          {element?.email}
                        </p>
                      </div>
                    </Td>
                    <Td>
                      <div className="item d-flex align-center state-green">
                        <span
                          className="fas fa-circle fa-fw font_10 me-2"
                          style={{ color: accountStatus?.color }}
                        ></span>
                        <p
                          className="font_14 mb-0"
                          style={{ color: accountStatus?.color }}
                        >
                          {accountStatus?.name}
                        </p>
                      </div>
                    </Td>
                    <Td>
                      <div className="item">
                        <p className="mb-0 font_14 ">
                          {element?.last_activity
                            ? moment(element?.last_activity).fromNow()
                            : "_"}
                        </p>
                      </div>
                    </Td>

                    <Td>
                      <div className="item text-center">
                        <div className="dropdown tools">
                          <button
                            className=" dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="fas fa-ellipsis-h fa-fw font_14 font-color-grey"></span>
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <div>
                                <p className="bold mb-0 font_12 font-extrabold">
                                  {element?.name}
                                </p>
                                <p className="mb-0 font_12 font-color-grey">
                                  {element?.email}
                                </p>
                              </div>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <Switch
                                label={t("common:accountStatus")}
                                disabled={accountStatus.type == "notResigtered"}
                                checked={accountStatus.type == "active"}
                                onChange={() =>
                                  updateAdmin({
                                    adminId: element?.id,
                                    updates: {
                                      active: !(accountStatus.type == "active"),
                                    },
                                  })
                                }
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Td>
                  </Tr>
                </>
              );
            })
          : null}
      </Tbody>
    </Table>
  );
};

export default TableAdmin;
