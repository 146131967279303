import React, { useEffect, useState } from "react";
import { calculateDurationInHoursAndMin } from "../../utils/serviceUtilities";
import { useTranslation } from "react-i18next";

export default function CourseCoverCard({ learningTrack }) {
  const [duration, setDuration] = useState({});
  const { t } = useTranslation(["common", "learninPaths"]);

  useEffect(() => {
    let totalDurationInMiliSec = 0;
    learningTrack.courses.forEach((course) => {
      totalDurationInMiliSec += course.video_duration;
    });
    setDuration(calculateDurationInHoursAndMin(totalDurationInMiliSec));
  }, []);

  return (
    <div
      className="course-cover-card"
      style={{ backgroundImage: `url(${learningTrack.image_url})` }}
    >
      <div className="course-cover-card__content">
        <h1>{learningTrack.title}</h1>
        <ul className="list-details">
          <li>
            <span className="fas fa-play-circle fa-fw font-color-white"></span>
            <p className="font_14 font-color-white ms-1 mb-0">
              {t("learningPaths:courses", {
                courses_count: learningTrack.courses_count,
              })}
            </p>
          </li>
          <li>
            <span className="fas fa-clock fa-fw font-color-white"></span>
            <p className="font_14 font-color-white ms-1 mb-0">
              {duration.hours} {t("learningPaths:hour")} : {duration.minutes}{" "}
              {t("learningPaths:minutes")}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}
