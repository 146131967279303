import { api } from "../index";

export const settings = api.injectEndpoints({
  endpoints: (builder) => ({
    AdminGetDepartments: builder.query({
      query: () => `/api/v1/organization/departments`,
      providesTags: [{ type: "Settings" }],
    }),

    AdminCreateDepartment: builder.mutation({
      query: (department) => ({
        url: `/api/v1/organization/departments`,
        method: "POST",
        body: { department },
      }),
      invalidatesTags: ["Settings"],
    }),

    AdminUpdateDepartment: builder.mutation({
      query: (department) => ({
        url: `/api/v1/organization/departments/${department.id}`,
        method: "PUT",
        body: { department },
      }),
      invalidatesTags: ["Settings"],
    }),

    AdminDeleteDepartment: builder.mutation({
      query: (departmentID) => ({
        url: `/api/v1/organization/departments/${departmentID}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Settings"],
    }),

    AdminGetDesign: builder.query({
      // organizations not organization to get public scope 
      query: () => '/api/v1/organizations/profile',
      providesTags: [{ type: "OrganizationInfo" }],
    }),

    AdminUpdateOrganization: builder.mutation({
      query: (organization) => ({
        url:
          '/api/v1/organization/profile',
        method: "PUT",
        body: organization,
      }),
      invalidatesTags: ["OrganizationInfo"],
    }),

    AdminGetAdminsList: builder.query({
      query: ({ searchKeyword, page = 1 }) =>
        searchKeyword
          ? `/api/v1/organization/organization_admins?page=${page}&per_page=10&filters[keyword]=${searchKeyword}`
          : `/api/v1/organization/organization_admins?page=${page}&per_page=10`,
      providesTags: [{ type: "OrganizationAdmins" }],
    }),



    AdminUpdateOtherAdmin: builder.mutation({
      query: ({ adminId, updates }) => ({
        url:
          `/api/v1/organization/organization_admins/${adminId}`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["OrganizationAdmins"],
    }),

    AdminInviteOtherAdmin: builder.mutation({
      query: (email) => ({
        url: `/api/v1/organization/organization_admins/invite`,
        method: "POST",
        body: { email },
      }),
      invalidatesTags: ["OrganizationAdmins"],
    }),




  }),
});

export const {
  useAdminGetDepartmentsQuery,
  useAdminDeleteDepartmentMutation,
  useAdminCreateDepartmentMutation,
  useAdminUpdateDepartmentMutation,
  useAdminGetDesignQuery,
  useAdminUpdateOrganizationMutation,
  useAdminGetAdminsListQuery,
  useAdminUpdateOtherAdminMutation,
  useAdminInviteOtherAdminMutation
} = settings;
