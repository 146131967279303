import { api } from "../index";

export const notifications = api.injectEndpoints({
  endpoints: (builder) => ({
    listNotifications: builder.query({
      query: ({ page = 1, perPage = 10 }) =>
        `/api/v1/user/notifications?page=${page}&per_page=${perPage}`,
    }),
  }),
});

export const { useListNotificationsQuery } = notifications;
