import { useTranslation } from "react-i18next";
const UploadLogo = ({ image, onChange }) => {
  const { t } = useTranslation("settings");
  return (
    <div className="d-flex gap-4 upload-logo align-items-center">
      <div className="border border-2 border-color-grey-light border-radius-12 upload-logo__show">
        <img
          src={
            image
              ? typeof image == "string"
                ? image
                : URL.createObjectURL(image)
              : "/images/icons/upload.svg"
          }
          alt=""
        />
      </div>
      <button
        type="button"
        className="edit-btn"
        onClick={(e) => {
          document.getElementById("add-image").click();
        }}
      >
        <div className="d-flex align-items-center mb-2 ">
          <i className="far fa-edit font-color-grey-light font_18" />
          <p className="font_16 font-color-grey mb-0 ms-2 font-extrabold">
            {" "}
            {t("settings:logoChange")}
          </p>
        </div>
        <p className="font_12 font-color-grey mb-0 text-start">
          {t("settings:logoChangeHint")}
        </p>
        <p className="font_12 font-color-grey text-start">
          {" "}
          {t("settings:acceptedFormats")}: PNG, SVG, JPG
        </p>

        <input
          accept="image/png, image/gif, image/jpeg"
          type="file"
          id="add-image"
          className="upload-logo__input"
          onChange={onChange}
        />
      </button>
    </div>
  );
};

export default UploadLogo;
