import PropTypes from "prop-types";

const EmptyState = ({
  iconComponent,
  title,
  emptyStateText,
  customTitle = null,
}) => {
  return (
    <div className="empty-state text-center mt-4 ">
      <div className="empty-state__icon">{iconComponent}</div>

      <div>
        {customTitle ? (
          <h5 className="font_18 font-extrabold"> {customTitle} </h5>
        ) : (
          <h5 className="font_18 font-extrabold">{title}</h5>
        )}
        <p className="font_16 font-color-grey mt-2"> {emptyStateText} </p>
      </div>
    </div>
  );
};

export default EmptyState;

EmptyState.prototype = {
  iconComponent: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  emptyStateText: PropTypes.string.isRequired,
};
