import AdminQuizCard from "../../../../cards/AdminQuizCard";
import EmptyState from "../../../../EmptyState";
import { useTranslation } from "react-i18next";

const Quizzes = ({ learningTrack }) => {
  const { t } = useTranslation(["common", "learningPaths"]);
  return (
    <div id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
      <div className="container-fluid">
        <div className="row p-4">
          <div className="d-flex align-center flex-wrap">
            <div>
              <h1 className="font_20 font-extrabold">
                {learningTrack.quizzes.length} {t("Questions")}
              </h1>
              <p className="font_16 font-color-grey">
                {t("learningPaths:QuestionsAndExamsHint")}
              </p>
            </div>
          </div>
          <ul className="list-cards mt-4">
            {learningTrack.quizzes.length > 0 ? (
              learningTrack.quizzes.map((quiz) => (
                <li key={`quiz-${quiz.id}`} className="mb-3">
                  <AdminQuizCard quiz={quiz} />
                </li>
              ))
            ) : (
              <EmptyState
                iconComponent={<i className="far fa-question-circle" />}
                title={t("emptyStates.questions")}
                emptyStateText={t("learningPaths:emptyQuestionsHint")}
              />
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Quizzes;
