import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// using https://swiperjs.com/react

// import required modules
import { Pagination, Navigation } from "swiper";
import { useTranslation } from "react-i18next";

const SwiperSlider = ({
  title,
  text,
  children,
  sliderName,
  showAllNavigationUrl,
  hasShowMore,
  desc,
  slidesPerView,
  slidesPerViewXxl,
  slidesPerViewXl,
}) => {
  const [swiperRef, setSwiperRef] = useState(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  let navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  return (
    <>
      <div className="swiper-header">
        <div>
          <p className={`font_20 font-extrabold ${desc ? "mb-2" : ""}`}>
            {title} {text && <span className="font-regular">{text}</span>}
          </p>
          <p className="mb-0 font_14 font-color-grey font-bold">{desc}</p>
        </div>
        <ul className="swiper-tools ms-auto">
          <li>
            {/* {swiperRef.allowSlideNext ? "ture" : "false"} */}
            <button
              className={`btn btn-arrows font_16 font-bold me-2 `}
              //   onClick={() => swiperRef.slidePrev()}
              ref={navigationPrevRef}
            >
              <span className="fas fa-arrow-left fa-fw"></span>
            </button>
          </li>
          <li>
            <button
              className="btn btn-arrows font_16 font-bold me-2"
              //   onClick={() => swiperRef.slideNext()}
              ref={navigationNextRef}
            >
              <span className="fas fa-arrow-right fa-fw"></span>
            </button>
          </li>
          {hasShowMore && (
            <li>
              <button
                className="btn btn-see-more font_16 font-bold"
                onClick={() => navigate(showAllNavigationUrl)}
              >
                {t("showAll")}
              </button>
            </li>
          )}
        </ul>
      </div>
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={slidesPerView ? slidesPerView : 2}
        breakpoints={{
          // when window width is >= 200px
          200: {
            slidesPerView: 1,
          },
          // when window width is >= 576px
          576: {
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 1,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: slidesPerViewXl ? slidesPerViewXl : 1,
          },
          1280: {
            slidesPerView: slidesPerViewXxl ? slidesPerViewXxl : 2,
          },
        }}
        centeredSlides={false}
        spaceBetween={16}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[Navigation]}
        className={` ${sliderName}`}
      >
        {children}
        {/* {[1, 2, 3, 4, 5].map(() => (
          <SwiperSlide>{children}</SwiperSlide>
        ))} */}
      </Swiper>
    </>
  );
};

export default SwiperSlider;
