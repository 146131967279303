import React from "react";

const Spinner = ({ className, style }) => {
  return (
    <div className={`spinner-border ${className}`} style={style} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Spinner;
