import { api } from "../index";

export const auth = api.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (body) => ({
        url: `/api/v1/user/registrations`,
        method: "POST",
        body,
      }),
    }),

    showToken: builder.query({
      query: (token) => `/api/v1/user/registrations/${token}`,
    }),

    login: builder.mutation({
      query: (body) => ({
        url: `/api/v1/user/login`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Profile"],
      providesTags: ["User"],
    }),

    logout: builder.mutation({
      query: () => ({
        url: `/api/v1/user/logout`,
        method: "DELETE",
      }),
    }),

    showProfile: builder.query({
      query: () => `/api/v1/user/profile`,
      providesTags: [{ type: "Profile" }],
    }),

    updateProfile: builder.mutation({
      query: (body) => ({
        url: `/api/v1/user/profile`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "Profile" }],
    }),

    sendResetPasswordEmail: builder.mutation({
      query: (body) => {
        return {
          url: "/api/v1/user/password",
          method: "POST",
          body,
        };
      },
    }),

    verifyResetPasswordToken: builder.query({
      query: ({ token }) => `/api/v1/user/password/verify_token?token=${token}`,
    }),

    sendUpdatedPassword: builder.mutation({
      query: (body) => ({
        url: "/api/v1/user/password",
        method: "PUT",
        body,
      }),
    }),
    changePassword: builder.mutation({
      query: (body) => {
        return {
          url: '/api/v1/user/reset_password',
          method: "POST",
          body
        }
      }
    })
  }),
});

export const {
  useRegisterMutation,
  useShowTokenQuery,
  useLoginMutation,
  useLogoutMutation,
  useShowProfileQuery,
  useUpdateProfileMutation,
  useSendResetPasswordEmailMutation,
  useSendUpdatedPasswordMutation,
  useVerifyResetPasswordTokenQuery,
  useChangePasswordMutation
} = auth;
