import Modal from "./Modal";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({
  confirmationMessage,
  handleConfirmation,
  modalId = "confirmation-modal",
  isOpened,
  onClose,
  children,
}) => {
  const { t } = useTranslation("common");
  return (
    <Modal
      id={modalId}
      title={t("confirmationMessage")}
      mainBtn={t("confirm")}
      mainBtnOnclick={handleConfirmation}
      className="invite-employee-modal"
      maxWidth="500"
      isOpened={isOpened}
      onClose={onClose}
    >
      <h5>{confirmationMessage}</h5>
      {children}
    </Modal>
  );
};

export default ConfirmationModal;
