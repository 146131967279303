import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import UploadProfile from "../../components/inputs/UploadProfile";
import InputFieldFloat from "../../components/inputs/InputFieldFloat";
import InputTextAreaFloat from "../../components/inputs/InputTextAreaFloat";
import InputSelectFloat from "../../components/inputs/InputSelectFloat";
import { useListCountriesQuery } from "../../services/public/countries";
import { useListDepartmentsQuery } from "../../services/public/departments";
import {
  useShowProfileQuery,
  useUpdateProfileMutation,
} from "../../services/users/auth";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  arabic_name: yup.string(),
  name: yup.string(),
  title: yup.string(),
  department: yup.string(),
  phone: yup.string(),
  country_id: yup.number(),
  city_id: yup.number(),
  bio: yup.string(),
  skills: yup.array(),
  facebook: yup.string(),
  linkedin: yup.string(),
  twitter: yup.string(),
  snapchat: yup.string(),
  instagram: yup.string(),
});

const EditProfile = () => {
  const [cities, setCities] = useState([]);
  const [image, setImage] = useState();
  const [skill, setSkill] = useState("");
  const [submmiting, setSubmmiting] = useState(false);
  const { data: countriesList } = useListCountriesQuery();
  const { data: departmentsList } = useListDepartmentsQuery();
  const { data: myProfile } = useShowProfileQuery();
  const [updateProfile] = useUpdateProfileMutation();

  const { t } = useTranslation(["common", "employees"]);

  useEffect(() => {
    if (myProfile?.data?.country?.id) {
      let country = countriesList?.data?.find(
        (country) => country?.id == myProfile?.data?.country?.id
      );
      setCities(country?.cities);
    }
  }, [myProfile]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      arabic_name: myProfile?.data?.name_ar ? myProfile?.data?.name_ar : "",
      name: myProfile?.data?.name_en ? myProfile?.data?.name_en : "",
      title: myProfile?.data?.title ? myProfile?.data?.title : "",
      department: myProfile?.data?.department?.id
        ? departmentsList?.data?.find(
            (department) => department.id == myProfile?.data?.department?.id
          ).name
        : "",
      phone: myProfile?.data?.phone ? myProfile?.data?.phone : "",
      country_id: myProfile?.data?.country?.id
        ? myProfile?.data?.country?.id
        : "",
      city_id: myProfile?.data?.city?.id ? myProfile?.data?.city?.id : "",
      bio: myProfile?.data?.bio ? myProfile?.data?.bio : "",
      skills:
        myProfile?.data?.skills?.length > 0 ? myProfile?.data?.skills : [],
      // skills: myProfile?.data?.skills,
      facebook: myProfile?.data?.facebook ? myProfile?.data?.facebook : "",
      linkedin: myProfile?.data?.linkedin ? myProfile?.data?.linkedin : "",
      twitter: myProfile?.data?.twitter ? myProfile?.data?.twitter : "",
      snapchat: myProfile?.data?.snapchat ? myProfile?.data?.snapchat : "",
      instagram: myProfile?.data?.instagram ? myProfile?.data?.instagram : "",
    },

    validationSchema: validationSchema,
    onSubmit: async (form_values, { resetForm }) => {
      console.log({ form_values });
      setSubmmiting(true);
      let dataForm = new FormData();
      if (image) dataForm.append("avatar", image);
      dataForm.append("name_ar", form_values?.arabic_name);
      dataForm.append("name_en", form_values?.name);
      dataForm.append("phone", form_values?.phone);
      dataForm.append("bio", form_values?.bio);
      dataForm.append("title", form_values?.title);
      dataForm.append("skills", form_values?.skills);
      // dataForm.append("skills", form_values?.skills.join(","));
      dataForm.append("facebook", form_values?.facebook);
      dataForm.append("instagram", form_values?.instagram);
      dataForm.append("twitter", form_values?.twitter);
      dataForm.append("linkedin", form_values?.linkedin);
      dataForm.append("snapchat", form_values?.snapchat);
      dataForm.append("country_id", form_values?.country_id);
      dataForm.append("city_id", form_values?.city_id);
      // dataForm.append("department_id", form_values?.department_id);
      const result = await updateProfile(dataForm);

      setSubmmiting(false);
      if (!result.error) {
        toast.success(t("updatedSuccessfully"));
      } else {
        toast.error(result?.error?.data?.message);
      }
    },
  });

  return (
    <div className="container-fluid profile-page">
      <div className="row justify-content-center p-4">
        <div className="col-lg-6 col-md-12">
          <div className="content-header">
            <h1 className="font-extrabold">{t("profile:editProfile")}</h1>
            <p>{t("profile:updateYourProfile")}</p>
          </div>
          <form
            className="content-form"
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <div className="row">
              <h1 className="section_label">{t("profile:personalInfo")}</h1>
              <div className="d-flex mb-4">
                <UploadProfile
                  avatarUrl={myProfile?.data?.avatar_url}
                  image={image}
                  setImage={setImage}
                />
                <div className="d-flex flex-column  justify-content-center mx-3">
                  <h4 className="gray_title">
                    {t("profile:changeProfilePicture")}
                  </h4>
                  <p className="small_hint">{t("profile:useRecentPhoto")}</p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <InputFieldFloat
                  label={t("profile:nameInArabic")}
                  type="text"
                  name="arabic_name"
                  value={formik.values.arabic_name}
                  onChange={formik.handleChange}
                  error={formik.errors.arabic_name}
                  touched={formik.touched.arabic_name}
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <InputFieldFloat
                  label={t("profile:nameInEnglish")}
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.errors.name}
                  touched={formik.touched.name}
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <InputFieldFloat
                  label={t("profile:professionTitle")}
                  type="text"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.errors.title}
                  touched={formik.touched.title}
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <InputFieldFloat
                  label={t("profile:professionTitle")}
                  type="text"
                  name="department"
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  error={formik.errors.department}
                  touched={formik.touched.department}
                  disabled
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <InputFieldFloat
                  label={t("inputs.email")}
                  type="text"
                  name="email"
                  disabled
                  value={myProfile?.data?.email}
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <InputFieldFloat
                  label={t("mobileNumber")}
                  type="text"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.errors.phone}
                  touched={formik.touched.phone}
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <InputSelectFloat
                  label={t("theCountry")}
                  type="text"
                  name="country_id"
                  value={formik.values.country_id}
                  onChange={(e) => {
                    let country = countriesList?.data?.find(
                      (country) => country?.id == e.target.value
                    );
                    setCities(country?.cities);
                    formik.handleChange(e);
                  }}
                  error={formik.errors.country_id}
                  touched={formik.touched.country_id}
                >
                  <option defaultValue>{t("profile:chooseCountry")}</option>
                  {countriesList?.data?.map((country) => (
                    <option value={country?.id}>{country?.name}</option>
                  ))}
                </InputSelectFloat>
              </div>
              <div className="col-lg-6 col-sm-12">
                <InputSelectFloat
                  label={t("theCity")}
                  type="text"
                  name="city_id"
                  value={formik.values.city_id}
                  onChange={formik.handleChange}
                  error={formik.errors.city_id}
                  touched={formik.touched.city_id}
                >
                  <option defaultValue>{t("profile:chooseCity")}</option>
                  {cities?.map((city) => (
                    <option value={city?.id}>{city?.name}</option>
                  ))}
                </InputSelectFloat>
              </div>
            </div>
            <div className="row">
              <h1 className="section_label">{t("profile:quickOverview")}</h1>
              <InputTextAreaFloat
                label={t("profile:aboutMe")}
                type="text"
                name="bio"
                rows={4}
                hint={`1000 / ${formik?.values?.bio?.length}`}
                value={formik.values.bio}
                onChange={(e) =>
                  formik?.values?.bio?.length < 1000
                    ? formik.handleChange(e)
                    : null
                }
                error={formik.errors.bio}
                touched={formik.touched.bio}
              />
              <InputTextAreaFloat
                label={t("theSkills")}
                type="text"
                name="skills"
                rows={4}
                hint={t("profile:skillsHint")}
                value={skill}
                onChange={(e) => setSkill(e.target.value.trim())}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    let isSkillExist = null;
                    if (formik?.values?.skills?.length)
                      isSkillExist = formik.values.skills.find(
                        (element) => element == skill
                      );

                    console.log({ skill, isSkillExist });
                    if (skill != "" && !isSkillExist) {
                      formik.setFieldValue("skills", [
                        ...formik.values.skills,
                        skill,
                      ]);
                      setSkill("");
                    }
                  }
                }}
              />
              <div
                className="d-flex flex-wrap"
                style={{ marginTop: "10px", gap: "10px" }}
              >
                {formik?.values?.skills?.length > 0
                  ? formik.values.skills?.map((option, i) => (
                      <div
                        className="add-employee-to-track-modal"
                        key={`selected-${i}`}
                      >
                        <div className="selected-component">
                          <p className="selected-component__name"> {option}</p>
                          <span
                            onClick={() => {
                              let newSkills = formik.values.skills.filter(
                                (skill) => skill != option
                              );
                              formik.setFieldValue("skills", newSkills);
                            }}
                            className="fas fa-times selected-component__remove-btn font-color-grey"
                          />
                        </div>
                      </div>
                      // <div key={`selected-${option.id}`}>
                      //   <SelectedComponent
                      //     option={option}

                      //   />
                      // </div>
                    ))
                  : null}
              </div>
            </div>
            <div className="row">
              <h1 className="section_label">{t("profile:socialMediaLinks")}</h1>

              <InputFieldFloat
                label={t("socialMedia.facebook")}
                type="url"
                name="facebook"
                value={formik.values.facebook}
                onChange={formik.handleChange}
                error={formik.errors.facebook}
                touched={formik.touched.facebook}
              />
              <InputFieldFloat
                label={t("socialMedia.linkedin")}
                type="url"
                name="linkedin"
                value={formik.values.linkedin}
                onChange={formik.handleChange}
                error={formik.errors.linkedin}
                touched={formik.touched.linkedin}
              />
              <InputFieldFloat
                label={t("socialMedia.twitter")}
                type="url"
                name="twitter"
                value={formik.values.twitter}
                onChange={formik.handleChange}
                error={formik.errors.twitter}
                touched={formik.touched.twitter}
              />
              <InputFieldFloat
                label={t("socialMedia.snapchat")}
                type="url"
                name="snapchat"
                value={formik.values.snapchat}
                onChange={formik.handleChange}
                error={formik.errors.snapchat}
                touched={formik.touched.snapchat}
              />
              <InputFieldFloat
                label={t("socialMedia.instagram")}
                type="url"
                name="instagram"
                value={formik.values.instagram}
                onChange={formik.handleChange}
                error={formik.errors.instagram}
                touched={formik.touched.instagram}
              />
            </div>
            <button
              type="submit"
              className="btn btn-main-color d-block w-100"
              //   disabled={!checked}
              disabled={submmiting}
            >
              {t("saveInfo")}
              {submmiting && (
                <Spinner
                  style={{ marginRight: "10px", width: "20px", height: "20px" }}
                />
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
