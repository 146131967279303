import React from "react";
import DoughnutChart from "../DoughnutChart";

const ExamProgressFloat = ({ answeredQuestionNumber, totalQuestionNumber }) => {
  return (
    <div className="exam-progress-float">
      <div className="me-3">
        {/* <DoughnutChart
          percentage={parseInt((12 / 45) * 100)}
          color={parseInt((12 / 45) * 100) < 50 && "#AF151F"}
          questionTotals="45"
        /> */}
      </div>
      <div>
        <p className="font_16 font-color-grey mb-0">الأسئلة المحلولة</p>
        <p className="font_20 mb-0">
          <span className="font_31 font-color-red font-extrabold">
            {answeredQuestionNumber}
          </span>{" "}
          / {totalQuestionNumber}
        </p>
      </div>
    </div>
  );
};

export default ExamProgressFloat;
