import { useState } from "react";
import { useSelector } from "react-redux";
import { CongratulationIcon } from "../icons/Icons";
import Modal from "./Modal";
import State from "../State";
import { downloadImage } from "../../utils/helpers";
import { useCreatePostMutation } from "../../services/users/community";
import Spinner from "../../components/Spinner";
import Avatar from "../../components/inputs/Avatar";

const CongratsCertificateModal = ({ CertificateUrl }) => {
  const [isSharingCertificate, setIsSharingCertificate] = useState(false);
  const [postText, setPostText] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [closeModal, setCloseModal] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const [createPost, { isLoading }] = useCreatePostMutation();

  const handleCreatePost = async () => {
    let dataForm = new FormData();
    dataForm.append("body", postText);
    dataForm.append("image_url", CertificateUrl);
    await createPost(dataForm);
    setIsOpen(false);
    setPostText("");
    setCloseModal(true);
  };

  const renderModalContent = () => {
    return isSharingCertificate ? (
      <div>
        <textarea
          id=""
          name=""
          rows="2"
          className="text-area-no-style w-100 mt-1 mb-2"
          placeholder="اكتب عن إنجازك هنا"
          value={postText}
          onChange={(e) => setPostText(e.target.value)}
        />

        {CertificateUrl && (
          <div className="create-post-button-modal__uploaded">
            <div className="image-result">
              <img alt="" src={CertificateUrl} />
            </div>
          </div>
        )}
        <div className="d-flex flex-wrap align-center">
          <button
            type="button"
            className="btn p-5 pb-3 pt-3 btn-main-color font-15"
            onClick={handleCreatePost}
            disabled={!postText && !CertificateUrl}
            data-bs-dismiss={!isOpen ? "modal" : "none"}
            style={{ width: "45%" }}
          >
            {isLoading ? (
              <Spinner style={{ width: "20px", height: "20px" }} />
            ) : (
              "مشاركة"
            )}
          </button>
          <span style={{ margin: "0 auto" }}></span>
          <button
            type="button"
            className="btn p-5 pb-3 pt-3 btn-secondary-color font-15"
            style={{ background: "#F1F1F2", width: "45%" }}
            onClick={() => setCloseModal(true)}
          >
            الغاء
          </button>
        </div>
      </div>
    ) : (
      <div className="row mb-4 ">
        <div className="col-lg-6 col-sm-12 mt-sm-3">
          <img src={CertificateUrl} alt="" style={{ maxWidth: "100%" }} />
        </div>
        <div className="col-lg-6 col-sm-12 mt-sm-3">
          <State
            hasIconStroke
            color="#af151f"
            iconSize="50"
            icon={<CongratulationIcon />}
          >
            <p className="font_15 font-color-grey mb-1">شهادة اتمام الدورة</p>
            <h1 className="font_24 font-extrabold">
              مبرووووك يا {currentUser?.name}
            </h1>
            <p className="font_14 font-color-grey mb-0">
              لقد فعلتها ! لقد نجحت في إتمام الدورة بنجاح
            </p>
            <p className="font_14 font-color-grey">
              يمكنك الآن تحميل الشهادة ومشاركتها مع أصدقائك
            </p>
            <a
              className="btn btn-main-color font-15 mb-3"
              style={{ width: "100%" }}
              href={CertificateUrl}
              target="_blank"
              // onClick={() => downloadImage(CertificateUrl)}
            >
              <img
                className="me-2"
                alt=""
                src="/images/downloadFile.svg"
                style={{ filter: "brightness(0) invert(1)" }}
                width="20px"
              />
              تحميل الشهادة
            </a>
            <button
              className="btn btn-grey-color font-15 "
              style={{ width: "100%" }}
              onClick={() => setIsSharingCertificate(true)}
            >
              <i className="fas fa-share fa-fw me-2"></i>
              مشاركة الشهادة على المنتدى
            </button>
          </State>
        </div>
      </div>
    );
  };

  return (
    <Modal
      id="congratsCertificate"
      className=""
      title={isSharingCertificate ? "مشاركة على المنتدى" : null}
      maxWidth={isSharingCertificate ? "550" : "1000"}
      closeModal={closeModal}
      onClose={() => {
        setCloseModal(false);
        setIsSharingCertificate(false);
      }}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default CongratsCertificateModal;
