import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import HeaderPage from "../../components/HeaderPage";
import VideoPlayer from "../../components/VideoPlayer";
import VideoJS from "../../components/VideoJs";
import FileDownload from "../../components/inputs/FileDownload";
import SideBarCourseContent from "../../components/SideBarCourseContent";
import StartCourseExamCard from "../../components/cards/Exam/StartCourseExamCard";
import {
  useGetCourseQuery,
  useCreateLessonLogMutation,
  useLazyGetLessonQuery,
} from "../../services/users/courses";
import AboveCourseExamCard from "../../components/cards/Exam/AboveCourseExamCard";
import CourseExamContent from "../../components/cards/Exam/CourseExamContent";
import LoadingIndicator from "../../components/LoadingIndicator";
import EmptyState from "../../components/EmptyState";
import { useTranslation } from "react-i18next";

const CoursePage = () => {
  const [currentLesson, setCurrentLesson] = useState();
  const [isAboveVideoExam, setIsAboveVideoExam] = useState(false);
  const [isExamStart, setIsExamStart] = useState(false);
  const [examQuestions, setExamQuestions] = useState([]);
  // const [inVideoQuestions, setInVideoQuestions] = useState([]);
  const [currentInVideoQuestion, setCurrentInVideoQuestion] = useState();

  const [currentLogs, setCurrentLogs] = useState();
  const lessonLogs = useRef();
  const inVideoQuestions = useRef();

  const { t } = useTranslation(["common", "learningPaths"]);

  let { id } = useParams();
  const location = useLocation();
  const {
    data: courseDetails,
    isLoading: isCourseLoading,
    refetch,
  } = useGetCourseQuery(id);
  const [getLesson, { data: lessonDetails }] = useLazyGetLessonQuery();
  const [createLessonLog] = useCreateLessonLogMutation();

  console.log("parent rerenders");

  useEffect(() => {
    if (currentLesson?.questions?.length > 0) {
      inVideoQuestions.current = currentLesson?.questions?.map((question) => {
        let timeSplitted = question.time.split(":");
        let timeInSeconds =
          parseInt(timeSplitted[0]) * 60 + parseInt(timeSplitted[1]);
        return {
          id: question.id,
          archived: question.archived,
          question: question.question,
          answers: question.answers,
          time: question.time,
          timeInSeconds,
        };
      });
    }
  }, [currentLesson]);

  useEffect(() => {
    if (courseDetails) {
      if (!currentLesson)
        setCurrentLesson(courseDetails?.data?.sections[0]?.lessons[0]);
      lessonLogs.current = courseDetails?.data?.lesson_logs;
      setCurrentLogs(courseDetails?.data?.lesson_logs);
    }
  }, [courseDetails]);

  const handleStartingExam = async () => {
    let lesson = await getLesson(currentLesson?.id);
    if (!lesson?.error) {
      setExamQuestions(lesson?.data?.data?.questions);
      setIsExamStart(true);
    }
  };

  if (isCourseLoading) return <LoadingIndicator />;
  if (!courseDetails?.data?.sections?.length)
    return (
      <div style={{ marginTop: "200px" }}>
        <EmptyState
          iconComponent={<i className="far fa-play-circle" />}
          title={t("emptyStates.videos")}
          emptyStateText={t("learningPaths:emptyVideoCourse")}
        />
      </div>
    );

  return (
    <div>
      <HeaderPage
        progressBarFullDetails={"مكتمل من الدورة"}
        // progressBarPercentageText={location.state?.ProgressBarPercentage}
        progressBarPercentageText={`${courseDetails?.data?.enrollment?.progress}%`}
        sectionTitle={courseDetails?.data?.title}
        listItems={[
          {
            name: "مسارات التعلم",
            href: "/employee/learning_paths",
          },
          {
            name: location.state?.learningPathName,
            href: `/employee/learning_paths/${location.state?.learningPathId}`,
          },
          {
            name: courseDetails?.data?.title,
            href: "#",
          },
        ]}
      />
      <div className="container-fluid">
        <div className="row course-page">
          <SideBarCourseContent
            lessonLogs={currentLogs}
            sections={courseDetails?.data?.sections}
            currentLesson={currentLesson}
            setCurrentLesson={setCurrentLesson}
            createLessonLog={createLessonLog}
          />

          <div className="col-lg-8 col-md-12 content-coure-page p-4">
            {/* Content */}
            {currentLesson && currentLesson?.type != "Quiz" && (
              <h1 className="font_20 font-extrabold">{currentLesson?.name}</h1>
            )}

            {currentLesson?.type == "Video" && (
              <div
                className={`video-main-container ${
                  isAboveVideoExam ? "open-exam-card" : ""
                }`}
              >
                <VideoJS
                  createLessonLog={createLessonLog}
                  currentLessonId={currentLesson.id}
                  currentLessonType={currentLesson?.type}
                  currentLessonVideoSrc={currentLesson?.video_source[0].src}
                  setIsAboveVideoExam={setIsAboveVideoExam}
                  inVideoQuestions={inVideoQuestions}
                  setCurrentInVideoQuestion={setCurrentInVideoQuestion}
                  forwardedRef={lessonLogs}
                />
                {isAboveVideoExam && (
                  <AboveCourseExamCard
                    currentInVideoQuestion={currentInVideoQuestion}
                    setIsAboveVideoExam={setIsAboveVideoExam}
                    createLessonLog={createLessonLog}
                    currentLessonId={currentLesson.id}
                    lessonLogs={lessonLogs.current.find(
                      (log) => log.lesson_id == currentLesson.id
                    )}
                  />
                )}
              </div>
            )}

            {currentLesson?.type == "ExplanationFile" && (
              <embed
                src={currentLesson?.file_url}
                // src="https://www.africau.edu/images/default/sample.pdf"
                width="800px"
                height="600px"
              />
            )}

            {currentLesson?.type == "Quiz" &&
              (isExamStart ? (
                <CourseExamContent
                  questions={examQuestions}
                  createLessonLog={createLessonLog}
                  currentLessonId={currentLesson?.id}
                  refetch={refetch}
                  setCurrentLesson={setCurrentLesson}
                  getLesson={getLesson}
                />
              ) : (
                <StartCourseExamCard
                  duration={currentLesson?.quiz_time}
                  numberOfQuestion={currentLesson?.questions_count}
                  handleStartingExam={handleStartingExam}
                  examTitle={currentLesson?.name}
                  currentLessonLog={courseDetails?.data?.lesson_logs?.find(
                    (lesson_log) => lesson_log?.lesson_id == currentLesson?.id
                  )}
                />
              ))}

            {/* <VideoPlayer src={currentLesson?.video_source[0].src} /> */}

            {/* <div className="card-style">
              <p className="font_16 font-bold font-color-red">ملفات شرح </p>
              {fileSection?.lessons?.map((lesson, i) => (
                <>
                  {i > 0 ? <hr /> : null}
                  <FileDownload name={lesson?.name} url={lesson?.file_url} />
                </>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePage;
