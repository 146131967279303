import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

export default function Modal({
  children,
  title,
  subTitle,
  id,
  mainBtn,
  mainBtnOnclick,
  className,
  maxWidth,
  onClose,
  isOpened,
  hasCloseBtn,
  closeModal,
  isVerticallyCentered
}) {
  const buttonRef = useRef();

  useEffect(() => {
    if (buttonRef.current && !isOpened) {
      buttonRef.current.click();
    }
  }, [isOpened]);

  useEffect(() => {
    if (closeModal) {
      buttonRef.current.click();
    }
  }, [closeModal]);

  useEffect(() => {
    return () => {
      const modalBackdrop = document.querySelector(".modal-backdrop.fade.show");
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    };
  }, []);

  return (
    <div
      className={`modal main-modal ${className} fade`}
      id={id}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className={`modal-dialog ${isVerticallyCentered ? "modal-dialog-centered": ""}`} style={{ maxWidth: maxWidth + "px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <div>
              {title && (
                <h5
                  className="modal-title font-extrabold"
                  id="staticBackdropLabel"
                >
                  {title}
                </h5>
              )}
              {subTitle && (
                <p className="font_16 font-color-grey">{subTitle}</p>
              )}
            </div>
            {hasCloseBtn && (
              <button
                ref={buttonRef}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <span className="fas fa-times fa-fw"></span>
              </button>
            )}
          </div>

          <div className="modal-body">{children}</div>

          {mainBtn && (
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
              <button
                type="button"
                className="btn btn-main-color w-100"
                onClick={mainBtnOnclick}
              >
                {mainBtn}
              </button>
            </div>
          )}
        </div>
      </div>
      <button
        id="dismiss-modal-btn"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={() => console.log("clickedd22")}
        style={{ display: "none" }}
      ></button>
    </div>
  );
}

Modal.propTypes = {
  hasCloseBtn: PropTypes.bool,
  isVerticallyCentered: PropTypes.bool
};

Modal.defaultProps = {
  hasCloseBtn: true,
  isVerticallyCentered: false
};
