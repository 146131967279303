import React from "react";
import { useTranslation } from "react-i18next";

const MyPostsStatisticsCard = ({ statistics, title }) => {
  const { t } = useTranslation("communityFeed");
  return (
    <div className="card-style posts-statistics-card">
      <div>
        <h1 className="font_20 font-extrabold">
          {" "}
          {title ? title : t("communityFeed:myPosts")}
        </h1>
        <ul className="d-flex">
          <li className="me-auto">
            <div className="icon me-2">
              <span className="fas fa-paper-plane fa-fw"></span>
            </div>
            <div>
              <h1 className="mb-0 font_14 font-extrabold">
                {t("communityFeed:thePost")}
              </h1>
              <p className="mb-0 font_16 mt-1 font-extrabold font-color-grey">
                {statistics?.posts_count}
              </p>
            </div>
          </li>
          <li className="me-auto">
            <div className="icon me-2">
              <span className="fas fa-heart fa-fw"></span>
            </div>
            <div>
              <h1 className="mb-0 font_14 font-extrabold">
                {t("communityFeed:likes")}
              </h1>
              <p className="mb-0 font_16 mt-1 font-extrabold font-color-grey">
                {statistics?.likes_count}
              </p>
            </div>
          </li>
          <li className="me-auto">
            <div className="icon me-2">
              <span className="fas fa-comment-alt fa-fw"></span>
            </div>
            <div>
              <h1 className="mb-0 font_14 font-extrabold">
                {t("communityFeed:comments")}
              </h1>
              <p className="mb-0 font_16 mt-1 font-extrabold font-color-grey">
                {statistics?.comments_count}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MyPostsStatisticsCard;
