import { useState, useMemo } from "react";

const useCoursesFilter = () => {
  const [topic, setTopic] = useState("");
  const [level, setLevel] = useState("");

  const topicFilter = useMemo(() => {
    return topic ? `filters[topic]=${topic}` : "";
  }, [topic]);

  const levelFilter = useMemo(() => {
    return level ? `filters[level]=${level}` : "";
  }, [level]);

  const queryParams = useMemo(() => {
    const params = [topicFilter, levelFilter].filter(Boolean).join("&");
    return params ? `${params}` : "";
  }, [topicFilter, levelFilter]);

  return [queryParams, setTopic, setLevel];
};

export default useCoursesFilter;
