import InputSearch from "../../inputs/InputSearch";
import TableAdmin from "../../Tables/TableAdmin";
import { useAdminGetAdminsListQuery } from "../../../services/organization/settings";
import InviteAdminModal from "../../Modals/InviteAdminModal";
import { useState, useEffect } from "react";
import Pagination from "../../Tables/Pagination";
import { useTranslation } from "react-i18next";

const SiteAdministration = () => {
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { data: adminsList } = useAdminGetAdminsListQuery({
    searchKeyword: searchKeyword,
    page,
  });
  const { t } = useTranslation("settings");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <div className="py-4 site-administration">
      <InviteAdminModal />

      <div className="header-table">
        <div className="d-lg-flex flex-wrap align-items-center justify-content-between">
          <div className="left-side">
            <h3 className="font_20 font-extrabold ">
              {t("settings:admins", { number: adminsList?.data?.length })}
            </h3>
            <p className="font_16 font-color-grey">
              {t("settings:addAdminHint")}
            </p>
          </div>

          <div className="right-side d-lg-flex">
            <ul className="list-filter d-flex">
              <li className="me-2">
                <InputSearch
                  placeHolder={t("settings:adminSearch")}
                  value={searchKeyword}
                  onChange={(e) => {
                    setPage(1);
                    setSearchKeyword(e.target.value);
                  }}
                />
              </li>

              <li>
                <button
                  type="button"
                  className="btn btn-dark-color font-15"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropAdmin"
                >
                  <span className="fas fa-user-plus fa-fw me-2"></span>
                  {t("settings:adminInvite")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <TableAdmin usersList={adminsList} />
        {adminsList?.data?.length != 0 ? (
          <Pagination
            current={adminsList?.pagination?.current}
            pages={adminsList?.pagination?.pages}
            handlePagination={(page) => setPage(page)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SiteAdministration;
