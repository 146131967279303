import { api } from "../index";

export const community = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserPosts: builder.query({
      query: ({ page, userID }) =>
        `/api/v1/user/users/${userID}/posts?page=${page}&per_page=10`,
      providesTags: ["posts"],
    }),

    getUserProfileData: builder.query({
      query: (userID) => `/api/v1/user/users/${userID}`,
      providesTags: [{ type: "userProfile" }],
    }),

    getUserCompletedCourses: builder.query({
      query: (userId) =>
        `/api/v1/user/users/${userId}/courses/certificates`,
    }),
  }),
});

export const { useLazyGetUserPostsQuery, useGetUserProfileDataQuery, useGetUserCompletedCoursesQuery } =
  community;
