import React, { useEffect, useState } from "react";
import TableEmployee from "../../components/Tables/TableEmployee";
import InputSearch from "../../components/inputs/InputSearch";
import SelectWithIcon from "../../components/inputs/SelectWithIcon";
import Pagination from "../../components/Tables/Pagination";
import { useAdminListUsersQuery } from "../../services/organization/adminUsers";
import InviteEmployeeModal from "../../components/Modals/InviteEmployeeModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";

const Employees = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filterKeyword, setFilterKeyword] = useState("");
  const [filters, setFilters] = useState([]);

  const [page, setPage] = useState(1);

  const { t } = useTranslation(["common", "employees"]);

  const {
    data: usersList,
    isLoading: isListUsersLoading,
    refetch,
  } = useAdminListUsersQuery({
    filters: filters.join("&"),
    page,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    let result = [];
    if (searchKeyword)
      result.push(`filters[keyword]=${encodeURIComponent(searchKeyword)}`);
    if (filterKeyword) result.push(`sort=${filterKeyword}`);
    setFilters(result);
  }, [searchKeyword, filterKeyword]);

  const onInvitationSuccess = () => {
    setSearchKeyword("");
    setFilterKeyword("-created_at");
    setFilters([`sort=-created_at`]);
    setPage(1);
    refetch();
  };

  if (isListUsersLoading) return <LoadingIndicator />;
  return (
    <div className="employee ranks">
      <InviteEmployeeModal onInvitationSuccess={onInvitationSuccess} />
      <div
        className={`sticky-header header-table ${
          usersList?.data?.length === 0 ? "" : "top-sticky-header"
        }`}
      >
        <div className="d-lg-flex flex-wrap">
          <div className="left-side">
            <h1 className="title-page ">{t("employees:theEmployees")}</h1>
            <ul className="d-flex">
              <li className="me-2">
                <span className="label label-black">
                  {" "}
                  <span className="me-2">
                    {usersList?.users_registered_count}
                  </span>
                  {t("employees:registeredEmployee")}
                </span>
              </li>
              <li className="me-2">
                <span className="label label-red">
                  {" "}
                  <span className="me-2">{usersList?.users_invited_count}</span>
                  {t("employees:pendingRegisteration")}
                </span>
              </li>
            </ul>
          </div>

          <div className="ms-auto right-side">
            <ul className="list-filter">
              <li className="me-2">
                <InputSearch
                  placeHolder={t("employees:searchForEmployee")}
                  value={searchKeyword}
                  onChange={(e) => {
                    setPage(1);
                    setSearchKeyword(e.target.value);
                  }}
                />
              </li>
              <li className="me-2">
                <SelectWithIcon
                  value={filterKeyword}
                  onChange={(e) => {
                    setPage(1);
                    setFilterKeyword(e.target.value);
                  }}
                >
                  <option defaultValue>{t("sorting.sortBy")}</option>
                  <option value="name">{t("sorting.alphabitically")}</option>
                  <option value="-name">
                    {t("sorting.alphabiticallyReversed")}
                  </option>
                  <option value="-created_at">{t("sorting.newFirst")}</option>
                  <option value="created_at">{t("sorting.oldFirst")}</option>
                </SelectWithIcon>
              </li>

              <li>
                <button
                  type="button"
                  className="btn btn-main-color font-15"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  <span className="fas fa-user-plus fa-fw me-2"></span>
                  {t("inviteNewEmployee")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="content-page__wrapper">
        <TableEmployee usersList={usersList} />

        {usersList?.data?.length != 0 ? (
          <Pagination
            current={usersList?.pagination?.current}
            pages={usersList?.pagination?.pages}
            handlePagination={(page) => setPage(page)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Employees;
