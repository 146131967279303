import React from "react";

export default function Switch({
  label,
  disabled,
  checked,
  onChange,
  activeText,
  disActiveText,
}) {
  return (
    <div className="form-check form-switch">
      <label
        className="form-check-label font_14 font-bold me-2"
        htmlFor="flexSwitchCheckDefault"
      >
        {label}
      </label>
      <input
        className="form-check-input"
        type="checkbox"
        id="flexSwitchCheckDefault"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
}
