import { api } from "../index";

export const courses = api.injectEndpoints({
  endpoints: (builder) => ({
    getCourse: builder.query({
      query: (courseId) => `/api/v1/user/courses/${courseId}`,
      providesTags: [{ type: "Course" }],
    }),

    createLessonLog: builder.mutation({
      query: (body) => ({
        url: `/api/v1/user/lesson_logs`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Course" }],
    }),

    getLesson: builder.query({
      query: (lessonId) => `/api/v1/user/lessons/${lessonId}`,
    }),
  }),
});

export const {
  useGetCourseQuery,
  useCreateLessonLogMutation,
  useLazyGetLessonQuery,
} = courses;
