import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SideBar = ({ items }) => {
  const [sideBarShown, setSideBarShown] = useState(false);

  const { t } = useTranslation(["learningPaths"]);

  const toggleSideBarVisiblity = () => {
    setSideBarShown(sideBarShown ? false : true);
  };

  return (
    <div className={`col-lg-3 sidebar-main ${sideBarShown ? "show" : "hide"}`}>
      <button className="sidebar-main-toggle" onClick={toggleSideBarVisiblity}>
        <div className={`hamburger ${sideBarShown ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </button>
      <div className="sidebar-main__content">
        <ul>
          {items.map((item) => (
            <li key={item?.id || item?.text} className={`${item?.active ? "active" : ""}`}>
              <Link to={item?.link}>
                {item?.icon}
                <p>{item?.text}</p>
              </Link>
            </li>
          ))}
        </ul>

        <Link to="/admin/discover-courses" className="explore-link">
          {t("learningPaths:discoverCourses")} <span className="fas fa-arrow-right fa-fw"></span>
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
