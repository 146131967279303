import React, { useState, useEffect } from "react";
import Avatar from "../inputs/Avatar";
import ProgressBar from "../../components/ProgressBar";
import InputComment from "../inputs/InputComment";
import CommentUserCard from "./CommentUserCard";
import { useLazyListCommentsQuery } from "../../services/public/comments";
import { useTranslation } from "react-i18next";

const PostCommunityComment = ({
  post,
  handleToggleLike,
  handleSubmittingComment,
}) => {
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(2);
  const [listComments] = useLazyListCommentsQuery();
  const { t } = useTranslation("communityFeed");

  useEffect(() => {
    if (post?.comments) {
      setComments(post?.comments);
      setPage(2);
    }
  }, [post]);

  return (
    <div className="post-community-card__comment">
      <ul className="actions-list">
        <li className="me-4">
          <button
            className={`${
              post?.is_liked_by_me
                ? "fas font-color-red"
                : "far font-color-grey"
            } font_20 fa-heart fa-fw me-2`}
            onClick={() =>
              handleToggleLike({
                postId: post?.id,
                isLikedByMe: post?.is_liked_by_me,
              })
            }
            // onClick={() => setIsLike(!isLike)}
          ></button>
          <p className="mb-0 font_16 font-bold">
            <span className="">{post?.likes_count} </span>{" "}
            {t("communityFeed:like")}
          </p>
        </li>
        <li>
          <span
            className={`fas icon fa-comment-alt font_20 fa-fw me-2 font-color-grey`}
          ></span>
          <p className="mb-0 font_16 font-bold">
            <span className="">{post?.comments_count} </span>{" "}
            {t("communityFeed:comment")}
          </p>
        </li>
      </ul>
      <InputComment
        postId={post?.id}
        handleSubmittingComment={handleSubmittingComment}
      />
      <div className="comments-wrapper">
        {comments?.length == 0 ? (
          <div className="h-100 d-flex flex-column justify-content-center align-center mt-4">
            <p className="mb-0 font_16 font-bold font-color-grey">
              {t("communityFeed:noComments")}
            </p>
            <p className="mb-0 font_14 font-color-grey-light">
              {t("communityFeed:addFirstComment")}
            </p>
          </div>
        ) : (
          <div>
            <ul className="comments-list">
              {comments?.map((comment) => (
                <li>
                  <CommentUserCard comment={comment} />
                </li>
              ))}
            </ul>
            {comments.length < post?.comments_count ? (
              <button
                className="mt-3"
                onClick={async () => {
                  const result = await listComments({ postId: post?.id, page });
                  setPage((prev) => ++prev);
                  if (!result.error) {
                    setComments((prev) => [...prev, ...result?.data?.data]);
                  }
                }}
              >
                <p className="mb-0 font_14 font-bold font-color-grey">
                  {t("communityFeed:showMoreComments")}{" "}
                  <span className="fas fa-chevron-down fa-fw me-2"></span>
                </p>
              </button>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default PostCommunityComment;
