import PropTypes from "prop-types";

const EmployeeQuizAnswerCard = ({ quiz, numberOfQuestion, correctAnswer, employeeAnswer }) => {
  const checkAnswerState = (answer) => {
    if (answer === correctAnswer) {
      return <span className="label label-green font_16 font-bold">إجابة صحيحة</span>
    } else if (answer === "") {
      return <span className="label label-grey font_16 font-bold">غير مجاب</span>
    }
    return <span className="label label-red font_16 font-bold">إجابة خاطئة</span>
  }

  const checkCorrectAnswer = (answer) => (employeeAnswer !== '' && (correctAnswer || employeeAnswer) === answer ? true : false);
  const checkWrongAnswer = (answer) => (employeeAnswer !== '' && (correctAnswer && employeeAnswer) === answer ? true : false);

  return (
    <div className="card-style employee-card-quiz">
      <div className="d-flex gap-2 mb-4 flex-column flex-sm-row align-items-center">
        <p className="font_16 font-color-grey mb-0 me-2"> السؤال {numberOfQuestion}</p>
        {checkAnswerState(employeeAnswer)}
      </div>
      <h3 className="font_20 font-extrabold"> {quiz.title} </h3>
      <ul className="admin-card-quiz__answers">

        {
          quiz.answers.map((answer, i) => (
            <li key={answer.id} className="d-flex  ">
              <div className={`d-flex align-items-center ${checkCorrectAnswer(answer) && 'label label-green font-color-dark py-2'} ${checkWrongAnswer(answer) && 'label label-red py-2'}`}>
                <span className={`font-color-grey-light`}>
                  <i className={`font_16 fas fa-${correctAnswer === answer ? 'check' : 'times'} ${checkCorrectAnswer(answer) && 'font-color-green'} ${checkWrongAnswer(answer) && 'font-color-red'}`} />
                </span>
                <p className={`font_16 font-bold px-3 mb-0 font-color-grey ${checkCorrectAnswer(answer) && checkWrongAnswer(answer) ? 'font-color-dark' : ''}`} >
                  {answer}
                </p>
              </div>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default EmployeeQuizAnswerCard;

EmployeeQuizAnswerCard.propTypes = {
  numberOfQuestion: PropTypes.number,
  correctAnswer: PropTypes.string,
  employeeAnswer: PropTypes.string,
  quiz: PropTypes.shape({
    title: PropTypes.string,
    course: PropTypes.string,
    lesson: PropTypes.string,
    time: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
      })
    )
  })
}