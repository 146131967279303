import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast, Zoom } from "react-toastify";
import {
  useGetAdminLearningTrackByIdForEditQuery,
  useUpdateLearningTrackMutation,
  useAddCourseToLearningTrackMutation,
  useRemoveCourseFromLearningTrackMutation,
} from "../../services/organization/adminLearningTrack";
import Details from "../../components/LearningTracks/Admin/MutationPages/Tabs/Details";
import Courses from "../../components/LearningTracks/Admin/MutationPages/Tabs/Courses";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";

export default function EditLearningPath() {
  const TABS = {
    DETAILS: 1,
    COURSES: 2,
  };

  let navigate = useNavigate();
  const { learningPathId } = useParams();
  const [isValidated, setIsValidated] = useState(false);
  const [submmiting, setSubmmiting] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TABS.DETAILS);
  const [formData, setFormData] = useState(null);
  const { data: learningTrackDetails, isLoading: isLoadingDetails } =
    useGetAdminLearningTrackByIdForEditQuery(learningPathId);
  const [updateLearningTrack] = useUpdateLearningTrackMutation();

  const [courses, setCourses] = useState(
    learningTrackDetails?.data?.courses || []
  );
  const [deletedCoursesIds, setDeltedCoursesIds] = useState([]);
  const [addCourseToLearningTrack] = useAddCourseToLearningTrackMutation();
  const [removeCourseFromLearningTrack] =
    useRemoveCourseFromLearningTrackMutation();
  let learningTrackCourseIdsSet = new Set(
    learningTrackDetails?.data?.courses?.map((course) => course.id)
  );

  const { t } = useTranslation(["common", "learningPaths"]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setCourses(learningTrackDetails?.data?.courses);
  }, [learningTrackDetails?.data?.courses]);

  // listen  for change in isValidated state and submit if valid.
  useEffect(() => {
    async function submit() {
      try {
        setSubmmiting(true);
        if (formData) {
          await updateLearningTrack({
            learningTrackId: learningPathId,
            body: formData,
          });
          if (formData.image) {
            const data = new FormData();
            data.append("image", formData.image);
            await updateLearningTrack({
              learningTrackId: learningPathId,
              body: data,
            });
          }
        }
        const courseIds = courses
          .map((course) => course.id)
          .filter((courseId) => !learningTrackCourseIdsSet.has(courseId));
        if (courseIds.length) {
          addCourseToLearningTrack({
            learningTrackId: learningPathId,
            body: { course_ids: courseIds },
          });
        }
        if (deletedCoursesIds.length) {
          removeCourseFromLearningTrack({
            learningTrackId: learningPathId,
            body: { course_ids: deletedCoursesIds },
          });
        }

        toast.success(t("successfulSave"));
        navigate(`/admin/learning_paths/${learningPathId}`);
        setSubmmiting(false);
      } catch (e) {
        toast.error(e?.data?.message);
        setSubmmiting(false);
      }
    }
    if (!isValidated) return;
    submit();
  }, [isValidated]);

  function onSubmit() {
    setSubmit(true);
    if (!formData) {
      setIsValidated(true);
    }
  }

  if (isLoadingDetails) {
    return <></>;
  }

  return (
    <div>
      <div className="sticky-header header-table no-padding-b">
        <div className="d-flex align-center flex-wrap justify-content-between">
          <div className="left-side">
            <h1 className="title-page mt-1">{t("learningPaths:EditPath")}</h1>
          </div>

          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className="nav-link active p-3"
              id="learning-track-details-tab"
              data-bs-toggle="tab"
              data-bs-target="#learning-track-details"
              type="button"
              role="tab"
              aria-controls="learning-track-details"
              aria-selected="true"
              onClick={() => {
                setSelectedTab(TABS.DETAILS);
              }}
            >
              {t("learningPaths:pathDetails")}{" "}
            </button>
            <button
              className="nav-link p-3"
              id="learning-track-courses-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-learning-track-courses"
              type="button"
              role="tab"
              aria-controls="nav-learning-track-courses"
              aria-selected="false"
              onClick={() => {
                setSelectedTab(TABS.COURSES);
              }}
            >
              {t("learningPaths:pathCourses")}
            </button>
          </div>

          <div className="right-side">
            <ul className="list-filter">
              <li style={{ marginLeft: "16px" }}>
                <button
                  type="button"
                  className="btn btn-main-color font-15  min-w-140 mt-3 mt-md-0"
                  onClick={onSubmit}
                  disabled={submmiting}
                >
                  {t("learningPaths:savePath")}
                  {submmiting && (
                    <Spinner
                      style={{
                        marginRight: "10px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  )}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn btn-grey-color font-15  min-w-140 mt-3 mt-md-0"
                  onClick={() =>
                    navigate(`/admin/learning_paths/${learningPathId}`)
                  }
                >
                  {t("cancel")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {selectedTab === TABS.DETAILS && (
        <Details
          submit={submit}
          setSubmit={setSubmit}
          setIsValidated={setIsValidated}
          formData={formData}
          setFormData={setFormData}
          inputInititalValues={learningTrackDetails.data}
        />
      )}

      {selectedTab === TABS.COURSES && (
        <Courses
          courses={courses}
          setCourses={setCourses}
          deletedCoursesIds={deletedCoursesIds}
          setDeltedCoursesIds={setDeltedCoursesIds}
        />
      )}
    </div>
  );
}
