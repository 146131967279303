import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PostCommunityCard from "../../../components/cards/PostCommunityCard";
import {
  useLikePostMutation,
  useUnlikePostMutation,
} from "../../../services/users/community";
import MyPostsStatisticsCard from "../../cards/MyPostsStatisticsCard";
import {
  useLazyAdminGetUserPostsQuery,
  useAdminGetUserPostStatsQuery,
} from "../../../services/organization/adminUsers";
import LoadingIndicator from "../../LoadingIndicator";
import { useTranslation } from "react-i18next";

const Posts = ({ user }) => {
  const [allPosts, setAllPosts] = useState([]);
  const [page, setPage] = useState(1);
  const { t } = useTranslation(["common", "communityFeed"]);
  const [like] = useLikePostMutation();
  const [unlike] = useUnlikePostMutation();

  const { data: postStats } = useAdminGetUserPostStatsQuery(user?.id);
  const [listPosts, { data: posts, isLoading: isGetUserPostsLoading }] =
    useLazyAdminGetUserPostsQuery();

  useEffect(() => {
    listPosts({ userId: user?.id, page });
  }, [page]);

  useEffect(() => {
    if (posts?.data?.length) {
      if (posts?.pagination?.current == 1) {
        setAllPosts(posts?.data);
      } else {
        setAllPosts((prev) => {
          return [...prev, ...posts?.data];
        });
      }
    }
  }, [posts]);

  const handleToggleLike = async ({ postId, isLikedByMe }) => {
    let result;
    if (isLikedByMe) {
      result = await unlike(postId);
    } else {
      result = await like(postId);
    }
    if (!result?.error) await updateSinglePost(postId);
  };

  const updateSinglePost = async (postId) => {};

  if (isGetUserPostsLoading) return <LoadingIndicator />;

  return (
    <div className="profile-about">
      <h1 className="font_20 font-extrabold mb-4">{t("allPosts")}</h1>

      <div className="row community-page">
        <div className="col-lg-8 col-sm-12">
          {allPosts?.length > 0 ? (
            <ul>
              <InfiniteScroll
                className="feed-list"
                dataLength={allPosts.length}
                next={() => setPage(posts?.pagination?.next)}
                hasMore={posts?.pagination?.next}
                loader={<h4>{t("Loading")}</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>{t("communityFeed:successfulPostsDisplay")}</b>
                  </p>
                }
                style={{
                  overflow: "unset",
                }}
              >
                {allPosts.map((post) => {
                  return (
                    <li className="mb-3">
                      <PostCommunityCard
                        post={post}
                        handleToggleLike={handleToggleLike}
                        handleSubmittingComment={() =>
                          console.log("submitting comment")
                        }
                        handleDeletingPost={() =>
                          console.log("deleting postss")
                        }
                      />
                    </li>
                  );
                })}
              </InfiniteScroll>
            </ul>
          ) : (
            <p style={{ textAlign: "center" }}>
              <b>{t("communityFeed:noPostsYet")}</b>
            </p>
          )}
        </div>
        <div className="col-lg-4 col-sm-12 mb-4">
          <MyPostsStatisticsCard
            title={t("communityFeed:interactions")}
            statistics={postStats?.data}
          />
        </div>
      </div>
    </div>
  );
};

export default Posts;
