import React from "react";

export default function UploadProfile({
  avatarUrl = "/images/profile.png",
  title,
  IconRightAligned,
  image,
  setImage,
}) {
  return (
    <div className="upload-profile">
      <div className="upload-profile__container">
        <img
          className="img"
          alt=""
          src={image ? URL.createObjectURL(image) : avatarUrl}
        />
        <button
          className="edit-btn"
          type="button"
          style={IconRightAligned ? { right: "-10px" } : { left: "-10px" }}
          onClick={() => document.getElementById("add-image").click()}
        >
          <span className="fas fa-pen fa-fw"></span>
          <input
            accept="image/png, image/gif, image/jpeg"
            type="file"
            id="add-image"
            style={{ display: "none" }}
            onChange={(e) => setImage(e.target.files.item(0))}
          />
        </button>
      </div>
      {title && <p>{title}</p>}
    </div>
  );
}
