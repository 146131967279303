import React from "react";
import { useTranslation } from "react-i18next";

export default function EmployeeScoreCard({ points }) {
  const { t } = useTranslation(["common"]);

  return (
    <div className="emp-score-card card-style">
      <span className="font_31 fas fa-star fa-fw mb-2 font-color-gold"></span>
      <h1 className="font_16 font-bold mb-2">
        <span className="font_24 font-extrabold">{points}</span> {t("points")}
      </h1>
      <p className="font_16 font-color-grey">{t("wantMorePoints")}</p>
    </div>
  );
}
