const Logout = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="20.5" viewBox="0 0 20.5 20.5">
    <g id="log-out" transform="translate(-1.75 -1.75)">
      <path id="Path_34358" data-name="Path 34358" d="M9,21H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H9" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
      <path id="Path_34359" data-name="Path 34359" d="M16,17l5-5L16,7" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
      <line id="Line_884" data-name="Line 884" x1="12" transform="translate(9 12)" fill="none" stroke="#bbbabf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
    </g>
  </svg>
)

export default Logout;
