import React, { useState } from "react";
import { useAdminGetUserCompletedCoursesQuery } from "../../../services/organization/adminUsers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../LoadingIndicator";

const Cert = ({ user }) => {
  const { data: completedCourses, isLoading: isCompletedCoursesLoading } =
    useAdminGetUserCompletedCoursesQuery(user?.id);
  const { t } = useTranslation("common");

  const currentUser = useSelector((state) => state.auth.currentUser);

  const [isshowImage, setisShowImage] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const onClose = () => {
    setisShowImage(false);
  };

  if (isCompletedCoursesLoading) return <LoadingIndicator />;

  return (
    <div className="profile-about">
      <div className="row">
        <h1 className="font_20 font-extrabold mb-1">{t("certificates")}</h1>
        <p className="font_14 font-color-grey mb-4">
          {completedCourses?.pagination?.count} {t("completedCoursesCount")}
        </p>

        {completedCourses?.data?.map((completedCourse) => (
          <div className="col-lg-4 col-sm-12 mb-4">
            <button
              className="show-image"
              onClick={() => {
                setisShowImage(true);
                setCurrentImage(completedCourse?.enrollment?.certificate_url);
              }}
            >
              <div className="show-image__text">
                <i className="far fa-eye font_22"></i>
                <p className="font_12 font-extrabold">
                  {" "}
                  {t("showCertificate")}{" "}
                </p>
              </div>
              <img
                src={completedCourse?.enrollment?.certificate_url}
                className="w-100"
                alt={`${completedCourse?.enrollment?.certificate_url}`}
              />
            </button>
            <div
              className={`preview-image-container ${
                isshowImage ? "preview-image-container--show" : ""
              }`}
            >
              <div className="preview-image-container__content">
                <div className="d-flex justify-content-between mb-3 align-items-center">
                  <h4 className="font_18 font-bold"> {t("certificate")} </h4>
                  <button className="btn btn-main-color" onClick={onClose}>
                    <i className="fas fa-times" />
                  </button>
                </div>
                <img
                  src={currentImage}
                  className="w-100"
                  alt={`${completedCourse?.enrollment?.certificate_url}`}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cert;
