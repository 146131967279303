import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import InputFieldFloat from "../components/inputs/InputFieldFloat";
import PasswordInputFloat from "../components/inputs/PasswordInputFloat";
import Checkbox from "../components/inputs/Checkbox";
import {
  useAdminRegisterMutation,
  useAdminShowTokenQuery,
} from "../services/organization/auth";
import { useCookies } from "react-cookie";

const AdminInvitation = () => {
  const [checked, setChecked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [register] = useAdminRegisterMutation();
  const { data: showToken } = useAdminShowTokenQuery(token);
  const [cookies] = useCookies(["cookie-name"]);

  let navigate = useNavigate();
  const { t } = useTranslation(["common", "auth"]);

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(3, t("validations.passwordMin3"))
      .required(t("validations.passwordRequired")),
    name: yup.string().required(t("validations.nameRequired")),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      name: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const result = await register({
        invitation_token: token,
        admin: {
          password: values.password,
          name: values.name,
        },
      });

      if (result?.error) {
        toast.error(result?.error?.data?.message);
      } else {
        localStorage.setItem("token", result?.data?.data?.token);
        localStorage.setItem("is_admin", true);
        navigate("/admin");
      }
    },
  });

  return (
    <div className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-6 login-page__banner"
            style={{
              background: `linear-gradient(180deg, #AF151F00 0%, #AF151F14 100%)`,
            }}
          >
            <div className="content-employee">
              <div
                className="banner-flag-container"
                style={{
                  backgroundImage: `url('/images/banner-flag.svg')`,
                }}
              >
                <img
                  className="logo"
                  alt=""
                  src={cookies?.orgLogo || "/images/Tadarab-logo.png"}
                />
              </div>
              <h1>
                {t("welcomeTadarab")}
                <span>{cookies?.domain}</span>
              </h1>
              <p>{t("startLearning")}</p>
              <ul>
                <li>{t("biggestPlatform")}</li>
                <li>{t("remoteLearning")}</li>
                <li>{t("highQuality")}</li>
                <li>{t("specificTracks")}</li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="login-page__content">
              <div className="content-header">
                <h1 className="font-extrabold">
                  {t("auth:completedYourData")}
                </h1>
                <p className="mb-4">{t("auth:oneStepToStart")}</p>
              </div>
              <form
                className="content-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <InputFieldFloat
                  label={t("inputs.email")}
                  type="email"
                  name="email"
                  value={showToken?.data?.email}
                  disabled
                />

                <InputFieldFloat
                  label={t("inputs.name")}
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.errors.name}
                  touched={formik.touched.name}
                />
                <PasswordInputFloat
                  label={t("inputs.password")}
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                  Autocomplete={false}
                />
                <Checkbox id="tos" checked={checked} setChecked={setChecked}>
                  <p>
                    {t("iAgree")}
                    <a href="" className="text-link-red under-line">
                      {" "}
                      {t("termsAndConditions")}{" "}
                    </a>
                    {t("tadarabPlatform")}
                  </p>
                </Checkbox>
                <button
                  type="submit"
                  className="btn btn-main-color d-block w-100"
                  disabled={!checked}
                >
                  {t("saveInfo")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminInvitation;
