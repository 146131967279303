import { createApi } from "@reduxjs/toolkit/query/react";
import { staggeredBaseQueryWithBailOut } from "../utils/serviceUtilities";

export const api = createApi({
  reducerPath: "api",
  baseQuery: staggeredBaseQueryWithBailOut(process.env.REACT_APP_API_URL),
  tagTypes: [
    "Users",
    "User",
    "Posts",
    "Statistics",
    "learningTracks",
    "learningTrack",
    "learningTracksLearners",
    "Course",
    "Profile",
    "Settings",
    "MyPosts",
    "UserProfiles",
    "OrganizationInfo",
    "OrganizationAdmins",
    "discoverCourses",
  ],
  endpoints: () => ({}),
});
