import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  currentOrganization: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    setCurrentOrganization(state, action) {
      state.currentOrganization = action.payload;
    },
  },
});

export const { setCurrentUser, setCurrentOrganization } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
