import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Navbar from "../components/Navbar";
import SideBar from "../components/SideBar";
import SideBarMobile from "../components/SideBarMobile";
import { getAdminNavbarItems, getAdminSidebarItems } from "./navigationItems";
import { useShowAdminProfileQuery } from "../services/organization/auth";
import { setCurrentUser } from "../app/store/reducers/authSlice";
import { useTranslation } from "react-i18next";
import { setDocumentLang } from "../utils/helpers";
import i18n from "../i18n";

const AdminLayout = ({ activeNavbar, activeSidebar }) => {
  const { data: myProfile, error } = useShowAdminProfileQuery();

  const { t } = useTranslation([
    "common",
    "learningPaths",
    "leaderboard",
    "communityFeed",
  ]);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (myProfile?.status == "success") {
      dispatch(setCurrentUser(myProfile?.data));
      i18n.changeLanguage(myProfile?.data?.admin?.language);
      setDocumentLang(myProfile?.data?.admin?.language);
    }

    if (error?.status == 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("is_admin");
      dispatch(setCurrentUser(null));
      navigate("/login/admin");
    }
  }, [myProfile, error]);

  return (
    <div className="admin-layout">
      <Navbar
        items={getAdminNavbarItems(activeNavbar, t)}
        oraganizationLogo={myProfile?.data ? myProfile.data.image_url : ""}
        isAdmin
      ></Navbar>
      <div className="container-fluid">
        <div className="row">
          {activeSidebar && (
            <SideBar items={getAdminSidebarItems(activeSidebar, t)} />
          )}

          <SideBarMobile
            items={getAdminNavbarItems(activeNavbar, t)}
            additionalItems={getAdminSidebarItems(activeSidebar, t)}
            isAdmin
          />
          <div className="col content-page">
            {/* This element will render child of AdminLayout */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
