import { api } from "../index";

export const organizations = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrgProfile: builder.query({
      query: (code) => `/api/v1/organizations/${code}`,
    }),
  }),
});

export const { useLazyGetOrgProfileQuery } = organizations;
