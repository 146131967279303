/* eslint-disable */

import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { getDocumentLang } from "./helpers";

export const staggeredBaseQueryWithBailOut = (baseUrl) =>
  retry(
    async (args, api, extraOptions) => {
      const result = await fetchBaseQuery({ baseUrl, prepareHeaders })(
        args,
        api,
        extraOptions
      );
      // bail out of re-tries immediately if unauthorized,
      // because we know successive re-retries would be redundant
      if (
        result.error?.status === 401 ||
        result.error?.status === 403 ||
        result.error?.status === 500
      ) {
        retry.fail(result.error);
      }
      return result;
    },
    {
      maxRetries: 1,
    }
  );

const prepareHeaders = async (headers, { getState }) => {
  const token = localStorage.getItem("token");
  token ? headers.set("Authorization", token) : null;
  headers.set("Accept-Language", getDocumentLang());
  return headers;
};

export function calculateDurationInHoursAndMin(durationsInSeconds) {
  const totalDuration = durationsInSeconds / (60 * 60);
  return {
    hours: Math.floor(totalDuration),
    minutes: Math.ceil((totalDuration / 10) * 60),
  };
}

export function calculateRemaingDays(endDate) {
  const oneDay = 24 * 60 * 60 * 1000;
  const currentDate = new Date();

  return Math.round((endDate - currentDate) / oneDay);
}
