import React, { useState } from "react";
import TabsNav from "../../components/tabs/Tabs";

import Departments from "../../components/Settings/Admin/Departments";
import CertificateDesign from "../../components/Settings/Admin/CertificateDesign";
import WebsiteDesign from "../../components/Settings/Admin/WebsiteDesign";
import SiteAdministration from "../../components/Settings/Admin/SiteAdministration";
import { useTranslation } from "react-i18next";

const TABS = {
  WEBSITE_DESIGN: 1,
  CERTIFICATE_DESIGN: 2,
  DEPARTMENTS: 3,
  SITE_ADMINISTRATION: 4,
};

const Settings = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation("settings");

  return (
    <div className="employee">
      <div className="sticky-header  no-padding-b">
        <div className="d-flex flex-wrap">
          <div className="left-side">
            <h1 className="title-page ">{t("settings:siteSettings")}</h1>
            <ul className="d-flex">
              <li className="me-2"></li>
            </ul>
          </div>

          <div className="ms-auto right-side">
            <ul className="list-filter">
              <li className="me-2"></li>
              <li>
                <button
                  type="button"
                  className="btn btn-main-color font-15 font-extrabold px-5"
                  onClick={() => {
                    if (!isSaving) {
                      setIsSaving(true);
                    }
                  }}
                  style={{
                    visibility:
                      currentTab === TABS.SITE_ADMINISTRATION
                        ? "hidden"
                        : "visible",
                  }}
                >
                  {t("settings:saveChanges")}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <TabsNav
          tabsConfig={[
            {
              name: "profile",
              label: t("settings:siteDesign"),
              clickHandler: () => setCurrentTab(TABS.WEBSITE_DESIGN),
            },
            {
              name: "home",
              label: t("settings:certifcateDesign"),
              clickHandler: () => setCurrentTab(TABS.CERTIFICATE_DESIGN),
            },
            {
              name: "employees",
              label: t("settings:employeesDepartments"),
              clickHandler: () => setCurrentTab(TABS.DEPARTMENTS),
            },
            {
              name: "administration",
              label: t("settings:siteAdminstration"),
              clickHandler: () => setCurrentTab(TABS.SITE_ADMINISTRATION),
            },
          ]}
        />
      </div>

      <div className="content-page__wrapper">
        <div className="tab-content" id="nav-tabContent">
          {currentTab === TABS.DEPARTMENTS && (
            <Departments submittingController={{ isSaving, setIsSaving }} />
          )}
          {currentTab === TABS.CERTIFICATE_DESIGN && <CertificateDesign />}
          {currentTab === TABS.WEBSITE_DESIGN && (
            <WebsiteDesign submittingController={{ isSaving, setIsSaving }} />
          )}
          {currentTab === TABS.SITE_ADMINISTRATION && <SiteAdministration />}
        </div>
      </div>
    </div>
  );
};

export default Settings;
